import React from "react";
import {useSelector} from "react-redux";
import R from "../theme/styles";
import {isMobileOnly} from "react-device-detect";
import {getPalette} from "../theme/styles/palette";

const viewportContext = React.createContext({});
const breakpointsMap = ['xs', 'sm', 'md', 'lg', 'xl']

export const ResProvider = ({children, value}) => {
   let palette = getPalette(value)
   return (
      <viewportContext.Provider
         value={{palette}}>
         {children}
      </viewportContext.Provider>
   );
};

const useRes = () => {
   const {
      palette
   } = React.useContext(viewportContext);

   return {palette};
};
export default useRes
