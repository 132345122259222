import palette from "../palette";
import pxToRem from "../../functions/pxToRem";
import borders from "../borders";
import boxShadows from "../boxShadows";
import boxShadow from "../../functions/boxShadow";
import R from "../index";
import {alpha} from "../../functions/colorManipulation";

const {colors, white, black, info} = palette;
const {borderRadius, borderWidth} = borders;
const {sliderBoxShadow} = boxShadows;

const slider = {

};

export default slider;
