
export const buildStyle = (classes, conditions, sx) => {
    let style = {...classes?.root}
    for(let key of Object.keys(conditions)){
        if(conditions[key] && classes[key]){
            style = {...style, ...classes[key]}
        }
    }
    if(sx){
        style = {...style, ...sx}
    }
    return style
}
