import {createSlice} from '@reduxjs/toolkit'
import {databaseFB} from "../firebase";

const init = {offset: 0, seasonSelected: 's_22_23', formazioniDefault: {}, delay: 0, playersToSkip: {}, readyData: [], hidePurchases: false, audioActive: true, season: null}
const slice = createSlice({
   name: 'persisted',
   initialState: init,
   reducers: {
      setOffset: (
         state,
         action
      ) => {
         state.offset = action.payload.offset

      },
      resetPersistedData: (state, action) => {
         state.season = null
         state.offset = 0
         state.seasonSelected = 's_22_23'
         state.formazioniDefault = {}
         state.playersToSkip = {}
      },
      resetOffset: (
         state,
         action
      ) => {
         state.offset = 0
      },
      setSeasonSelected: (
         state,
         action
      ) => {
         state.seasonSelected = action.payload.season
      },
      setSeason: (
         state,
         action
      ) => {
         state.season = action.payload
      },
      setFormazioniDefault: (
         state,
         action
      ) => {
         state.formazioniDefault = action.payload.formazioniDefault
      },
      updateLivePlayerStrategy: (
         state,
         action
      ) => {
         state.livePlayerStrategy = action.payload
      },
      updateLiveSinglePlayerStrategy: (
         state,
         action
      ) => {
         state.livePlayerStrategy[action.payload.player_id] = action.payload
      },
      updateLiveStrategy: (
         state,
         action
      ) => {
         state.liveStrategy = action.payload
      },
      setLiveDelay: (
         state,
         action
      ) => {
         state.delay = action.payload
      },
      setDiretto: (
         state,
         action
      ) => {
         state.diretto = action.payload
      },
      setAudioActive: (
         state,
         action
      ) => {
         state.audioActive = action.payload
      },
      setPlayersToSkip: (
         state,
         action
      ) => {
         state.playersToSkip = action.payload
      },
      setReady: (
         state,
         action
      ) => {
         if(state.readyData){
            let result = [...state.readyData]
            if(action.payload.type === 'players'){
               if(!state.readyData.includes('players')){
                  result.push('players')
               }
            } else if(action.payload.type === 'heatmaps') {
               if(!state.readyData.includes('heatmaps')){
                  result.push('heatmaps')
               }
            } else if(action.payload.type === 'gamestats'){
               if(!state.readyData.includes('gamestats')){
                  result.push('gamestats')
               }
            }
            state.readyData = result
         } else {
            state.readyData = [action.payload.type]
         }
      },
      setNotReady: (
         state,
         action
      ) => {
         if(state.readyData){
            if(action.payload.type === 'players'){
               if(state.readyData.includes('heatmaps')){
                  state.readyData = ['heatmaps']
               } else {
                  state.readyData = []
               }
            } else {
               if(state.readyData.includes('players')){
                  state.readyData = ['players']
               } else {
                  state.readyData = []
               }
            }
         }

      },
      setHidePurchases: (
         state,
         action
      ) => {
         state.hidePurchases = action.payload
      },
   },
})

export const {setAudioActive, resetPersistedData, setHidePurchases, setReady, setNotReady, setPlayersToSkip, setOffset, setSeasonSelected, setSeason, setDiretto, setFormazioniDefault, updateLivePlayerStrategy, updateLiveStrategy, resetOffset, updateLiveSinglePlayerStrategy, setLiveDelay} = slice.actions


export default slice.reducer

