import palette from "./styles/palette";

export const MUIPalette = {
   ...palette,
   grey:{
     ...palette.colors.grey
   },
   gradients: {
      primary: {
         main: palette.primary.main,
         state: palette.primary.gradient,
      },
      secondary: {
         main: palette.secondary.main,
         state: palette.secondary.gradient,
      },
      info: {
         main: palette.info.main,
         state: palette.info.gradient,
      },
      success: {
         main: palette.success.main,
         state: palette.success.gradient,
      },
      warning: {
         main: palette.warning.main,
         state: palette.warning.gradient,
      },
      error: {
         main: palette.error.main,
         state: palette.error.gradient,
      },
      light: {
         main: palette.light.main,
         state: palette.light.gradient,
      },
      dark: {
         main: palette.dark.main,
         state: palette.dark.gradient,
      },
   },
   badgeColors: {
      primary: {
         background: palette.primary.light,
         text: palette.primary.focus,
      },
      secondary: {
         background: palette.secondary.light,
         text: palette.secondary.focus,
      },

      info: {
         background: palette.info.light,
         text: palette.info.focus,
      },

      success: {
         background: palette.success.light,
         text: palette.success.focus,
      },

      warning: {
         background: palette.warning.light,
         text: palette.warning.focus,
      },

      error: {
         background: palette.error.light,
         text: palette.error.focus,
      },

      light: {
         background: palette.light.light,
         text: palette.light.focus,
      },

      dark: {
         background: palette.dark.light,
         text: palette.dark.focus,
      },
   },
   coloredShadows: {
      primary: palette.primary.main,
      secondary: palette.secondary.main,
      info: palette.info.main,
      success: palette.success.main,
      warning: palette.warning.main,
      error: palette.error.main,
      light: palette.light.main,
      dark: palette.dark.main,
   },
}
