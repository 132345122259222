import React from 'react';
import PropTypes from 'prop-types';
import {Stack, useTheme} from "@mui/material";
import R from "../../theme/styles";
import {buildStyle} from "../../utils/style/buildStyle";
import Divider from "./Divider";
import {alpha, emphasize} from "../../theme/functions/colorManipulation";
import useViewport from "../../hooks/useViewport";
import _ from "lodash";
import useRes from "../../hooks/useRes";

const getColorBackground = (palette, backgroundColor, backgroundColor2, gradient) => {
   //CREATE THE COLOR BACKGROUND
   let color1 = palette.background.secondary
   let color2 = palette.background.tertiary
   if (gradient && palette[gradient]?.gradient) {
      color1 = palette[gradient]?.main || gradient
      color2 = palette[gradient]?.gradient
   } else if (backgroundColor === true || backgroundColor === 'primary') {
      color1 = palette.background.primary
      color2 = palette.background.secondary
   } else if (backgroundColor === 'secondary') {
      color1 = palette.background.secondary
      color2 = palette.background.tertiary
   } else if (backgroundColor) {
      color1 = palette[backgroundColor]?.main || backgroundColor
      if (backgroundColor2) {
         color2 = palette[backgroundColor2]?.gradient || backgroundColor2
      }
   }
   return [color1, color2]
}

const View = ({
                 borderRadius,
                 className,
                 children,
                 flex = false,
                 flex1 = false,
                 flexGrow = false,
                 flexShrink = false,
                 centerCenter = false,
                 opacity,
                 overflowHidden = false,
                 overflow,
                 wrap = false,
                 row = false,
                 column = false,
                 justify,
                 alignItems,
                 clickable,
                 backgroundColor,
                 backgroundColor2,
                 backgroundImage,
                 minHeight = false,
                 minWidth = false,
                 gradient = false,
                 gradientDirection = 'bottom right',
                 fullWidth,
                 fullHeight,
                 /*hiddenUp = false,
                 hiddenDown = false,*/
                 hover = false,
                 spacing,
                 direction,
                 divider,
                 paper,
                 shadow,
                 maxWidth,
                 maxHeight,
                 position,
                 dividerVariant = "middle",
                 sx,
                 onClick,
                 forceMobileVertical,
                 xs, sm, md, lg, xl, xxl,
                 container,
                 grid,
                 spacingContainer,
                 p,pt,pb,pl,pr,px,py,
                 border,
                 borderWidth,
                 ...rest
              }) => {
   const {palette} = useRes()
   const hasPlainBackground = backgroundColor && !gradient && !backgroundColor2
   const hasGradientBackground = gradient || backgroundColor2
   const [color1, color2] = getColorBackground(palette, backgroundColor, backgroundColor2, gradient)
   const viewport = useViewport()

   function getColumnsStyle(n, v) {
      return {
         [`@media (min-width: ${R.breakpoints.values[v]}px)`]: {
            '-webkit-flex-basis': `${n * 100 / 12}%`,
            '-ms-flex-preferred-size': `${n * 100 / 12}%`,
            flexBasis: `${n * 100 / 12}%`,
            '-webkit-box-flex': 0,
            '-webkit-flex-grow': 0,
            '-ms-flex-positive': 0,
            flexGrow: 0,
            maxWidth: `calc(${n * 100 / 12}% - ${(R.dimensions.spacing * spacingContainer)}px)`,
            //maxWidth: `${n*100 / 12}%`,
            marginRight: `${R.dimensions.spacing * spacingContainer / 2}px`,
            marginLeft: `${R.dimensions.spacing * spacingContainer / 2}px`,
            marginTop: `${R.dimensions.spacing * spacingContainer / 2}px`,
            marginBottom: `${R.dimensions.spacing * spacingContainer / 2}px`,
         }
      }
   }

   const childrenWithProps = React.Children.map(children, child => {
      // Checking isValidElement is the safe way and avoids a
      // typescript error too.
      if (React.isValidElement(child)) {
         return React.cloneElement(child, {spacingContainer: spacing});
      }
      return child;
   });

   const classes = {
      root: {},
      flex: {
         ...R.layout.flex,
         flex: flex || 'initial'
      },
      flex1: {...R.layout.flex1},
      flexGrow: {...R.layout.flexGrow},
      flexShrink: {...R.layout.flexShrink},

      wrap: wrap === true ? {...R.layout.wrap} : {display: 'flex', flexWrap: wrap},
      centerCenter: {...R.layout.centerCenter},
      justify: {display: 'flex', justifyContent: justify},
      alignItems: {display: 'flex', alignItems: alignItems},
      minHeight: {minHeight: minHeight},
      minWidth: {minWidth: minWidth},
      position: {position: position},
      clickable: {
         '&:hover': {
            cursor: 'pointer',
         },
      },
      clickableHighlight: {
         '&:hover': {
            background: `${emphasize(color1, 0.05)} !important`,
            cursor: 'pointer',
         },
         '&:hover span': {
            // interferisce con l'hover delle icone dentro la view
            //color: clickable === 'selected' ? palette.text.light.strong : 'inherit',
         },
      },
      opacity: {
         opacity: opacity || 1,
      },
      backgroundColor: {
         backgroundColor: color1,
         //...R.layout.flex1,
      },
      backgroundColorGradient: {
         background: gradientDirection === 'top bottom' ? `linear-gradient(${color1}, ${color2})` : `linear-gradient(to ${gradientDirection}, ${color1}, ${color2})`,
         //...R.layout.flex1,
      },
      backgroundImage: {
         backgroundPosition: 'center',
         backgroundSize: 'cover !important',
         background: `url("${backgroundImage}")`,
      },
      fullWidth: {
         width: '100%',
      },
      fullHeight: {
         height: '100%',
      },
      /*hiddenUp: {
         [theme.breakpoints.up(hiddenUp)]: {
            display: 'none',
         },
      },
      hiddenDown: {
         [theme.breakpoints.down(hiddenDown)]: {
            display: 'none',
         },
      },*/
      paper: {
         backgroundColor: palette.background[paper] || alpha(palette.background.primary,0.85),
         borderRadius: !borderRadius ? R.borders.borderRadius.xxxl : undefined,
         boxShadow: `${R.boxShadows.paper}, inset 0px 0px 0px ${borderWidth || 1}px ${R.borders.borderColor}`,
         pt: (_.isNumber(pt) || _.isNumber(py) || _.isNumber(p)) ? undefined : viewport.isXS ? 1 : 2,
         pb: (_.isNumber(pb) || _.isNumber(py) || _.isNumber(p)) ? undefined : viewport.isXS ? 1 : 2,
         pr: (_.isNumber(pr) || _.isNumber(px) || _.isNumber(p)) ? undefined : viewport.isXS ? 1 : 2,
         pl: (_.isNumber(pl) || _.isNumber(px) || _.isNumber(p)) ? undefined : viewport.isXS ? 1 : 2,
         overflow:'hidden',
      },
      overflowHidden: {...R.layout.overflowHidden},
      overflow: {overflow: overflow},
      borderRadius: {
         borderRadius: borderRadius || 0,
      },
      shadow: {
         boxShadow: R.boxShadows[shadow] || R.boxShadows.md
      },
      maxWidth: {
         maxWidth: R.breakpoints.values?.[maxWidth] ? R.breakpoints.values[maxWidth] : maxWidth
      },
      maxHeight: {
         maxHeight: maxHeight
      },
      spacing: {
         gap: !spacing ? 0 : spacing
      },
      container: {
         boxSizing: 'border-box',
         //display: 'webkit-flex',
         display: 'flex',
         '-webkit-box-flex-wrap': 'wrap',
         '-webkit-flex-wrap': 'wrap',
         '-ms-flex-wrap': 'wrap',
         flexWrap: 'wrap',
         width: `calc(100% + ${R.dimensions.spacing * spacing}px);`,
         '-webkit-flex-direction': 'row',
         '-ms-flex-direction': 'row',
         'flex-direction': 'row',
         marginLeft: `-${R.dimensions.spacing * spacing / 2}px`,
         marginRight: `-${R.dimensions.spacing * spacing / 2}px`,
         marginTop: `-${R.dimensions.spacing * spacing / 2}px`,
         marginBottom: `-${R.dimensions.spacing * spacing / 2}px`,
         overflow: 'unset',
      },
      xs: getColumnsStyle(xs, 'xs'),
      sm: getColumnsStyle(sm, 'sm'),
      md: getColumnsStyle(md, 'md'),
      lg: getColumnsStyle(lg, 'lg'),
      xl: getColumnsStyle(xl, 'xl'),
      xxl: getColumnsStyle(xxl, 'xxl'),
      border:{
         boxShadow: 'none',
         border: border === true ? `solid ${borderWidth || 1}px ${alpha(R.borders.borderColor, 1)}` : border,
      }

   };

   const style = buildStyle(classes, {
      root: true,
      flex: flex,
      flex1: flex1,
      flexGrow: flexGrow,
      flexShrink: flexShrink,
      overflowHidden: overflowHidden,
      overflow: overflow,
      wrap: wrap,
      centerCenter: centerCenter,
      justify: justify,
      alignItems: alignItems,
      paper: paper,
      borderRadius: borderRadius,
      clickable: clickable && clickable !== 'highlight',
      clickableHighlight: clickable === 'highlight',
      minHeight: minHeight,
      minWidth: minWidth,
      backgroundColor: hasPlainBackground,
      backgroundColorGradient: hasGradientBackground,
      backgroundImage: backgroundImage,
      fullWidth: fullWidth,
      fullHeight: fullHeight,
      /*hiddenUp: hiddenUp,
      hiddenDown: hiddenDown,*/
      opacity: opacity,
      shadow: shadow,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      position: position,
      spacing: spacing && !container,
      container: container && grid,
      xs: xs && grid,
      sm: sm && grid,
      md: md && grid,
      lg: lg && grid,
      xl: xl && grid,
      border: border,
   })
   return (
      <Stack
         direction={(forceMobileVertical && viewport.isXS) ? 'column' : direction ? direction : 'column'}
         //spacing={spacing}
         divider={divider &&
            <Divider orientation={direction === 'row' ? 'vertical' : 'horizontal'}
                     light={divider === 'light'}
                     color={divider && divider !== 'light'}
                     variant={dividerVariant ? dividerVariant : undefined}
                     flexItem={true}
                     sx={{borderColor: divider && typeof divider !== "boolean" && divider !== 'light' ? divider : false}}
            />}
         className={className || clickable ? `${className || ''} ${clickable ? 'divHover' : ''}` : undefined}
         onClick={clickable ? onClick : undefined}
         sx={[style, sx]}
         p={p}
         px={px}
         py={py}
         pr={pr}
         pl={pl}
         pt={pt}
         pb={pb}
         {...rest}
      >
         {container ? childrenWithProps : children}
      </Stack>
   );
};

View.propTypes = {
   children: PropTypes.node,
   clickable: PropTypes.oneOf([false, true, 'highlight']),
   flex: PropTypes.oneOfType([PropTypes.bool || PropTypes.number]),
   flex1: PropTypes.bool,
   flexGrow: PropTypes.bool,
   flexShrink: PropTypes.bool,
   overflowHidden: PropTypes.bool,
   row: PropTypes.bool,
   column: PropTypes.bool,
   centerCenter: PropTypes.bool,
   minHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
   minWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
   justify: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly', 'baseline']),
   alignItems: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline', 'end']),
   backgroundColor: PropTypes.string,
   backgroundColor2: PropTypes.string,
   gradient: PropTypes.oneOf([false, true, 'light', 'dark', 'primary', 'secondary', 'success', 'info', 'error', 'warning']),
   dividerVariant: PropTypes.oneOf('fullWidth', 'inset', 'middle', 'string'),
   maxWidth: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl'])
   ])
}

export default View;
