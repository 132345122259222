import {Select as MUISelect, useTheme} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {buildStyle} from "../../utils/style/buildStyle";
import R from "../../theme/styles";

const Select = ({
                   className,
                   children,
                   sx,
                   size = 'medium',
                   color,
                   minWidth,
                   darkTheme,
                   noBorder,
                   fontSize,
                   borderColor,
                   ...rest
                }) => {

   const theme = useTheme()
   const classes = {
      root: {
         display: 'flex',
         minWidth: minWidth,
         borderRadius: R.borders.borderRadius.input,
         paddingLeft: 0,
         paddingRight: 0,
         overflow:'hidden',
         textAlign:'left',
         "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
         "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
         "&.Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: `${R.palette.info.main} !important`,
         },
      },
      fullWidth: {
         width: '100%'
      },
      xSmall: {
         height: 20,
         '& .MuiSelect-select': {
            padding: '6px 14px'
         }
      },
      small: {
         height: 30,
         '& .MuiSelect-select': {
            padding: '6px 14px'
         }
      },
      medium: {
         height: 40,
         '& .MuiSelect-select': {
            padding: '10.5px 14px'
         }
      },
      large: {
         height: 50,
         '& .MuiSelect-select': {
            padding: '14px 14px'
         }
      },
      fontSize:{
         fontSize:fontSize
      },
      color: {
         "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px',
            top: 'auto',
            color: `${darkTheme ? '#eee' : theme.palette.getContrastText(theme.palette?.[color]?.['main'] || color)} !important`,
         },
         "&.MuiInputBase-root .MuiSelect-select": {
            color: `${darkTheme ? '#eee' : theme.palette.getContrastText(theme.palette?.[color]?.['main'] || color)} !important`,
         },
         backgroundColor: R.palette?.[color]?.main || color,
      },
      noBorder: {
         '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
         }
      }
   }
   const style = buildStyle(classes, {
      root: true,
      xSmall: size === 'xSmall',
      small: size === 'small',
      medium: size === 'medium',
      large: size === 'large',
      color: color,
      noBorder: noBorder,
      fontSize: fontSize,
   }, sx);

   return (
      <MUISelect
         sx={style}
         //className={className}
         IconComponent={KeyboardArrowDownIcon}
         {...rest}
      >
         {children}
      </MUISelect>
   )
}

export default Select
