import React from 'react';
import {Divider as MuiDivider} from "@mui/material";

const Divider = ({color, ...rest}) => {

    return (
        <MuiDivider
           sx={{borderColor: color}}
            {...rest}
        />
    );
};

Divider.propTypes = {};

export default Divider;
