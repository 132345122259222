import palette from "../../palette";
import borders from "../../borders";
import boxShadows from "../../boxShadows";

const {white} = palette;
const {md} = boxShadows;
const {borderRadius} = borders;

const tableContainer = {
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            boxShadow: md,
            borderRadius: borderRadius.xl,
        },
    },
};

export default tableContainer;
