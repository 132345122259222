import React from 'react';
import {MenuItem as MuiMenuItem} from "@mui/material";

const MenuItem = ({...rest}) => {

    return (
        <MuiMenuItem
            {...rest}
        />
    );
};

MenuItem.propTypes = {};

export default MenuItem;
