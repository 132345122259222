import {Icon as MuiIcon} from "@mui/material";
import R from "../../theme/styles";
import {darken, emphasize, getLuminance, lighten} from "../../theme/functions/colorManipulation";
import PropTypes from "prop-types";
import pxToRem from "../../theme/functions/pxToRem";
import View from "./View";

const Icon = ({
                 children,
                 color,
                 sx,
                 fontSize = 28,
                 selected,
                 hover = true,
                 hoverButton = false,
                 hoverIcon = false,
                 src,
                 justHeight = false,
                 round,
                 opacity,
                 muiIconType ='round',
                 ...rest
              }) => {

   const colorIcon = color === 'primary' ? R.palette.elements.iconButton.primary : color === 'secondary' ? emphasize(R.palette.elements.iconButton.primary) : color ? color : R.palette.elements.iconButton.primary
   const darkColor = getLuminance(colorIcon) < 0.3
   const hoverColor = darkColor ? darken(colorIcon, 0.8) : lighten(colorIcon, 0.8)

   let fontSizeFinal = undefined
   if (fontSize === 'small') {
      fontSizeFinal = 16
   } else if (fontSize === 'medium') {
      fontSizeFinal = 22
   } else if (fontSize === 'default') {
      fontSizeFinal = 28
   } else if (fontSize === 'large') {
      fontSizeFinal = 36
   } else if (fontSize) {
      fontSizeFinal = fontSize
   }


   return (
      src ?
         <View sx={{
            '&:hover .icon-img': {
               opacity: hoverIcon ? '0.8 !important' : undefined,
            },
         }}>
            <img
               className={'icon-img'}
               src={src}
               alt={'icon_image'}
               height={fontSizeFinal ? fontSizeFinal-2 : undefined}
               width={(!justHeight && fontSizeFinal) ? fontSizeFinal-2 : undefined}
               style={{opacity: opacity ? opacity : hoverIcon ? 0.5 : undefined, borderRadius: round ? 1000 : undefined,...sx}}
            />
         </View>
         :
         <MuiIcon
            baseClassName={muiIconType === 'round' ? "material-icons-round" : undefined}
            fontSize={undefined}
            sx={{
               '&.MuiIcon-root': {
                  fontSize: fontSizeFinal ? fontSizeFinal+'px !important' : 'inherit',
                  color: `${colorIcon}`,
               },
               '& :hover': {
                  color: hover ? hoverColor : colorIcon
               },
               borderRadius: round ? 1000 : undefined,
               ...sx
            }}
            {...rest}>
            {children}
         </MuiIcon>
   )
}

Icon.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
   fontSize: PropTypes.string || PropTypes.oneOf(["inherit", "large", "medium", "small"]),
};

export default Icon
