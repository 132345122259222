import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'style',
   initialState: {
      activePlayer : null,
      auctionState: null,
      auctionMode: null,
      showValues: true,
      roleFilter: null,
      activeAsta: null
   },
   reducers: {
      setActivePlayer: (
         state,
         action
      ) => {
         state.activePlayer = action.payload.activePlayer
      },
      setAuctionState: (
         state,
         action
      ) => {
         state.auctionState = action.payload.auctionState
         state.lastAuctionStateUpdate = new Date().valueOf()
      },
      setAuctionMode: (
         state,
         action
      ) => {
         state.auctionMode = action.payload.auctionMode
      },
      setShowValues: (
         state,
         action
      ) => {

         state.showValues = action.payload.showValues
      },
      setRoleFilter: (
         state,
         action
      ) => {

         state.roleFilter = action.payload.roleFilter
      },
      setActiveAsta: (
         state,
         action
      ) => {

         state.activeAsta = action.payload.activeAsta
      },
   },
})

export const { setActivePlayer, setAuctionState, setAuctionMode, setShowValues, setRoleFilter, setActiveAsta } = slice.actions

export default slice.reducer

