import palette from "../palette";

const {transparent} = palette;

const iconButton = {
    styleOverrides: {
        root: {
            "&:hover": {
                //backgroundColor: transparent.main,
            },
        },
    },
};

export default iconButton;
