import add from '../../../assets/icons/light/add.png'
import admin from '../../../assets/icons/light/admin.png'
import assist from '../../../assets/icons/light/assist.png'
import balance from '../../../assets/icons/light/balance.png'
import ball from '../../../assets/icons/light/ball.png'
import barrier from '../../../assets/icons/light/barrier.png'
import bench from '../../../assets/icons/light/bench.png'
import bin from '../../../assets/icons/light/bin.png'
import bonus_minuti from '../../../assets/icons/light/bonus_minuti.png'
import bonus_partite from '../../../assets/icons/light/bonus_partite.png'
import calendar from '../../../assets/icons/light/calendar.png'
import cancel from '../../../assets/icons/light/cancel.png'
import cards from '../../../assets/icons/light/cards.png'
import close_circle from '../../../assets/icons/light/close-circle.png'
import cog from '../../../assets/icons/light/cog.png'
import coin from '../../../assets/icons/light/coin.png'
import comment from '../../../assets/icons/light/comment.png'
import cup from '../../../assets/icons/light/cup.png'
import deal from '../../../assets/icons/light/deal.png'
import diamond from '../../../assets/icons/light/diamond.png'
import download from '../../../assets/icons/light/download.png'
import edit from '../../../assets/icons/light/edit.png'
import error from '../../../assets/icons/light/error.png'
import eye from '../../../assets/icons/light/eye.png'
import field from '../../../assets/icons/light/field.png'
import football_field from '../../../assets/icons/light/football-field.png'
import football_field_in_perspective from '../../../assets/icons/light/football-field-in-perspective.png'
import freekick from '../../../assets/icons/light/freekick.png'
import gavel from '../../../assets/icons/light/gavel.png'
import gloves from '../../../assets/icons/light/gloves.png'
import gol_subito from '../../../assets/icons/light/gol_subito.png'
import graph from '../../../assets/icons/light/graph.png'
import graph_1 from '../../../assets/icons/light/graph-1.png'
import head_ball from '../../../assets/icons/light/head_ball.png'
import heart from '../../../assets/icons/light/heart.png'
import help from '../../../assets/icons/light/help.png'
import history from '../../../assets/icons/light/history.png'
import imbattibilita from '../../../assets/icons/light/imbattibilita.png'
import influencers from '../../../assets/icons/light/influencers.png'
import jersey from '../../../assets/icons/light/jersey.png'
import jersey_ball from '../../../assets/icons/light/jersey-ball.png'
import joker from '../../../assets/icons/light/joker.png'
import kicking_ball from '../../../assets/icons/light/kicking_ball.png'
import list from '../../../assets/icons/light/list.png'
import local_police from '../../../assets/icons/light/local_police.png'
import long_shoot from '../../../assets/icons/light/long_shoot.png'
import medical from '../../../assets/icons/light/medical.png'
import menu from '../../../assets/icons/light/menu.png'
import mod from '../../../assets/icons/light/mod.png'
import notifications from '../../../assets/icons/light/notifications.png'
import padlock_solid from '../../../assets/icons/light/padlock_solid.png'
import passing from '../../../assets/icons/light/passing.png'
import penalty from '../../../assets/icons/light/penalty.png'
import penalty_failed from '../../../assets/icons/light/penalty_failed.png'
import phishing from '../../../assets/icons/light/phishing.png'
import plus from '../../../assets/icons/light/plus.png'
import precision from '../../../assets/icons/light/precision.png'
import profile from '../../../assets/icons/light/profile.png'
import profit from '../../../assets/icons/light/profit.png'
import ranking from '../../../assets/icons/light/ranking.png'
import recap from '../../../assets/icons/light/recap.png'
import red_card from '../../../assets/icons/light/red_card.png'
import relaiable from '../../../assets/icons/light/relaiable.png'
import rocket from '../../../assets/icons/light/rocket.png'
import scambio from '../../../assets/icons/colored/scambio.png'
import search from '../../../assets/icons/light/search.png'
import serie_a from '../../../assets/icons/light/serie a.png'
import six from '../../../assets/icons/light/six.png'
import shield from '../../../assets/icons/light/shield.png'
import shoot from '../../../assets/icons/light/shoot.png'
import shoot_net from '../../../assets/icons/light/shoot_net.png'
import shorts from '../../../assets/icons/light/shorts.png'
import simulation from '../../../assets/icons/light/simulation.png'
import stadium from '../../../assets/icons/light/stadium.png'
import star from '../../../assets/icons/light/star.png'
import strategy from '../../../assets/icons/light/strategy.png'
import substitution from '../../../assets/icons/light/substitution.png'
import sync_player from '../../../assets/icons/light/sync_player.png'
import target from '../../../assets/icons/light/target.png'
import three_dots from '../../../assets/icons/light/three_dots.png'
import timer from '../../../assets/icons/light/timer.png'
import training from '../../../assets/icons/light/training.png'
import upload from '../../../assets/icons/light/upload.png'
import users from '../../../assets/icons/light/users.png'
import whistle from '../../../assets/icons/light/whistle.png'
import yellow_card from '../../../assets/icons/light/yellow_card.png'
import add_dark from '../../../assets/icons/dark/add.png'
import admin_dark from '../../../assets/icons/dark/admin.png'
import assist_dark from '../../../assets/icons/dark/assist.png'
import balance_dark from '../../../assets/icons/dark/balance.png'
import ball_dark from '../../../assets/icons/dark/ball.png'
import barrier_dark from '../../../assets/icons/dark/barrier.png'
import bench_dark from '../../../assets/icons/dark/bench.png'
import bin_dark from '../../../assets/icons/dark/bin.png'
import bonus_minuti_dark from '../../../assets/icons/dark/bonus_minuti.png'
import bonus_partite_dark from '../../../assets/icons/dark/bonus_partite.png'
import cancel_dark from '../../../assets/icons/dark/cancel.png'
import cards_dark from '../../../assets/icons/dark/cards.png'
import close_circle_dark from '../../../assets/icons/dark/close-circle.png'
import cog_dark from '../../../assets/icons/dark/cog.png'
import coin_dark from '../../../assets/icons/dark/coin.png'
import cup_dark from '../../../assets/icons/dark/cup.png'
import deal_dark from '../../../assets/icons/dark/deal.png'
import diamond_dark from '../../../assets/icons/dark/diamond.png'
import download_dark from '../../../assets/icons/dark/download.png'
import edit_dark from '../../../assets/icons/dark/edit.png'
import eye_dark from '../../../assets/icons/dark/eye.png'
import field_dark from '../../../assets/icons/dark/field.png'
import football_field_dark from '../../../assets/icons/dark/football-field.png'
import football_field_in_perspective_dark from '../../../assets/icons/dark/football-field-in-perspective.png'
import freekick_dark from '../../../assets/icons/dark/freekick.png'
import gavel_dark from '../../../assets/icons/dark/gavel.png'
import gloves_dark from '../../../assets/icons/dark/gloves.png'
import gol_subito_dark from '../../../assets/icons/dark/gol_subito.png'
import graph_dark from '../../../assets/icons/dark/graph.png'
import graph_1_dark from '../../../assets/icons/dark/graph-1.png'
import heart_dark from '../../../assets/icons/dark/heart.png'
import help_dark from '../../../assets/icons/dark/help.png'
import history_dark from '../../../assets/icons/dark/history.png'
import imbattibilita_dark from '../../../assets/icons/dark/imbattibilita.png'
import influencers_dark from '../../../assets/icons/dark/influencers.png'
import jersey_dark from '../../../assets/icons/dark/jersey.png'
import jersey_ball_dark from '../../../assets/icons/dark/jersey-ball.png'
import joker_dark from '../../../assets/icons/dark/joker.png'
import list_dark from '../../../assets/icons/dark/list.png'
import local_police_dark from '../../../assets/icons/dark/local_police.png'
import medical_dark from '../../../assets/icons/dark/medical.png'
import menu_dark from '../../../assets/icons/dark/menu.png'
import mod_dark from '../../../assets/icons/dark/mod.png'
import notifications_dark from '../../../assets/icons/dark/notifications.png'
import penalty_dark from '../../../assets/icons/dark/penalty.png'
import plus_dark from '../../../assets/icons/dark/plus.png'
import profile_dark from '../../../assets/icons/dark/profile.png'
import profit_dark from '../../../assets/icons/dark/profit.png'
import ranking_dark from '../../../assets/icons/dark/ranking.png'
import recap_dark from '../../../assets/icons/dark/recap.png'
import red_card_dark from '../../../assets/icons/dark/red_card.png'
import relaiable_dark from '../../../assets/icons/dark/relaiable.png'
import rocket_dark from '../../../assets/icons/dark/rocket.png'
import search_dark from '../../../assets/icons/dark/search.png'
import serie_a_dark from '../../../assets/icons/dark/serie a.png'
import shield_dark from '../../../assets/icons/dark/shield.png'
import shorts_dark from '../../../assets/icons/dark/shorts.png'
import simulation_dark from '../../../assets/icons/dark/simulation.png'
import stadium_dark from '../../../assets/icons/dark/stadium.png'
import star_dark from '../../../assets/icons/dark/star.png'
import strategy_dark from '../../../assets/icons/dark/strategy.png'
import substitution_dark from '../../../assets/icons/dark/substitution.png'
import sync_player_dark from '../../../assets/icons/dark/sync_player.png'
import target_dark from '../../../assets/icons/dark/target.png'
import timer_dark from '../../../assets/icons/dark/timer.png'
import training_dark from '../../../assets/icons/dark/training.png'
import upload_dark from '../../../assets/icons/dark/upload.png'
import users_dark from '../../../assets/icons/dark/users.png'
import yellow_card_dark from '../../../assets/icons/dark/yellow_card.png'
import add_selected from '../../../assets/icons/selected/add.png'
import admin_selected from '../../../assets/icons/selected/admin.png'
import assist_selected from '../../../assets/icons/selected/assist.png'
import balance_selected from '../../../assets/icons/selected/balance.png'
import ball_selected from '../../../assets/icons/selected/ball.png'
import barrier_selected from '../../../assets/icons/selected/barrier.png'
import bench_selected from '../../../assets/icons/selected/bench.png'
import bin_selected from '../../../assets/icons/selected/bin.png'
import cancel_selected from '../../../assets/icons/selected/cancel.png'
import cards_selected from '../../../assets/icons/selected/cards.png'
import close_circle_selected from '../../../assets/icons/selected/close-circle.png'
import cog_selected from '../../../assets/icons/selected/cog.png'
import coin_selected from '../../../assets/icons/selected/coin.png'
import cup_selected from '../../../assets/icons/selected/cup.png'
import deal_selected from '../../../assets/icons/selected/deal.png'
import diamond_selected from '../../../assets/icons/selected/diamond.png'
import download_selected from '../../../assets/icons/selected/download.png'
import edit_selected from '../../../assets/icons/selected/edit.png'
import eye_selected from '../../../assets/icons/selected/eye.png'
import field_selected from '../../../assets/icons/selected/field.png'
import football_field_selected from '../../../assets/icons/selected/football-field.png'
import football_field_in_perspective_selected from '../../../assets/icons/selected/football-field-in-perspective.png'
import freekick_selected from '../../../assets/icons/selected/freekick.png'
import gavel_selected from '../../../assets/icons/selected/gavel.png'
import gloves_selected from '../../../assets/icons/selected/gloves.png'
import gol_subito_selected from '../../../assets/icons/selected/gol_subito.png'
import graph_selected from '../../../assets/icons/selected/graph.png'
import graph_1_selected from '../../../assets/icons/selected/graph-1.png'
import heart_selected from '../../../assets/icons/selected/heart.png'
import help_selected from '../../../assets/icons/selected/help.png'
import history_selected from '../../../assets/icons/selected/history.png'
import imbattibilita_selected from '../../../assets/icons/selected/imbattibilita.png'
import influencers_selected from '../../../assets/icons/selected/influencers.png'
import jersey_selected from '../../../assets/icons/selected/jersey.png'
import jersey_ball_selected from '../../../assets/icons/selected/jersey-ball.png'
import joker_selected from '../../../assets/icons/selected/joker.png'
import list_selected from '../../../assets/icons/selected/list.png'
import local_police_selected from '../../../assets/icons/selected/local_police.png'
import medical_selected from '../../../assets/icons/selected/medical.png'
import menu_selected from '../../../assets/icons/selected/menu.png'
import mod_selected from '../../../assets/icons/selected/mod.png'
import notifications_selected from '../../../assets/icons/selected/notifications.png'
import penalty_selected from '../../../assets/icons/selected/penalty.png'
import plus_selected from '../../../assets/icons/selected/plus.png'
import profile_selected from '../../../assets/icons/selected/profile.png'
import profit_selected from '../../../assets/icons/selected/profit.png'
import ranking_selected from '../../../assets/icons/selected/ranking.png'
import recap_selected from '../../../assets/icons/selected/recap.png'
import red_card_selected from '../../../assets/icons/selected/red_card.png'
import relaiable_selected from '../../../assets/icons/selected/relaiable.png'
import rocket_selected from '../../../assets/icons/selected/rocket.png'
import search_selected from '../../../assets/icons/selected/search.png'
import serie_a_selected from '../../../assets/icons/selected/serie a.png'
import shield_selected from '../../../assets/icons/selected/shield.png'
import shorts_selected from '../../../assets/icons/selected/shorts.png'
import simulation_selected from '../../../assets/icons/selected/simulation.png'
import stadium_selected from '../../../assets/icons/selected/stadium.png'
import star_selected from '../../../assets/icons/selected/star.png'
import strategy_selected from '../../../assets/icons/selected/strategy.png'
import substitution_selected from '../../../assets/icons/selected/substitution.png'
import sync_player_selected from '../../../assets/icons/selected/sync_player.png'
import target_selected from '../../../assets/icons/selected/target.png'
import timer_selected from '../../../assets/icons/selected/timer.png'
import training_selected from '../../../assets/icons/selected/training.png'
import upload_selected from '../../../assets/icons/selected/upload.png'
import users_selected from '../../../assets/icons/selected/users.png'
import yellow_card_selected from '../../../assets/icons/selected/yellow_card.png'
import down_color from '../../../assets/icons/color/down.png'
import up_color from '../../../assets/icons/color/up.png'
import bet_color from '../../../assets/icons/color/bet.png'
import football_field_color from '../../../assets/icons/color/football-field.png'
import padlock_color from '../../../assets/icons/color/padlock.png'
import sub_in_color from '../../../assets/icons/color/sub_in.png'
import gol_fatto_color from '../../../assets/icons/color/gol_fatto.png'
import gol_subito_color from '../../../assets/icons/color/gol_subito.png'
import penalty_fatto_color from '../../../assets/icons/color/penalty_fatto.png'
import penalty_sbagliato_color from '../../../assets/icons/color/penalty_sbagliato.png'
import autogol_color from '../../../assets/icons/color/autogol.png'
import penalty_parato_color from '../../../assets/icons/color/penalty_parato.png'
import yellow_card_color from '../../../assets/icons/color/yellow_card.png'
import red_card_color from '../../../assets/icons/color/red_card.png'
import sub_out_color from '../../../assets/icons/color/sub_out.png'
import up_grey_color from '../../../assets/icons/color/up_grey.png'
import pdf_logo_color from '../../../assets/icons/color/pdf_logo.png'
import doc_logo_color from '../../../assets/icons/color/doc_logo.png'
import blue_diamond_color from '../../../assets/icons/color/blue_diamond.png'
import red_diamond_color from '../../../assets/icons/color/red_diamond.png'

import tipeee_logo_social from '../../../assets/icons/social/tipeee_logo.png'
import twitch_logo_social from '../../../assets/icons/social/twitch_logo.png'
import yt_logo_social from '../../../assets/icons/social/yt_logo.png'

import apple_light_social from '../../../assets/icons/social/light/apple.png'
import discord_light_social from '../../../assets/icons/social/light/discord.png'
import facebook_light_social from '../../../assets/icons/social/light/facebook.png'
import google_light_social from '../../../assets/icons/social/light/google.png'
import instagram_light_social from '../../../assets/icons/social/light/instagram.png'
import linkedin_light_social from '../../../assets/icons/social/light/linkedin.png'
import pinterest_light_social from '../../../assets/icons/social/light/pinterest.png'
import telegram_light_social from '../../../assets/icons/social/light/telegram.png'
import tiktok_light_social from '../../../assets/icons/social/light/tiktok.png'
import tipeee_light_social from '../../../assets/icons/social/light/tipeee.png'
import twitch_light_social from '../../../assets/icons/social/light/twitch.png'
import twitter_light_social from '../../../assets/icons/social/light/twitter.png'
import website_light_social from '../../../assets/icons/social/light/website.png'
import youtube_light_social from '../../../assets/icons/social/light/youtube.png'

import apple_dark_social from '../../../assets/icons/social/dark/apple.png'
import discord_dark_social from '../../../assets/icons/social/dark/discord.png'
import facebook_dark_social from '../../../assets/icons/social/dark/facebook.png'
import google_dark_social from '../../../assets/icons/social/dark/google.png'
import instagram_dark_social from '../../../assets/icons/social/dark/instagram.png'
import linkedin_dark_social from '../../../assets/icons/social/dark/linkedin.png'
import pinterest_dark_social from '../../../assets/icons/social/dark/pinterest.png'
import telegram_dark_social from '../../../assets/icons/social/dark/telegram.png'
import tiktok_dark_social from '../../../assets/icons/social/dark/tiktok.png'
import tipeee_dark_social from '../../../assets/icons/social/dark/tipeee.png'
import twitch_dark_social from '../../../assets/icons/social/dark/twitch.png'
import twitter_dark_social from '../../../assets/icons/social/dark/twitter.png'
import website_dark_social from '../../../assets/icons/social/dark/website.png'
import youtube_dark_social from '../../../assets/icons/social/dark/youtube.png'

import fantalab_logo_dark from '../../../assets/logo/dark/fantalab_logo.png'
import fantalab_logo_with_text_dark from '../../../assets/logo/dark/fantalab_logo_with_text.png'
import fantalab_logo from '../../../assets/logo/light/fantalab_logo.png'
import fantalab_logo_with_text from '../../../assets/logo/light/fantalab_logo_with_text.png'
import logo_icon from '../../../assets/logo/light/logo_icon.png'

import backgroundImage2 from '../../../assets/background/backgroundImage2.jpg'
import backgroundImageGreen from '../../../assets/background/backgroundImageGreen.jpg'
import playerInfoGreen from '../../../assets/background/playerInfoGreen.png'
import playerInfo from '../../../assets/background/playerInfo.png'
import pokerTable from '../../../assets/background/pokerTable.jpg'

import football_pitch from '../../../assets/general/football_pitch.jpg'
import premium from '../../../assets/general/premium.png'
import hourglass from '../../../assets/general/hourglass.png'
import target_example from '../../../assets/general/target_example.jpg'
import stagione from '../../../assets/general/stagione.png'
import no_logo_team from '../../../assets/general/no_logo_team.png'
import strategia from '../../../assets/general/strategia.png'
import asta from '../../../assets/general/asta.png'
import playerEmpty from '../../../assets/general/playerEmpty.png'
import money from '../../../assets/general/money.png'
import user_thumbnail from '../../../assets/general/user_thumbnail.png'
import coin_small from '../../../assets/general/coin_small.png'
import pitch_3 from '../../../assets/general/pitch_3.png'
import pitch_3_vertical from '../../../assets/general/pitch_3_vertical.png'
import pitch_prosp3 from '../../../assets/general/pitch_prosp3.png'
import pitch_prosp4 from '../../../assets/general/pitch_prosp4.png'
import pitch_field_flat_formazione from '../../../assets/general/pitch_field_flat_formazione.png'
import nome_lega from '../../../assets/general/nome_lega.jpg'

import ballottaggio from '../../../assets/icons/colored/ballottaggio.png'
import lineup from '../../../assets/icons/colored/lineup.png'
import player from '../../../assets/icons/colored/player.png'
import exchange from '../../../assets/icons/colored/exchange.png'

import creatorsList from '../../../assets/creators/CreatorsList.png'
import amiciDel65 from '../../../assets/creators/AmiciDel65.5-min.jpg'
import babboFanta from '../../../assets/creators/BabboFanta-min.jpg'
import cantarini from '../../../assets/creators/Cantarini-min.jpg'
import carmySpecial from '../../../assets/creators/CarmySpecial-min.jpg'
import fantaBros from '../../../assets/creators/FantaBros-min.jpg'
import fantaVirus from '../../../assets/creators/FantaVirus-min.jpg'
import giginho from '../../../assets/creators/Capitan Giginho-min.jpg'
import ilProfeta from '../../../assets/creators/Il Profeta-min.jpg'
import ilVeterano from '../../../assets/creators/Il Veterano-min.jpg'
import lisaOffside from '../../../assets/creators/LisaOffside-min.jpg'
import lucaDiddi from '../../../assets/creators/Luca Diddi-min.jpg'
import mrDelFanta from '../../../assets/creators/Mr Del Fanta-min.jpg'
import newFantaVision from '../../../assets/creators/NewFantaVision-min.jpg'
import recosta from '../../../assets/creators/Recosta-min.jpg'
import seriousOrNot from '../../../assets/creators/SeriousOrNot-min.jpg'
import sosFanta from '../../../assets/creators/SOS Fanta-min.jpg'
import tuttoFantacalcio from '../../../assets/creators/TuttoFantacalcio-min.jpg'
import uf from '../../../assets/creators/Universo Del Fantacalcio-min.jpg'
import walkOffSport from '../../../assets/creators/WalkOffSport-min.jpg'
import fantaBox from '../../../assets/creators/FantaBox-min.jpg'
import fabryFantaMister from '../../../assets/creators/Fabri Fanta Mister-min.jpg'
import fantaboom from '../../../assets/creators/FantaBoom-min.jpg'
import fantaDomenica from '../../../assets/creators/FantaDomenica-min.jpg'
import fantaReporters from '../../../assets/creators/Fanta Reporters-min.jpg'
import i2fantallenatori from '../../../assets/creators/I2Fantallenatori-min.jpg'
import airway from '../../../assets/creators/Airway-min.jpg'

//UNIVERSAL
import iphone from '../../../assets/universal/iPhone.png'

//MOCKUPS IPHONE
import iphone_mockup_stagione from '../../../assets/mockups/mobile/stagione.webp'
import iphone_mockup_home from '../../../assets/mockups/mobile/home.webp'
import iphone_mockup_asta from '../../../assets/mockups/mobile/asta.webp'
import iphone_mockup_strategia from '../../../assets/mockups/mobile/strategia.webp'
import iphone_mockup_giocatore from '../../../assets/mockups/mobile/giocatore.webp'
import iphone_mockup_confronto from '../../../assets/mockups/mobile/confronto.webp'
import iphone_mockup_guida from '../../../assets/mockups/mobile/guida.webp'

//MOCKUPS IPAD
import ipad_mockup_stagione from '../../../assets/mockups/ipad/stagione.webp'
import ipad_mockup_home from '../../../assets/mockups/ipad/home.webp'
import ipad_mockup_asta from '../../../assets/mockups/ipad/asta.webp'
import ipad_mockup_strategia from '../../../assets/mockups/ipad/strategia.webp'
import ipad_mockup_giocatore from '../../../assets/mockups/ipad/giocatore.webp'
import ipad_mockup_confronto from '../../../assets/mockups/ipad/confronto.webp'
import ipad_mockup_guida from '../../../assets/mockups/ipad/guida.webp'

export const assets = {
   general: {
      football_pitch,
      premium,
      hourglass,
      target_example,
      stagione,
      no_logo_team,
      strategia,
      asta,
      playerEmpty,
      money,
      user_thumbnail,
      coin_small,
      pitch_3,
      pitch_3_vertical,
      pitch_prosp3,
      pitch_prosp4,
      pitch_field_flat_formazione,
      nome_lega
   },
   background: {
      backgroundImage2,
      pokerTable,
      playerInfoGreen,
      playerInfo,
      backgroundImageGreen
   },
   logo: {
      light: {
         fantalab_logo,
         fantalab_logo_with_text,
         logo_icon
      },
      dark: {
         fantalab_logo: fantalab_logo_dark,
         fantalab_logo_with_text: fantalab_logo_with_text_dark
      }
   },
   creators:{
      creatorsList:creatorsList,
      amiciDel65:amiciDel65,
      babboFanta:babboFanta,
      cantarini:cantarini,
      carmySpecial:carmySpecial,
      fantaBros:fantaBros,
      fantaVirus:fantaVirus,
      ilProfeta:ilProfeta,
      ilVeterano:ilVeterano,
      lisaOffside:lisaOffside,
      lucaDiddi:lucaDiddi,
      mrDelFanta:mrDelFanta,
      newFantaVision:newFantaVision,
      recosta:recosta,
      seriousOrNot:seriousOrNot,
      tuttoFantacalcio:tuttoFantacalcio,
      uf,
      walkOffSport:walkOffSport,
      fantaBox,
      fabryFantaMister:fabryFantaMister,
      fantaboom,
      fantaDomenica,
      ilCalciologo:i2fantallenatori,
      sosFanta:sosFanta,
      airway:airway,
      giginho:giginho,
      fantaReporters:fantaReporters,
   },
   icons:{
      light:{
         add,
         admin,
         assist,
         balance,
         ball,
         barrier,
         bench,
         bin,
         bonus_minuti,
         bonus_partite,
         calendar,
         cancel,
         cards,
         close_circle,
         cog,
         coin,
         comment,
         cup,
         deal,
         diamond,
         download,
         edit,
         error,
         eye,
         field,
         football_field,
         football_field_in_perspective,
         freekick,
         gavel,
         gloves,
         gol_subito,
         graph,
         graph_1,
         head_ball,
         heart,
         help,
         history,
         imbattibilita,
         influencers,
         jersey,
         jersey_ball,
         joker,
         kicking_ball,
         list,
         local_police,
         long_shoot,
         medical,
         menu,
         mod,
         notifications,
         padlock_solid,
         passing,
         penalty,
         penalty_failed,
         phishing,
         plus,
         precision,
         profile,
         profit,
         ranking,
         recap,
         red_card,
         relaiable,
         rocket,
         scambio,
         search,
         serie_a,
         six,
         shield,
         shoot,
         shoot_net,
         shorts,
         simulation,
         stadium,
         star,
         strategy,
         substitution,
         sync_player,
         target,
         three_dots,
         timer,
         training,
         upload,
         users,
         whistle,
         yellow_card,
      },
      dark:{
         add: add_dark,
         admin: admin_dark,
         assist: assist_dark,
         balance: balance_dark,
         ball: ball_dark,
         barrier: barrier_dark,
         bench: bench_dark,
         bin: bin_dark,
         bonus_minuti: bonus_minuti_dark,
         bonus_partite: bonus_partite_dark,
         cancel: cancel_dark,
         cards: cards_dark,
         close_circle: close_circle_dark,
         cog: cog_dark,
         coin: coin_dark,
         cup: cup_dark,
         deal: deal_dark,
         diamond: diamond_dark,
         download: download_dark,
         edit: edit_dark,
         eye: eye_dark,
         field: field_dark,
         football_field: football_field_dark,
         football_field_in_perspective: football_field_in_perspective_dark,
         freekick: freekick_dark,
         gavel: gavel_dark,
         gloves: gloves_dark,
         gol_subito: gol_subito_dark,
         graph: graph_dark,
         graph_1: graph_1_dark,
         heart: heart_dark,
         help: help_dark,
         history: history_dark,
         imbattibilita: imbattibilita_dark,
         influencers: influencers_dark,
         jersey: jersey_dark,
         jersey_ball: jersey_ball_dark,
         joker: joker_dark,
         list: list_dark,
         local_police: local_police_dark,
         medical: medical_dark,
         menu: menu_dark,
         mod: mod_dark,
         notifications: notifications_dark,
         penalty: penalty_dark,
         plus: plus_dark,
         profile: profile_dark,
         profit: profit_dark,
         ranking: ranking_dark,
         recap: recap_dark,
         red_card: red_card_dark,
         relaiable: relaiable_dark,
         rocket: rocket_dark,
         search: search_dark,
         serie_a: serie_a_dark,
         shield: shield_dark,
         shorts: shorts_dark,
         simulation: simulation_dark,
         stadium: stadium_dark,
         star: star_dark,
         strategy: strategy_dark,
         substitution: substitution_dark,
         sync_player: sync_player_dark,
         target: target_dark,
         timer: timer_dark,
         training: training_dark,
         upload: upload_dark,
         users: users_dark,
         yellow_card: yellow_card_dark,
      },
      selected:{
         add: add_selected,
         admin: admin_selected,
         assist: assist_selected,
         balance: balance_selected,
         ball: ball_selected,
         barrier: barrier_selected,
         bench: bench_selected,
         bin: bin_selected,
         cancel: cancel_selected,
         cards: cards_selected,
         close_circle: close_circle_selected,
         cog: cog_selected,
         coin: coin_selected,
         cup: cup_selected,
         deal: deal_selected,
         diamond: diamond_selected,
         download: download_selected,
         edit: edit_selected,
         eye: eye_selected,
         field: field_selected,
         football_field: football_field_selected,
         football_field_in_perspective: football_field_in_perspective_selected,
         freekick: freekick_selected,
         gavel: gavel_selected,
         gloves: gloves_selected,
         gol_subito: gol_subito_selected,
         graph: graph_selected,
         graph_1: graph_1_selected,
         heart: heart_selected,
         help: help_selected,
         history: history_selected,
         imbattibilita: imbattibilita_selected,
         influencers: influencers_selected,
         jersey: jersey_selected,
         jersey_ball: jersey_ball_selected,
         joker: joker_selected,
         list: list_selected,
         local_police: local_police_selected,
         medical: medical_selected,
         menu: menu_selected,
         mod: mod_selected,
         notifications: notifications_selected,
         penalty: penalty_selected,
         plus: plus_selected,
         profile: profile_selected,
         profit: profit_selected,
         ranking: ranking_selected,
         recap: recap_selected,
         red_card: red_card_selected,
         relaiable: relaiable_selected,
         rocket: rocket_selected,
         search: search_selected,
         serie_a: serie_a_selected,
         shield: shield_selected,
         shorts: shorts_selected,
         simulation: simulation_selected,
         stadium: stadium_selected,
         star: star_selected,
         strategy: strategy_selected,
         substitution: substitution_selected,
         sync_player: sync_player_selected,
         target: target_selected,
         timer: timer_selected,
         training: training_selected,
         upload: upload_selected,
         users: users_selected,
         yellow_card: yellow_card_selected,
      },
      colored:{
         scambio:scambio,
         ballottaggio:ballottaggio,
         formazione:lineup,
         player:player,
         exchange:exchange,
      },
      color: {
         down: down_color,
         up: up_color,
         bet: bet_color,
         football_field: football_field_color,
         padlock: padlock_color,
         sub_in: sub_in_color,
         gol_fatto: gol_fatto_color,
         gol_subito: gol_subito_color,
         penalty_fatto: penalty_fatto_color,
         penalty_sbagliato: penalty_sbagliato_color,
         autogol: autogol_color,
         penalty_parato: penalty_parato_color,
         yellow_card: yellow_card_color,
         red_card: red_card_color,
         sub_out: sub_out_color,
         up_grey: up_grey_color,
         pdf_logo: pdf_logo_color,
         doc_logo: doc_logo_color,
         blue_diamond: blue_diamond_color,
         red_diamond: red_diamond_color,
      },
      social: {
         light: {
            apple: apple_light_social,
            discord: discord_light_social,
            facebook: facebook_light_social,
            google: google_light_social,
            instagram: instagram_light_social,
            linkedin: linkedin_light_social,
            pinterest: pinterest_light_social,
            telegram: telegram_light_social,
            tiktok: tiktok_light_social,
            tipeee: tipeee_light_social,
            twitch: twitch_light_social,
            twitter: twitter_light_social,
            website: website_light_social,
            youtube: youtube_light_social,
         },
         dark: {
            apple: apple_dark_social,
            discord: discord_dark_social,
            facebook: facebook_dark_social,
            google: google_dark_social,
            instagram: instagram_dark_social,
            linkedin: linkedin_dark_social,
            pinterest: pinterest_dark_social,
            telegram: telegram_dark_social,
            tiktok: tiktok_dark_social,
            tipeee: tipeee_dark_social,
            twitch: twitch_dark_social,
            twitter: twitter_dark_social,
            website: website_dark_social,
            youtube: youtube_dark_social,
         },
         tipeee_logo: tipeee_logo_social,
         twitch_logo: twitch_logo_social,
         yt_logo: yt_logo_social,
      }
   },
   mockups:{
      mobile:{
         stagione: iphone_mockup_stagione,
         confronto: iphone_mockup_confronto,
         home: iphone_mockup_home,
         strategia: iphone_mockup_strategia,
         asta: iphone_mockup_asta,
         guida: iphone_mockup_guida,
         giocatore: iphone_mockup_giocatore,
      },
      ipad:{
         stagione: ipad_mockup_stagione,
         confronto: ipad_mockup_confronto,
         home: ipad_mockup_home,
         strategia: ipad_mockup_strategia,
         asta: ipad_mockup_asta,
         guida: ipad_mockup_guida,
         giocatore: ipad_mockup_giocatore,
      }
   },
   universal:{
      iphone:iphone,
   }
}
