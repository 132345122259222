import React, {useEffect, useState} from 'react'
import {Collapse, Fade, Slide} from "@mui/material";
import PropTypes from "prop-types";

const Transition = ({type = 'fade', timeout, delay, sx, children, ...rest}) => {
    const [timeoutCompleted, setTimeoutCompleted] = useState(false)

    useEffect(() => {
        if (delay > 0) {
            setTimeout(() => {
                setTimeoutCompleted(true)
            }, delay)
        } else {
            setTimeoutCompleted(true)
        }
    }, [])

    return (
        <>
            {type === 'fade' ?
                <Fade in={rest.in && timeoutCompleted} style={sx} timeout={timeout} {...rest}>
                    <div>{children}</div>
                </Fade>
                : type === 'slide' ?
                    <Slide in={rest.in && timeoutCompleted} style={sx} timeout={timeout} {...rest}>
                        <div>{children}</div>
                    </Slide>
                    : type === 'collapse' ?
                        <Collapse in={rest.in && timeoutCompleted} style={sx} timeout={timeout} {...rest}>
                            <div>{children}</div>
                        </Collapse>
                    : null
            }
        </>
    )
}

Transition.propTypes = {
    type: PropTypes.oneOf([false, 'fade', 'slide', 'collapse']),
}
export default Transition
