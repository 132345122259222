import { useState, useEffect } from "react";
import { Transition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
   transition: `opacity ${duration}ms ease-in-out`,
   opacity: 0,
   position: "absolute"
}

const transitionStyles = {
   entering: { opacity: 0 },
   entered:  { opacity: 1 },
   exiting:  { opacity: 0 },
   exited:  { opacity: 0 },
};

const Image = ({ src, emptyImage, fade = true, width, height, alt = 'defaultAltImage', sx, round, innerShadow, backgroundColor, border, objectFit = 'cover', objectPosition='center' }) => {
   const [internalSrc, setInternalSrc] = useState(src || emptyImage);
   const [inProp, setInProp] = useState(true);

   useEffect(() => {
      setInProp(false);
   }, [src]);

   const onExited = () => {
      setInternalSrc(src || emptyImage);
      setInProp(true);
   };

   return (
      <div
         style={{
            width: width,
            height: height,
            borderRadius: round ? 1000 : undefined,
            position: "relative",
            overflow: "hidden",
            backgroundColor: backgroundColor || undefined,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx
         }}
      >
         <Transition in={inProp} timeout={duration} onExited={onExited} unmountOnExit>
            {(state) => (
               <img
                  src={internalSrc}
                  alt={alt}
                  style={{
                     ...defaultStyle,
                     ...transitionStyles[state],
                     width: '100%',
                     height: '100%',
                     objectFit: objectFit,
                     objectPosition: objectPosition,
                     borderRadius: round ? 1000 : undefined,
                     boxShadow: innerShadow ? "0 0 10px rgba(0,0,0,0.5)" : undefined,
                     border: border || undefined
                  }}
               />
            )}
         </Transition>
      </div>
   );
};

export default Image;
