import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'style',
   initialState: {
      miniSidenav : true,
      onMouseEnter : false,
   },
   reducers: {
      setMiniSidenav: (
         state,
         action
      ) => {
         state.miniSidenav = action.payload.miniSidenav
      },
      setOnMouseEnter: (
          state,
          action
      ) => {
         state.onMouseEnter = action.payload.onMouseEnter
      },
   },
})

export const { setMiniSidenav, setOnMouseEnter } = slice.actions

export default slice.reducer

