import React from 'react';
import View from "../components/MD/View";
import Typography from "../components/MD/Typography";
import SignupButton from "../components/SignupButton/SingnupButton";


const SignupPanel = ({}) => {
    return (
        <View fullWidth centerCenter maxWidth={'sm'}>
            {/*<Typography align={'center'} variant={'h1'} component={'h2'} capitalize>Vinci il fantacalcio</Typography>
            */}
                <SignupButton
                    position={'bottom'}
                />
        </View>
    )
}

export default SignupPanel
