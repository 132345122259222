import React, {useEffect} from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import theme from "./theme/theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import routes from "./routes";
import {MaterialUIControllerProvider} from "./layouts/context";
import {CookiesProvider} from 'react-cookie';
import {ViewportProvider} from "./hooks/useViewport";
import {Provider} from "react-redux";
import {store} from "./config/store";
import {ResProvider} from "./hooks/useRes";



const App = () => {

   const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
         if (route.collapse) {
            return getRoutes(route.collapse);
         }
         if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key}/>;
         }
         return null;
      });

   return (
      <div className="App">
         <Provider store={store}>
            <ThemeProvider theme={theme}>
               <ResProvider value={'dark'}>
                  <header className="App-header">
                     <ViewportProvider>
                        <BrowserRouter>
                           <MaterialUIControllerProvider>
                              <CookiesProvider>
                                 <Routes>
                                    <>
                                       {getRoutes(routes)}
                                    </>
                                 </Routes>
                              </CookiesProvider>
                              {/*<MessengerCustomerChat
                                 pageId="101739502637254"
                                 appId="5178242762273648"
                              />*/}
                           </MaterialUIControllerProvider>
                        </BrowserRouter>
                     </ViewportProvider>
                  </header>
               </ResProvider>
            </ThemeProvider>
         </Provider>
      </div>
   );
}

export default App;
