import Icon from "@mui/material/Icon";
import LandingPage from "./containers/LandingPage";
import React from "react";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage";
import Button from "./components/MD/Button";
import View from "./components/MD/View";
import RedirectPage from "./containers/RedirectPage";
import SignOutMarketingPage from "./containers/SignOutMarketingPage";

const routes = [
   {
      type: "",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/",
      component: <LandingPage/>,
   },
   {
      type: "",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/:id",
      component: <LandingPage/>,
   },
   {
      type: "",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/privacy-policy",
      component: <PrivacyPolicyPage/>

   },
   {
      type: "",
      name: "Smart Link",
      key: "smart",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/smart/:id",
      component: <LandingPage/>
   },
   {
      type: "",
      name: "Apri App",
      key: "open_app",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/app/:id",
      component: <RedirectPage/>
   },
   {
      type: "",
      name: "Apri App",
      key: "open_app",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/sign-out-marketing/:email",
      component: <SignOutMarketingPage/>
   },
   {
      type: "",
      name: "Apri App",
      key: "open_app",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/sign-out-marketing",
      component: <SignOutMarketingPage/>
   },
   
   
];

export default routes;
