import View from "../../components/MD/View";
import React from 'react';
import {creatorsList} from "../../utils/fantacalcio/creators/creators";
import Typography from "../../components/MD/Typography";
import InfiniteLooper from "../../components/Animations/InfiniteLooper/InfiniteLooper";
import Image from "../../components/ImageCustom/Image";
import useViewport from "../../hooks/useViewport";

const CreatorsList = () => {
   const viewport = useViewport()
   return (
      <View minWidth={'100%'} direction={'row'}>
         <InfiniteLooper speed="75">
            {creatorsList.map((creator, index) => (
               <div
                  key={index}
                  style={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     width: viewport.isXS ? 100 : 93,
                     marginRight: viewport.isXS ? 2 : 2,
                     marginLeft: viewport.isXS ? 2 : 2,
                     padding: 0,
                     flexDirection: 'column',
                     gap:10,
                     overflow:'hidden',
                  }}
               >
                  <Image
                     round
                     src={creator.logo}
                     width={70}
                     height={70}
                     sx={{border:'solid 2px rgba(255,255,255,1)'}}
                  />
                  <Typography maxLines={1} variant={viewport.isXS ? 'h7':'h8'}>{creator.name}</Typography>
               </div>
            ))}
         </InfiniteLooper>
      </View>
   )
}

export default CreatorsList
