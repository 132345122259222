import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";
import boxShadows from "../../boxShadows";
import linearGradient from "../../../functions/linearGradient";

const {transparent} = palette;
const {borderRadius} = borders;
const {coloredShadow} = boxShadows;

const stepper = {
    styleOverrides: {
        root: {
            background: linearGradient(palette.info.gradient, palette.info.main),
            padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
            borderRadius: borderRadius.lg,
            boxShadow: coloredShadow.info,

            "&.MuiPaper-root": {
                backgroundColor: transparent.main,
            },
        },
    },
};

export default stepper;
