import spacing from './spacing'
import typography from './typography'
import dimensions from "./dimensions"
import palette from "./palette";
import globals from "./globals";
import elements from "./elements";
import layout from "./layout";
import breakpoints from "./breakpoints";
import boxShadows from "./boxShadows";
import borders from "./borders";
import specific from "./specific";

const R = {borders, boxShadows, breakpoints, dimensions, elements, globals, layout, palette, spacing, typography, specific};

export default R;
