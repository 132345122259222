import React from 'react';
import {Snackbar as MuiSnackbar} from "@mui/material";
import Alert from "./Alert";
import Icon from "./Icon";
import IconButton from "./IconButton";
import Button from "./Button";

const Snackbar = ({message,
                      severity,
                      open,
                      horizontal='left',
                      vertical='bottom',
                      onClose,
                      autoHideDuration=3000,
                      ...rest}) => {

    return (
        <MuiSnackbar
            open={open}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{horizontal: horizontal, vertical: vertical}}
            {...rest}
        >
            <Alert severity={severity} variant="filled" onClose={onClose}>{message}</Alert>
        </MuiSnackbar>
    );
};

Snackbar.propTypes = {};

export default Snackbar;
