import React, {useEffect} from "react";
import {setPremiumModal} from "../../../config/slices/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import SidenavRoot from "./SidenavRoot";
import Logo from "../../../components/Logo/Logo";
import Typography from "../../../components/MD/Typography";
import Button from "../../../components/MD/Button";
import View from "../../../components/MD/View";
import Divider from "../../../components/MD/Divider";
import Icon from "../../../components/MD/Icon";
import {setMiniSidenav} from "../../../config/slices/styleSlice";
import Transition from "../../../components/MD/Transition/Transition";
import MenuList from "../Menu/MenuList";
import {isTablet} from "react-device-detect";


function Sidenav({darkMode, sidenavColor, color, routes, miniSidenav, ...rest}) {
   const dispatch = useDispatch();

   const location = useLocation();
   const collapseName = location.pathname.replace("/", "");
   /*const [fantaleagues] = useGetFantaLeagues();
   const subListLeagues = React.useMemo(() => {
       if (fantaleagues) {
           return addFantaLeaguesRoutes(fantaleagues)
       }
   }, [fantaleagues]);*/

   const closeSidenav = () => dispatch(setMiniSidenav({miniSidenav: true}))

   useEffect(() => {
      // A function that sets the mini state of the sidenav.
      function handleMiniSidenav() {
         //dispatch(setMiniSidenav({miniSidenav: !!window.innerWidth < 1200}))
      }

      /**
       The event listener that's calling the handleMiniSidenav function when resizing the window.
       */
      window.addEventListener("resize", handleMiniSidenav);

      // Call the handleMiniSidenav function to set the state with the initial value.
      handleMiniSidenav();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleMiniSidenav);
   }, [dispatch, location]);


   // -----------------------------------------
   const premium = useSelector((state) => state.auth.premium)

   // -----------------------------------------

   // Render all the routes from the routes.js (All the visible items on the Sidenav)


   return (
      <SidenavRoot
         {...rest}
         variant="permanent"
         ownerState={{miniSidenav, darkMode}}
      >
         <View spacing={2} px={1}>
            <View pt={3} pb={1} pl={0.5} textAlign="center" flex justify={'flex-start'}>
               <View pl={0}>
                  <Logo size={40} onlyImage/>
               </View>
               <View
                  display={isTablet && !miniSidenav ? "block" : 'none'}
                  position="absolute"
                  top={0}
                  right={0}
                  p={1.625}
                  onClick={closeSidenav}
                  sx={{cursor: "pointer"}}
               >
                  <Typography variant="h6" color="secondary">
                     <Icon sx={{fontWeight: "bold"}}>close</Icon>
                  </Typography>
               </View>
            </View>
            <Divider light/>
            <MenuList routes={routes} miniSidenav={miniSidenav}/>
         </View>
         {!miniSidenav && !premium &&
            <View pb={1} px={1} mt="auto">
               <Transition timeout={1000} in={!miniSidenav && !premium}>
                  <Button
                     rel="noreferrer"
                     variant="contained"
                     color={'primary'}
                     fullWidth
                     onClick={() => dispatch(setPremiumModal({open: true}))}
                  >
                     upgrade to pro
                  </Button>
               </Transition>
            </View>
         }
         {/*{!miniSidenav &&
               <View pb={2} px={1} mt={1}>
                   <Transition timeout={1000} in={!miniSidenav}>
                       <Button
                          rel="noreferrer"
                          variant="text"
                          color={'primary'}
                          fullWidth
                          onClick={() => Auth.signOut()}
                       >
                           Logout
                       </Button>
                   </Transition>
               </View>
            }*/}
      </SidenavRoot>
   );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
   color: "primary",
   brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
   // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
