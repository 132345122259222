import typography from "../../typography";
import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";

const {dark} = palette;
const {size, fontWeightBold} = typography;

const formControlLabel = {
    styleOverrides: {
        root: {
            display: "block",
            minHeight: pxToRem(24),
            marginBottom: pxToRem(2),
        },

        label: {
            display: "inline-block",
            fontSize: size.sm,
            fontWeight: fontWeightBold,
            color: dark.main,
            lineHeight: 1,
            transform: `translateY(${pxToRem(1)})`,
            marginLeft: pxToRem(4),

            "&.Mui-disabled": {
                color: dark.main,
            },
        },
    },
};

export default formControlLabel;
