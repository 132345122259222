import React from 'react';
import {CircularProgress as MuiCircularProgress} from "@mui/material";

const CircularProgress = ({className, size = 20, ...rest}) => {

    return (
        <MuiCircularProgress
            size={size}
            {...rest}
            style={className}
        />
    );
};

CircularProgress.propTypes = {};

export default CircularProgress;
