import palette from "../palette";
import pxToRem from "../../functions/pxToRem";
import borders from "../borders";

const {white} = palette;
const {borderRadius} = borders;

const sidenav = {
    styleOverrides: {
        root: {
            //width: pxToRem(250),
            whiteSpace: "nowrap",
            border: "none",
        },

        paper: {
            //width: pxToRem(250),
            backgroundColor: white.main,
            height: `calc(100vh - ${pxToRem(32)})`,
            margin: pxToRem(0),
            marginTop: pxToRem(16),
            borderRadius: borderRadius.xl,
            borderBottomLeftRadius:0,
            borderTopLeftRadius:0,
            border: "none",
        },

        paperAnchorDockedLeft: {
            borderRight: "none",
        },
    },
};

export default sidenav;
