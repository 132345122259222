import pxToRem from "../functions/pxToRem";
import palette from "./palette";

// Material Dashboard 2 React Helper Functions

const { colors, inputBorderColor } = palette;

const borders = {
  borderColor: inputBorderColor,

  borderWidth: {
    0: 0,
    1: pxToRem(1),
    2: pxToRem(2),
    3: pxToRem(3),
    4: pxToRem(4),
    5: pxToRem(5),
  },

  borderRadius: {
    xs: pxToRem(1.6),
    sm: pxToRem(2),
    md: pxToRem(6),
    lg: pxToRem(8),
    xl: pxToRem(16),
    xxl: pxToRem(24),
    xxxl: pxToRem(32),
    section: pxToRem(160),
    card: pxToRem(16),
    button: pxToRem(12),
    input: pxToRem(8),
  },

  borderPaper:{
     0:`solid 3px ${inputBorderColor}`,
     1:`solid 1px ${inputBorderColor}`,
     2:`solid 2px ${inputBorderColor}`,
     3:`solid 3px ${inputBorderColor}`,
     4:`solid 4px ${inputBorderColor}`,
     5:`solid 5px ${inputBorderColor}`,
  }
};

export default borders;
