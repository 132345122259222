import {assets} from "../assets/assets";

export const creatorsList = [
   {
      name: 'CarmySpecial',
      logo: assets.creators.carmySpecial,
      priority: 1
   },
   {
      name: 'Lisa Offside',
      logo: assets.creators.lisaOffside,
      priority: 1
   },
   {
      name: 'Il Profeta',
      logo: assets.creators.ilProfeta,
      priority: 1
   },
   {
      name: 'SOS Fanta',
      logo: assets.creators.sosFanta,
      priority: 1
   },
   {
      name: 'Recosta',
      logo: assets.creators.recosta,
      priority: 2
   },
   {
      name: 'Cantarini',
      logo: assets.creators.cantarini,
      priority: 2
   },
   {
      name: 'Luca Diddi',
      logo: assets.creators.lucaDiddi,
      priority: 2
   },
   {
      name: 'FantaBros',
      logo: assets.creators.fantaBros,
      priority: 2
   },


   {
      name: 'NewFantaVision',
      logo: assets.creators.newFantaVision,
      priority: 2
   },
   {
      name: 'TuttoFantacalcio',
      logo: assets.creators.tuttoFantacalcio,
      priority: 3
   },
   {
      name: 'SeriousOrNot',
      logo: assets.creators.seriousOrNot,
      priority: 3
   },
   {
      name: 'FantaBox',
      logo: assets.creators.fantaBox,
      priority: 3
   },
   {
      name: 'FantaMister',
      logo: assets.creators.fabryFantaMister,
      priority: 3
   },
   {
      name: 'FantaBoom',
      logo: assets.creators.fantaboom,
      priority: 3
   },
   {
      name: 'FantaVirus',
      logo: assets.creators.fantaVirus,
      priority: 3
   },
   {
      name: 'MrDelFanta',
      logo: assets.creators.mrDelFanta,
      priority: 3
   },
   {
      name: 'WalkOffSport',
      logo: assets.creators.walkOffSport,
      priority: 3
   },
   {
      name: 'Il Veterano',
      logo: assets.creators.ilVeterano,
      priority: 3
   },
   {
      name: 'BabboFanta',
      logo: assets.creators.babboFanta,
      priority: 3
   },
   {
      name: 'FantaDomenica',
      logo: assets.creators.fantaDomenica,
      priority: 3
   },
   {
      name: 'Amici Del 65.5',
      logo: assets.creators.amiciDel65,
      priority: 3
   },
   {
      name: 'Il Calciologo',
      logo: assets.creators.ilCalciologo,
      priority: 3
   },
   {
      name: 'UniversoDelFanta',
      logo: assets.creators.uf,
      priority: 3
   },
   {
      name: 'AirWay',
      logo: assets.creators.airway,
      priority: 3
   },


]
