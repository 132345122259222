import React from 'react';
import PropTypes from 'prop-types';
import {Typography as MuiTypography} from "@mui/material";
import R from "../../theme/styles";
import {buildStyle} from "../../utils/style/buildStyle";
import {alpha} from "../../theme/functions/colorManipulation";
import View from "./View";
import useRes from "../../hooks/useRes";

const Typography = ({
                       className,
                       children,
                       align = 'inherit',
                       color,
                       variant = 'body1',
                       lineHeight,
                       component,
                       paragraph,
                       noWrap,
                       maxLines,
                       weight,
                       clickable,
                       underline,
                       oppositeColor,
                       italic,
                       uppercase,
                       lowercase,
                       capitalize,
                       fullWidth,
                       fontSize,
                       highlightType,
                       highlightColor,
                       highlightStyle,
                       sx,
                       ...rest
                    }) => {
   const {palette} = useRes()
   //const isDefaultColor = color && (color === 'textPrimary' || color === 'textSecondary' || color === 'initial' || color === 'inherit' || color === 'primary' || color === 'secondary' || color === 'error');

   let finalColor = undefined
   if (color) {
      if (color === 'textPrimary') {
         finalColor = palette.text.primary
      } else if (color === 'textSecondary') {
         finalColor = palette.text.secondary
      } else if (color === 'textStrong') {
         finalColor = palette.text.strong
      } else if (color === 'textAlpha') {
         finalColor = palette.text.alpha
      } else if (palette[color]?.main) {
         finalColor = palette[color].main
      } else {
         finalColor = color
      }
   } else if (oppositeColor) {
      if (oppositeColor === 'secondary') {
         finalColor = palette.text.opposite.secondary
      } else if (oppositeColor === 'strong') {
         finalColor = palette.text.opposite.strong
      } else {
         finalColor = palette.text.opposite.primary
      }
   }


   const classes = {
      root: {
         cursor: 'default'
      },
      oneLineNoWrap: {
         whiteSpace: 'nowrap',
         overflow: 'visible',
      },
      maxLines: {
         WebkitBoxOrient: "vertical",
         WebkitLineClamp: maxLines ? maxLines : 1,
         display: "-webkit-box",
         whiteSpace: 'normal',
         wordBreak: 'break-word',
         overflow: 'hidden',
         textOverflow: 'ellipsis'
      },
      color: {
         color: `${finalColor} !important`,
      },
      weight: {
         fontWeight: weight && weight,
      },
      clickable: {
         cursor: 'pointer',
         '&:hover': {
            textDecoration: 'underline'
         }
      },
      underline: {
         textDecoration: 'underline'
      },
      italic: {
         fontStyle: 'italic'
      },
      uppercase: {
         textTransform: 'uppercase'
      },
      lowercase: {
         textTransform: 'lowercase'
      },
      capitalize: {
         textTransform: 'capitalize'
      },
      fullWidth: {
         width: '100%'
      },
      lineHeight: {
         lineHeight: lineHeight
      },
      fontSize: {
         fontSize: R.typography.size[fontSize] ? `${R.typography.size[fontSize]} !important` : `${fontSize}px !important`
      },
      highlightLine: {
         py: highlightType !== 'box' && 1,
         px: highlightType !== 'box' && 1,
         backgroundColor: highlightType === 'textBackground' && alpha(highlightColor || palette.primary.main, 0.2)
      }
   };

   const style = buildStyle(classes, {
      root: true,
      oneLineNoWrap: noWrap === 'visible',
      maxLines: maxLines,
      color: color || oppositeColor,
      //oppositeColor: oppositeColor && (!color || isDefaultColor),
      weight: weight,
      clickable: clickable,
      underline: underline,
      italic: italic,
      uppercase: uppercase,
      lowercase: lowercase,
      capitalize: capitalize,
      fullWidth: fullWidth,
      fontSize: fontSize,
      lineHeight: lineHeight,
      highlightLine: highlightType,
   }, sx);

   const buildTypography = () => (
      <>
         {highlightType &&
            <div
               style={{
                  width: 7,
                  marginRight: 10,
                  height: 'inherit',
                  backgroundColor: palette[highlightColor]?.main || highlightColor || palette.primary.main
               }}
            />
         }
         <MuiTypography
            align={align}
            variant={variant}
            component={component}
            paragraph={paragraph}
            noWrap={!!noWrap}
            className={className}
            {...rest}
            sx={style}
            //color={isDefaultColor && !oppositeColor ? color : null}
         >
            {children}
         </MuiTypography>
      </>
   )

   return (
      highlightType === 'box' ?
         <View
            px={2}
            py={3}
            direction={'row'}
            borderRadius={R.borders.borderRadius.card}
            backgroundColor={palette[highlightColor]?.main || highlightColor || palette.background.secondary}
            sx={highlightStyle}
         >
            {buildTypography()}
         </View>
         : buildTypography()
   );
};

Typography.propTypes = {
   children: PropTypes.node,
   align: PropTypes.string,
   color: PropTypes.string || PropTypes.oneOf([
      'textPrimary',
      'textSecondary',
      'textStrong',
      'textAlpha',
      'primary',
      'secondary',
      'success',
      'error',
      'info',
      'warning',
      'dark',
      'light',
      'white',
      'black',
      'inherit',
      'initial',
   ]),
   oppositeColor: PropTypes.oneOf([
      true,
      false,
      'primary',
      'secondary',
      'strong',
   ]),
   variant: PropTypes.oneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'h7',
      'h8',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'body3',
      'caption',
      'button',
      'overline',
      'srOnly',
      'inherit',
   ]),
   component: PropTypes.elementType,
   clickable: PropTypes.bool,
   underline: PropTypes.bool,
   paragraph: PropTypes.bool,
   noWrap: PropTypes.oneOfType([PropTypes.oneOf(['visible']), PropTypes.bool]),
   maxLines: PropTypes.number,
   weight: PropTypes.oneOf([
      'normal',
      'bold',
      'inherit',
      'initial',
      100,
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
   ]),
   capitalize: PropTypes.bool,
   lowercase: PropTypes.bool,
   uppercase: PropTypes.bool,
   fontSize: PropTypes.number || PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
   highlightType: PropTypes.oneOf(['normal', 'box', 'textBackground']),
};

export default Typography;
