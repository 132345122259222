/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React Helper Functions
import pxToRem from "../../../functions/pxToRem";

const cardContent = {
    styleOverrides: {
        root: {
            marginTop: 0,
            marginBottom: 0,
            padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
        },
    },
};

export default cardContent;
