import React from 'react';
import PropTypes from 'prop-types';
import S from "../../../theme/styles";
import {List as MuiList} from "@mui/material";
import {buildStyle} from "../../../utils/style/buildStyle";

const List = ({children, flex, horizontal, sx, ...rest}) => {

    const classes = {
        root: {},
        flex: {
            ...S.layout.flex,
        },
        horizontal: {
            ...S.layout.horizontal,
        },
    }
    const style = buildStyle(classes, {
        root: true,
        flex: flex,
        horizontal: horizontal,
    }, sx);

    return (
        <MuiList
            sx={style}
            {...rest}
        >
            {children}
        </MuiList>
    );
};

List.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    flex: PropTypes.bool,
    horizontal: PropTypes.bool,
};

List.defaultProps = {
    flex: false,
    horizontal: false,
};

export default List;
