import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";

const {transparent} = palette;

const select = {
    styleOverrides: {
        root:{

        },
        select: {




        },

        selectMenu: {
            /*height: "none",
            minHeight: "none",
            overflow: "unset",*/
        },

        icon: {
            //display: "none",
            //color:'white !important'
        },
        /*'label + &': {
            marginTop: 24,
        },*/
    },
};

export default select;
