import palette from "../palette";
import pxToRem from "../../functions/pxToRem";
import borders from "../borders";
import boxShadows from "../boxShadows";

const {transparent} = palette;
const {lg} = boxShadows;
const {borderRadius} = borders;

const popover = {
    styleOverrides: {
        paper: {
            backgroundColor: transparent.main,
            boxShadow: lg,
            padding: pxToRem(8),
            borderRadius: borderRadius.md,
        },
    },
};

export default popover;
