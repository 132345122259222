import palette from "../../palette";
import borders from "../../borders";

const {transparent} = palette;
const {borderRadius} = borders;

const textField = {
    styleOverrides: {
        root: {
            //borderRadius: borderRadius.md,
            //backgroundColor: 'rgba(255,255,255,0.2)',
        },
    },
    defaultProps: {
        margin: 'none',
    },
};

export default textField;
