import View from "../MD/View";
import Image from "../ImageCustom/Image";
import {assets} from "../../utils/fantacalcio/assets/assets";
import shadows from "@mui/material/styles/shadows";
import boxShadows from "../../theme/styles/boxShadows";


const phoneImageWidth = 433
const phoneImageHeight = 882
const phoneImageProportion = phoneImageWidth / phoneImageHeight
const MobileMockup = ({src, height = 400}) => {
   return (
      <View position={'relative'} sx={{zIndex:4}}>
         <Image
            src={assets.universal.iphone}
            height={height}
            width={height * phoneImageProportion}
         />
         {src &&
            <View borderRadius={10} overflow={'hidden'} flex centerCenter position={'absolute'} sx={{zIndex:2, top:0,bottom:0,left:0,right:0}}>
               <Image
                  src={src}
                  width={(height * phoneImageProportion) - (height*0.05)}
                  height={height - (height*0.05)}
                  objectPosition={'top'}
                  sx={{borderRadius:25}}
               />
            </View>
         }
      </View>
   )
}

export default MobileMockup
