import View from "../MD/View";
import {useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from "../MD/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {mixpanel_events} from "../../config/eventsTracking/mixpanel_events";
import config from "../../config/config";
import Link from "../MD/Link";
import R from "../../theme/styles";
import Transition from "../MD/Transition/Transition";
import {trackEvent} from "../../config/eventsTracking/tracking";

const logoFileWidthNoText = 98;
const logoFileHeightWidthNoText = 98;
const logoFileHeight = 142;
const logoFileWidthWithText = 583;

const Logo = ({size = 70, linkDisabled = false, oppositeColor = false, direction = 'row', onlyImage, openBlankPage}) => {
    const logo = oppositeColor ? config.logo.dark.logo : config.logo.light.logo
    const logoLong = oppositeColor ? config.logo.dark.logoLong : config.logo.light.logoLong
    const logoFileWidth = logoLong && !onlyImage ? logoFileWidthWithText : logoFileWidthNoText

    const theme = useTheme();
    const classes = useStyles({size});
    const titleClassName = classNames({
        [classes.title]: size,
    });
    const handleClick = () => {
        trackEvent(mixpanel_events.logo_cliccato, {logo: true})
    }
    const [isImgLoad, setIsImgLoad] = React.useState(false);
    const onLoad = () => {
        setIsImgLoad(true);
    }

    const finalHeight = onlyImage ? logoFileHeightWidthNoText : logoFileHeight

    return (
        <View className={classes.root} flex alignItems={'flex-start'} justify={'flex-start'}>
            <Link to="/" disabled={linkDisabled} target={openBlankPage && "_blank"}>
                <View flex alignItems={'flex-start'} justify={'flex-start'} clickable={!linkDisabled}
                      onClick={handleClick} direction={direction}>
                    <View className={classes.imgWrapper} centerCenter flex borderRadius={onlyImage? 1000 : 0} overflow={'hidden'}>
                        <img
                            alt={config.siteName + ' logo'}
                            className={classes.img}
                            src={logoLong && !onlyImage ? logoLong : logo}
                            width={Math.round(size * (logoFileWidth / finalHeight))}
                            height={size}
                            style={{
                                //minWidth: Math.round(size * (logoFileWidth / logoFileHeight)),
                                transition: R.globals.transition
                            }}
                            //width={(2.124 * size / 35) + 'rem' || theme.typography.h4.fontSize}
                            //height={(2.124 * size / 35) + 'rem' || theme.typography.h4.fontSize}
                            //onLoad={onLoad}
                        />
                    </View>
                    <Transition in={!onlyImage}>
                        <>
                            {!onlyImage && !logoLong &&
                                <Typography fontSize={direction === 'row' ? size / 1.5 : size / 2.5}
                                            weight={900} color={'#333'} sx={{fontFamily: 'Helvetica', marginLeft: 1}}
                                            oppositeColor={oppositeColor} variant={'h5'}>FantaLab</Typography>
                            }
                        </>
                    </Transition>
                </View>
            </Link>
        </View>
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap'
    },
    imgWrapper: {
        borderRadius: 0,
        //height: (2.124 * props.size / 35) + 'rem' || theme.typography.h4.fontSize,
        //width: (2.124 * props.size / 35) + 'rem' || theme.typography.h4.fontSize,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    img: {
        borderRadius: 0,
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
        position: 'relative',
    },
    title: props => ({
        fontSize: (2.124 * props.size / 35) + 'rem' || theme.typography.h4.fontSize,
        lineHeight: (2.124 * props.size / 35) + 'rem' || theme.typography.h4.lineHeight
    })
}));

Logo.propTypes = {
    size: PropTypes.number
}

export default Logo;
