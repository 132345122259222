import typography from "../../typography";
import palette from "../../palette";
import R from "../../index";

const {text, info} = palette;
const {size} = typography;

const inputLabel = {
    styleOverrides: {
        root: {
            fontSize: size.sm,
            color: R.palette.colors.grey[500],
            //lineHeight: 0.9,

            '&.MuiInputLabel-outlined':{
                //top:3
            },

            "&.MuiInputLabel-shrink.Mui-focused": {
                color: info.main,
            },

            "&.MuiInputLabel-shrink": {
                lineHeight: 1.5,
                fontSize: size.md,
                color: R.palette.colors.grey[600],

                "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
                    fontSize: "0.85em",
                },
            },
        },

        sizeSmall: {
            fontSize: size.xs,
            lineHeight: 1.625,

            "&.MuiInputLabel-shrink": {
                lineHeight: 1.6,
                fontSize: size.sm,

                "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
                    fontSize: "0.72em",
                },
            },
        },
    },
};

export default inputLabel;
