export const mixpanel_events = {
   //GENERAL
   app_aperta: 'app_aperta',
   button_clicked_menu: 'bottone_menu_cliccato',
   button_social_media_clicked: 'bottone_social_media_cliccato',
   logo_cliccato: 'logo_cliccato',
   app_aperta_tramite_referral: 'app_aperta_tramite_referral',
   inizia_processo_pagamento: 'inizia_processo_pagamento',
   acquisto_completato: 'acquisto_completato',
   acquisto_completato_page: 'acquisto_completato_page',
   paypal_pagamento_completato: 'paypal_pagamento_completato',
   set_offset: 'set_offset',

   //LOGIN
   login_google_cliccato: 'login_google_cliccato',
   login_email_pass_cliccato: 'login_email_pass_cliccato',
   login_email_pass_errore: 'login_email_pass_errore',
   signup_email_pass_cliccato: 'signup_email_pass_cliccato',
   signup_email_pass_errore: 'signup_email_pass_errore',
   signin_error_get_user: 'signin_error_get_user',
   signin_error: 'signin_error',
   signin_error_creating_user: 'signin_error_creating_user',
   signin_complete: 'signin_complete',

   //RESET PASSWORD
   reset_pass_send_email_errore: 'reset_password_errore_nel_mandare_codice',
   reset_pass_set_new_password_errore: 'reset_pass_errore_settando_nuova_password',
   reset_pass_new_password_set: 'reset_pass_nuova_password_settata',

   //PAGE CONTROL
   entrato_nella_pagina: 'entrato_nella_pagina',
   uscito_dalla_pagina: 'uscito_dalla_pagina',

   //CREA ASTA
   nuova_asta_creata: 'nuova_asta_creata',

   //CREA STRATEGIA
   nuova_strategia_creata: 'nuova_strategia_creata',

   //STRATEGIA
   modifica_riga_giocatore: 'modifica_riga_giocatore',

   //ASTA
   asta_giocatore_chiamato: 'asta_giocatore_chiamato',
   asta_giocatore_raise: 'asta_giocatore_raise',
   asta_giocatore_venduto_confermato: 'asta_giocatore_venduto_confermato',
   asta_giocatore_assegnato: 'asta_giocatore_assegnato',
   asta_giocatore_passato: 'asta_giocatore_passato',
   asta_giocatore_passato_dall_admin: 'asta_giocatore_passato_dall_admin',
   asta_giocatore_venduto_saltato: 'asta_giocatore_venduto_saltato',
   asta_connessione_saltata: 'asta_connessione_saltata',
   asta_connessione_stabilita: 'asta_connessione_stabilita',

   //PREMIUM
   premium_modal_aperto: 'premium_modal_aperto',
   premium_visualizzato: 'premium_visualizzato',
   disattivato_rinnovo_subsciption: 'disattivato_rinnovo_subsciption',
   attivato_rinnovo_subsciption: 'attivato_rinnovo_subsciption',
   asta_tentativo_di_scrittura_mentre_ofline: 'asta_tentativo_di_scrittura_mentre_ofline',


   //SEASON
   nuova_lega_creata: 'nuova_lega_creata',
   stagione_modifica_giocatore: 'stagione_modifica_giocatore',
   stagione_riga_cliccata: 'stagione_riga_cliccata',
   stagione_tab_cliccata: 'stagione_tab_cliccata',
   probabili_squadra_selezionata: 'probabili_squadra_selezionata',
   stagione_comparazione_giocatore_selezionato: 'stagione_comparazione_giocatore_selezionato',
   stagione_statistica_cliccata: 'stagione_statistica_cliccata',

   //ADVICE
   richiesta_consiglio_creata: 'richiesta_consiglio_creata',
   risposta_consiglio_creata: 'risposta_consiglio_creata',

   //SITO WEB LANDING PAGE
   sito_web_aperto: 'sito_web_aperto',
   button_call_to_action_landing_page: 'button_call_to_action_landing_page'
}
