import R from './styles'
import {createTheme} from "@mui/material";
import boxShadow from "./functions/boxShadow";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba, {hexToRgb} from "./functions/rgba";
import breakpoints from "./styles/breakpoints";
import boxShadows from "./styles/boxShadows";
import borders from "./styles/borders";
import typography from "./styles/typography";


// Material Dashboard 2 React MD base styles for @mui material MD
import sidenav from "./styles/components/sidenav";
import list from "./styles/components/list";
import listItem from "./styles/components/list/listItem";
import listItemText from "./styles/components/list/listItemText";
import listItemIcon from "./styles/components/list/listItemIcon";
import card from "./styles/components/card";
import cardMedia from "./styles/components/card/cardMedia";
import cardContent from "./styles/components/card/cardContent";
import button from "./styles/components/button";
import iconButton from "./styles/components/iconButton";
import input from "./styles/components/form/input";
import inputLabel from "./styles/components/form/inputLabel";
import inputOutlined from "./styles/components/form/inputOutlined";
import textField from "./styles/components/form/textField";
import menu from "./styles/components/menu";
import menuItem from "./styles/components/menu/menuItem";
import switchButton from "./styles/components/form/switchButton";
import divider from "./styles/components/divider";
import tableContainer from "./styles/components/table/tableContainer";
import tableHead from "./styles/components/table/tableHead";
import tableCell from "./styles/components/table/tableCell";
import linearProgress from "./styles/components/linearProgress";
import breadcrumbs from "./styles/components/breadcrumbs";
import slider from "./styles/components/slider";
import avatar from "./styles/components/avatar";
import tooltip from "./styles/components/tooltip";
import appBar from "./styles/components/appBar";
import tabs from "./styles/components/tabs";
import tab from "./styles/components/tabs/tab";
import stepper from "./styles/components/stepper";
import step from "./styles/components/stepper/step";
import stepConnector from "./styles/components/stepper/stepConnector";
import stepLabel from "./styles/components/stepper/stepLabel";
import stepIcon from "./styles/components/stepper/stepIcon";
import select from "./styles/components/form/select";
import formControlLabel from "./styles/components/form/formControlLabel";
import formLabel from "./styles/components/form/formLabel";
import checkbox from "./styles/components/form/checkbox";
import radio from "./styles/components/form/radio";
import autocomplete from "./styles/components/form/autocomplete";
import container from "./styles/components/container";
import popover from "./styles/components/popover";
import buttonBase from "./styles/components/buttonBase";
import icon from "./styles/components/icon";
import svgIcon from "./styles/components/svgIcon";
import link from "./styles/components/link";
import dialog from "./styles/components/dialog";
import dialogTitle from "./styles/components/dialog/dialogTitle";
import dialogContent from "./styles/components/dialog/dialogContent";
import dialogContentText from "./styles/components/dialog/dialogContentText";
import dialogActions from "./styles/components/dialog/dialogActions";
import globals from "./styles/globals";
import grid from "./styles/components/grid";
import {MUIPalette} from "./MUIPalette";


const theme = createTheme({
    palette: {
        ...MUIPalette
    },
    typography: { ...typography },
    breakpoints: { ...breakpoints },
    boxShadows: { ...boxShadows },
    borders: { ...borders },
    functions: {
        boxShadow,
        hexToRgb,
        linearGradient,
        pxToRem,
        rgba,
    },
    props: {
        MuiButtonBase: {
            disableRipple: false // No more ripple, on the whole application!
        }

    },
    /*mixins:{
        toolbar:{
            minHeight: 46
        }
    }*/
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                ...globals,
                ...container,
            },
        },
        MuiDrawer: { ...sidenav },
        MuiList: { ...list },
        MuiListItem: { ...listItem },
        MuiListItemText: { ...listItemText },
        MuiListItemIcon: { ...listItemIcon },
        MuiCard: { ...card },
        MuiCardMedia: { ...cardMedia },
        MuiCardContent: { ...cardContent },
        MuiButton: { ...button },
        MuiIconButton: { ...iconButton },
        MuiInput: { ...input },
        MuiInputLabel: { ...inputLabel },
        MuiOutlinedInput: { ...inputOutlined },
        MuiTextField: { ...textField },
        MuiMenu: { ...menu },
        MuiMenuItem: { ...menuItem },
        MuiSwitch: { ...switchButton },
        MuiDivider: { ...divider },
        MuiTableContainer: { ...tableContainer },
        MuiTableHead: { ...tableHead },
        MuiTableCell: { ...tableCell },
        MuiLinearProgress: { ...linearProgress },
        MuiBreadcrumbs: { ...breadcrumbs },
        MuiSlider: { ...slider },
        MuiAvatar: { ...avatar },
        MuiTooltip: { ...tooltip },
        MuiAppBar: { ...appBar },
        MuiTabs: { ...tabs },
        MuiTab: { ...tab },
        MuiStepper: { ...stepper },
        MuiStep: { ...step },
        MuiStepConnector: { ...stepConnector },
        MuiStepLabel: { ...stepLabel },
        MuiStepIcon: { ...stepIcon },
        MuiSelect: { ...select },
        MuiFormControlLabel: { ...formControlLabel },
        MuiFormLabel: { ...formLabel },
        MuiCheckbox: { ...checkbox },
        MuiRadio: { ...radio },
        MuiAutocomplete: { ...autocomplete },
        MuiPopover: { ...popover },
        MuiButtonBase: { ...buttonBase },
        MuiIcon: { ...icon },
        MuiSvgIcon: { ...svgIcon },
        MuiLink: { ...link },
        MuiDialog: { ...dialog },
        MuiDialogTitle: { ...dialogTitle },
        MuiDialogContent: { ...dialogContent },
        MuiDialogContentText: { ...dialogContentText },
        MuiDialogActions: { ...dialogActions },
        MuiGrid: { ...grid },
    },
});

export default theme;
