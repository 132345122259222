import React from 'react';
import {Popper as MuiPopper} from "@mui/material";
import R from "../../theme/styles";

const Popper = ({sx, ...rest}) => {

   return (
      <MuiPopper
         sx={{
            "& li":{
               color: R.palette.text.dark.primary
            },
            ...sx
         }}
         {...rest}
      />
   );
};

Popper.propTypes = {};

export default Popper;
