import chroma from "chroma-js";

export function hexToRgb(color) {
  return chroma(color).rgb().join(", ");
}

function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
