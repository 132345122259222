import React, {useEffect, useState} from 'react'
import {assets} from "../../utils/fantacalcio/assets/assets";
import View from "../../components/MD/View";
import Logo from "../../components/Logo/Logo";
import IpadAndMobile from "../../components/Mockups/IpadAndMobile";
import TypingText from "../../components/Animations/AnimatedTypography/TypingText";
import SignunPanel from "../../containers/SignunPanel";
import useViewport from "../../hooks/useViewport";
import R from "../../theme/styles";
import linearGradient from "../../theme/functions/linearGradient";
import Typography from "../../components/MD/Typography";
import rgba from "../../theme/functions/rgba";
import {isMobile} from "react-device-detect";

const defaultString = "L'app di supporto al Fantacalcio che non ti meriti ma di cui hai disperato bisogno!"
const defaultStrings2 = "Trasformiamo gli appassionati di Fantacalcio in Football Managers."
const defaultStrings3 = "Durante l'asta hai un pannello per tenere tutto sotto controllo."
const defaultStrings4 = "FantaLab ti accompagna anche durante la stagione con dati e analisi approfondite. Accedi a statistiche, probabili, indici di schierabilità e chiedi consigli ai tuoi canali di fantacalcio preferiti."
const defaultStrings5 = "FantaLab ti accompagna durante tutta la stagione, fornendoti statistiche dettagliate, probabili formazioni, indici di schierabilità e la possibilità di chiedere consigli ai tuoi canali di fantacalcio preferiti."
const defaultStrings6 = "Con FantaLab, hai sempre il controllo durante l'asta. Non perdere mai di vista la tua strategia di Fantacalcio.  Il tuo punto di riferimento durante l'asta. "

const main_color= '#C1FF72'

const featuresList = [
   {
      text2: "Trasformiamo gli appassionati di Fantacalcio in Football Managers",
      text: "Preparati all'asta con il tool di fantacalcio più usato in Italia.",
      delayBetweenStrings: 5000,
      highlights: [
         {
            highlightedText: "asta",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "più usato in Italia.",
            color: linearGradient(main_color, main_color),
         },
      ],
   },
   {
      text: "Dí addio a carta e penna o files excel. Prepara l'asta perfetta sfruttando tutti gli strumenti pre-asta di FantaLab.",
      highlights: [
         {
            highlightedText: "l'asta perfetta",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "pre-asta",
            color: linearGradient(main_color, main_color),
         },

      ],
   },
   {
      text: "Oppure risparmia ore di lavoro e scarica fasce, prezzi e tutti i consigli dei migliori creators di fantacalcio.",
      highlights: [
         {
            highlightedText: "risparmia ore di lavoro",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "migliori creators",
            color: linearGradient(main_color, main_color),
         },

      ],
   },

   {
      text: "Durante l'asta tieni sotto controllo obiettivi, strategia, budget, crediti, avversari e domina l'asta.",
      highlights: [
         {
            highlightedText: "Durante l'asta",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "domina l'asta.",
            color: linearGradient(main_color, main_color),
         },
      ],
   },
   {
      text: "Per ogni giocatore hai accesso a tutti i dati OPTA con statistiche avanzate e analisi approfondite come un vero match analyst.",
      highlights: [
         {
            highlightedText: "tutti i dati OPTA",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "match analyst.",
            color: linearGradient(main_color, main_color),
         },
      ],
   },
   {
      text: "Durante la stagione ottimizza la tua formazione con strumenti avanzati e interagisci coi tuoi creators preferiti.",
      highlights: [
         {
            highlightedText: "Durante la stagione",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "strumenti avanzati",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "interagisci",
            color: linearGradient(main_color, main_color),
         },
         {
            highlightedText: "creators preferiti.",
            color: linearGradient(main_color, main_color),
         },
      ],
   },


]
const mockupsList = [
   {
      mobileImage: assets.mockups.mobile.home,
      ipadImage: assets.mockups.ipad.home,
   },
   {
      mobileImage: assets.mockups.mobile.strategia,
      ipadImage: assets.mockups.ipad.strategia,
   },
   {
      mobileImage: assets.mockups.mobile.guida,
      ipadImage: assets.mockups.ipad.guida,
   },
   {
      mobileImage: assets.mockups.mobile.asta,
      ipadImage: assets.mockups.ipad.asta,
   },
   {
      mobileImage: assets.mockups.mobile.giocatore,
      ipadImage: assets.mockups.ipad.giocatore,
   },
   {
      mobileImage: assets.mockups.mobile.stagione,
      ipadImage: assets.mockups.ipad.stagione,
   },
   {
      mobileImage: assets.mockups.mobile.confronto,
      ipadImage: assets.mockups.ipad.confronto,
   },

]


const AnimatedFeatures = ({defaultSpeed = 10000}) => {
   const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
   const [firstTime, setFirstTime] = useState(true);

   /*useEffect(() => {
      const timer = setInterval(() => {
         if (firstTime) {
            setFirstTime(false)
         }
         setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % featuresList.length);
      }, featuresList[currentFeatureIndex]?.duration || defaultSpeed); // Change index every 3 seconds

      // Cleanup function
      return () => clearInterval(timer);
   }, []);*/

   useEffect(() => {
      if (firstTime) {
         setFirstTime(false)
      }
   }, []);

   const currentFeature = featuresList[currentFeatureIndex];
   const viewport = useViewport()

   return (
      <View direction={'row'} fullWidth flex1>
         <View flex={1} maxWidth={750}>
            <View flex1 alignItems={'flex-end'} justify={'center'}>
               <View
                  pt={viewport.isSM? 3 : 0}
                  px={2} fullWidth fullHeight spacing={2}
                  flex1
                  maxWidth={720}
                  maxHeight={!viewport.isSM ? 475 : undefined}
                  //alignItems={'center'}
               >
                  <Logo size={viewport.isXS ? 50 : 40}/>
                  {/*<Typography align={'left'} variant={'h5'} weight={600} color={'textSecondary'}>Trasformiamo i FANTALLENATORI in FOOTBALL MANAGERS</Typography>
                  */}
                  <View
                     fullHeight
                     fullWidth
                     flex1
                     flexShrink
                     //justify={'center'}

                  >
                     <TypingText
                        //fixedText={"FantaLab è l'app del Fantacalcio che non ti meriti ma di cui hai disperato bisogno! Ti aiuta"}
                        //skipAnimation={firstTime}
                        featuresList={featuresList}
                        typingSpeed={50}
                        deletionSpeed={10}
                        delayBetweenStrings={4000}
                        fontSize={viewport.isXS && viewport.height < 750 ? 32 : viewport.isXS ? 38 : viewport.width < 1300 ? 34 : viewport.isMD ? 38 : 48}
                        weight={900} lineHeight={1.3}
                        color={'rgba(255,255,255,1)'}
                     />
                  </View>
                  <SignunPanel/>
               </View>
            </View>

            {
               !isMobile ?
                  <Typography color={rgba('white', 0.7)} variant={"body1"}>Fantalab LTD | VAT 446483177 | info@fantalab.it | <a style={{color: rgba('white', 0.7), textDecoration: 'underlined'}} href={'https://fantalab.it/privacy-policy'}>Privacy Policy</a></Typography>
               :
               <View style={{position: viewport.isXS ? 'absolute' : 'relative', bottom: viewport.isXS ? 0: undefined}} centerCenter={viewport.isXS} fullWidth={viewport.isXS}>
                     <Typography fontSize={12} color={rgba('white', 0.7)} variant={"body1"}>Fantalab LTD | VAT 446483177 | info@fantalab.it | <a style={{color: rgba('white', 0.7), textDecoration: 'underlined'}} href={'https://fantalab.it/privacy-policy'}>Privacy Policy</a></Typography>

                  </View>

            }


         </View>

         {!viewport.isSM &&
            <View flex={1}>
               <View flex1 justify={'center'} px={5}>
                  <IpadAndMobile defaultSpeed={10000} list={mockupsList} position={'left'} scale={1.1}/>
               </View>
            </View>
         }

      </View>
   )
}

export default AnimatedFeatures
