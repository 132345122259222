import {createSlice} from '@reduxjs/toolkit'
const init = {user: null, token: null, checking: true, premium: false, openPremiumModal: false, unlockedPlayers: []}
const slice = createSlice({
   name: 'auth',
   initialState: init,
   reducers: {
      setCredentials: (
         state,
         action
      ) => {
         state.token = action.payload.token
      },
      setUser: (
         state,
         action
      ) => {
         state.user = action.payload.user
         state.checking = false;
         if (action.payload.premium) {
            state.premium = action.payload.premium
         }
         if(action.payload.credits){
            state.credits = action.payload.credits
         }
         if(action.payload.usedCredits != null){
            state.usedCredits = action.payload.usedCredits || 0
         }
         if(action.payload.unlockedPlayers != null){
            state.unlockedPlayers = action.payload.unlockedPlayers
         }
      }, logout: (
         state,
      ) => {
         state.user = null
         state.token = null
         state.premium = null
         state.unlockedPlayers = []
      },
      setPremium: (
         state,
         action
      ) => {
         state.premium = action.payload.premium
      },
      setPremiumModal: (
         state,
         action
      ) => {
         state.openPremiumModal = action.payload.open
      },
      setIsConnected: (
         state,
         action
      ) => {
         state.connected = action.payload.connected
      },
      resetAuthData: (state, action) => {

      },
   },
})

export const {setCredentials, setUser, logout, setPremium, setPremiumModal,setIsConnected, resetAuthData} = slice.actions
export const selectCurrentUser = (state) => state.auth.user


export default slice.reducer

