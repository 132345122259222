/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import typography from "../../typography";
import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";

// Material Dashboard 2 React Base Styles

// Material Dashboard 2 React Helper Functions

const { transparent, light, info, secondary } = palette;
const { size } = typography;

const outlined = {
  base: {
    color: light.main,
    borderColor: light.main,

    "&:hover": {
      opacity: 0.75,
      backgroundColor: transparent.main,
    },

  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },
};

export default outlined;
