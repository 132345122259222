import {configTracking} from "../configTracking";
import {
   browserName, deviceDetect,
   deviceType,
   isAndroid,
   isDesktop,
   isIOS,
   isMobile, mobileModel,
   mobileVendor,
   osName
} from "react-device-detect";
import mixpanel from "mixpanel-browser";
import _ from "lodash";

mixpanel.init(configTracking.mixpanelTrakingId, {debug: configTracking.isDebug, ignore_dnt: true});
//window.gtag('config', configTracking.gaTrakingId);


export const trackEvent = (action, props) => {
   if(configTracking.gaEnabled){
      if (!configTracking.isDebug && window && typeof window !== 'undefined') {
         window.gtag('event', action, {
            ...props,
            mobile: isMobile,
            android: isAndroid,
            ios: isIOS,
            osName: osName,
            desktop: isDesktop,
            deviceType: deviceType,
            mobileVendor: mobileVendor,
            browserName: browserName,
            mobileModel: mobileModel,
            deviceData: deviceDetect,
         });
      }
   }
   if(configTracking.mixpanelEnabled){
      mixpanel.track(action, {
         ...props,
         mobile: isMobile,
         android: isAndroid,
         ios: isIOS,
         osName: osName,
         desktop: isDesktop,
         deviceType: deviceType,
         mobileVendor: mobileVendor,
         browserName: browserName,
         mobileModel: mobileModel,
         deviceData: deviceDetect,
      })
   }
}


/*export const pageview = (url) => {
   window.gtag('configTracking', configTracking.gaTrakingId, {
      page_path: url,
   })
}*/

export const setUserProperties = (props) => {
   if(configTracking.gaEnabled){
      window.gtag('set', 'user_properties', {
         ...props
      });
   }
   if(configTracking.mixpanelEnabled){
      for(let prop of _.keys(props)){
         if(prop === 'email'){
            mixpanel.people.set("$email", props[prop])
         } else if(prop === 'referral') {
            mixpanel.people.set_once('referral', props[prop])
         } else {
            mixpanel.people.set(prop, props[prop])
         }
      }
   }
}

export const initUser = async (userId) => {
   if(configTracking.gaEnabled){
   }
   if(configTracking.mixpanelEnabled){
      let id = await mixpanel.get_distinct_id()
      if(id === userId){
         await mixpanel.identify(userId)
      } else {
         await mixpanel.alias(userId, id)
      }
   }
}
