import typography from "../typography";
import palette from "../palette";
import pxToRem from "../../functions/pxToRem";
import borders from "../borders";
import {Fade} from "@mui/material";

const {black, light} = palette;
const {size, fontWeightRegular} = typography;
const {borderRadius} = borders;

const tooltip = {
    defaultProps: {
        arrow: false,
        placement: 'top',
        enterDelay:400,
        TransitionComponent: Fade,
    },

    styleOverrides: {
        tooltip: {
            maxWidth: pxToRem(200),
            backgroundColor: black.main,
            color: light.main,
            fontSize: size.sm,
            fontWeight: fontWeightRegular,
            textAlign: "center",
            borderRadius: borderRadius.md,
            opacity: 0.7,
            padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
            //cursor:'pointer !important',
        },

        arrow: {
            color: black.main,
        },
    },
};

export default tooltip;
