import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'auction',
   initialState: {
      auction: null,
      onlineUsers: []
   },
   reducers: {
      setAuction: (
         state,
         action
      ) => {
         state.auction = action.payload
      },
      saveAuctionBlocked: (
         state
      ) => {
         state.auctionBlocked = state.auction
      },
      cleanAuctionBlocked: (
         state,
      ) => {
         state.auctionBlocked = {}
      },
      setOnlineUsers : (
         state,
         action
      ) => {
         state.onlineUsers = action.payload
      },
   },
})

export const {setAuction, saveAuctionBlocked, cleanAuctionBlocked, setOnlineUsers} = slice.actions

export default slice.reducer

