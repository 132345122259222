import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import View from "./View";
import CircularProgress from "./CircularProgress";
import {Button as MuiButton, useTheme} from "@mui/material";
import {buildStyle} from "../../utils/style/buildStyle";
import linearGradient from "../../theme/functions/linearGradient";
import boxShadow from "../../theme/functions/boxShadow";
import {alpha, darken, getContrastText} from "../../theme/functions/colorManipulation";
import R from "../../theme/styles";
import rgba from "../../theme/functions/rgba";
import Icon from "./Icon";
import pxToRem from "../../theme/functions/pxToRem";
import moment from "moment";
import Typography from "./Typography";


const Button = ({
                   props,
                   className,
                   children,
                   fullWidth,
                   color = 'primary',
                   variant = 'contained',
                   size = 'medium',
                   iconSize,
                   height,
                   noHover,
                   oppositeColor,
                   loading = false,
                   disabled,
                   startIcon,
                   iconType = 'icon',
                   gradient,
                   dark,
                   borders = false,
                   uppercase = true,
                   withTimer = false,
                   waitingMillis,
                   startingWaitingTime,
                   sx,
                   ...rest
                }) => {
      const [isWaiting, setIsWaiting] = useState(withTimer && moment().diff(moment(startingWaitingTime)) < waitingMillis);
      const [timeLeft, setTimeLeft] = useState(withTimer && Math.floor(moment(startingWaitingTime + waitingMillis).diff(moment()) / 1000));
      const theme = useTheme();
      const {coloredShadow} = R.boxShadows;
      const ref = useRef();
      ref.timeLeft = timeLeft

      useEffect(() => {
         if (withTimer && startingWaitingTime) {
            const timerId = setInterval(() => {
               if (moment().diff(moment(startingWaitingTime)) <= waitingMillis) {
                  let current = ref.timeLeft
                  if (!current) {
                     setTimeLeft(Math.floor(moment(startingWaitingTime + waitingMillis).diff(moment()) / 1000))
                  } else {
                     setTimeLeft(current - 1)
                  }
               } else {
                  setIsWaiting(false)
                  clearInterval(timerId)
               }
            }, 1000);
            return () => clearInterval(timerId);
         }
      }, [withTimer, startingWaitingTime]);

      const classes = {
         root: {
            borderRadius: R.borders.borderRadius.button,
            lineHeight: 1.2,
            fontWeight: 900,
            "& .MuiIcon-root": {
               color: `${getContrastText(R.palette[color] ? R.palette[color]['main'] : color, 'strong', 7)} !important`,
            },
         },
         color: {
            backgroundColor: R.palette?.[color]?.['main'] ? R.palette[color]['main'] : color,
            '&.MuiButton-root': {
               color: `${getContrastText(R.palette[color] ? R.palette[color]['main'] : color, 'strong', 7)} !important`,
            },
            '&.MuiButton-root:hover': {
               backgroundColor: R.palette[color] ? R.palette[color].focus : darken(color, 0.1),
            },
            /*"&.MuiButton-root:focus:not(:hover)": {
               background: R.palette[color] ? R.palette[color].focus : darken(color, 0.1),
            }*/
         },
         colorText: {
            color: R.palette?.[color]?.['main'] || color || 'light',
            '&.MuiButton-root:hover': {
               color: R.palette?.[color]?.focus || darken(color || R.palette.light.main, 0.1),
            },
         },
         gradient: {
            background: R.palette[color]?.gradient ?
               linearGradient(R.palette[color]?.gradient, R.palette[color]?.main)
               : linearGradient(color, darken(color, 0.2)),
            '&.MuiButton-root:hover': {
               background: R.palette[color]?.gradient ?
                  linearGradient(darken(R.palette[color]?.gradient, 0.1), darken(R.palette[color]?.main, 0.1))
                  : linearGradient(darken(color, 0.1), darken(color, 0.3)),
            },
         },
         noHover: {
            '&:hover': {
               background: 'transparent'
            },
         },
         noUppercase: {
            textTransform: "capitalize",
         },
         disabledDark: {
            color: '#fff !important',
            opacity: '0.5 !important',
            background: '#aaa !important'
         },
         fullWidth: {
            flex: 1,
            width: '100%'
         },
         oppositeColor: {
            color: `${getContrastText(R.palette[color] ? getContrastText(R.palette[color]['main']) : getContrastText(color))}`,
         },
         buttonProgress: {
            position: 'absolute',
            //top: '50%',
            left: 8,
            //marginTop: -6,
            //marginLeft:
         },
         loadingWrapper: {
            position: 'relative',
         },
         onlyIcon: {
            paddingLeft: 2,
            paddingRight: 2,
            minWidth: 'auto',
            '& .MuiButton-startIcon': {
               marginLeft: 0,
               marginRight: 0,
            }
         },
         coloredShadow: {
            boxShadow: coloredShadow?.[color] ? coloredShadow?.[color] : "none",
            /*`${boxShadow([0, 3], [3, 0], R.palette[color].main, 0.15)},
                ${boxShadow([0, 3], [1, -2], R.palette[color].main, 0.2)},
                ${boxShadow([0, 1], [5, 0], R.palette[color].main, 0.15)}`*/
            "&:hover": {
               boxShadow: coloredShadow?.[color] ? coloredShadow?.[color] : "none"
               /*`${boxShadow([0, 14], [26, -12], R.palette[color]?.main, 0.4)},
                    ${boxShadow([0, 4], [23, 0], R.palette[color]?.main, 0.15)},
                    ${boxShadow([0, 8], [10, -5], R.palette[color]?.main, 0.2)}`*/

            },
            "&:focus:not(:hover)": {
               boxShadow: R.palette[color]
                  ? boxShadow([0, 0], [0, 3.2], R.palette[color]?.main, 0.5)
                  : boxShadow([0, 0], [0, 3.2], R.palette.white.main, 0.5),
            },
         },
         outlined: {
            backgroundColor: color === "white" ? rgba(R.palette.white.main, 0.1) : R.palette.transparent.main,
            color: R.palette[color] ? R.palette[color]?.main : R.palette.white.main,
            borderColor: R.palette[color] ? R.palette[color]?.main : rgba(R.palette.white.main, 0.75),
            "&:focus:not(:hover)": {
               boxShadow: R.palette[color] ?
                  boxShadow([0, 0], [0, 3.2], R.palette[color]?.main, 0.5)
                  : boxShadow([0, 0], [0, 3.2], R.palette.white.main, 0.5)
            },
         },
         border: {
            border: `solid 2px ${alpha(R.palette?.['white']?.['main'] || color, 0.3)} !important`
         },

         height: {
            minHeight: pxToRem(height),
         },
         fontSize: {
            fontSize: `${size}px !important`,
         },
         sizeDefault: {
            minHeight: pxToRem(40),
            padding: `${pxToRem(10)} ${pxToRem(24)}`,
            fontSize: R.typography.size.xs,
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(16)} !important`,
            },
         },
         sizeSM: {
            minHeight: pxToRem(32),
            padding: `${pxToRem(6)} ${pxToRem(16)}`,
            fontSize: R.typography.size.xxs,
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(12)} !important`,
            },
         },
         sizeMD: {
            minHeight: pxToRem(40),
            padding: `${pxToRem(10)} ${pxToRem(24)}`,
            fontSize: R.typography.size.xs,
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(16)} !important`,
            },
         },
         sizeLG: {
            minHeight: pxToRem(47),
            padding: `${pxToRem(12)} ${pxToRem(28)}`,
            fontSize: R.typography.size.xs,
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(20)} !important`,
            },
         },
         sizeXL: {
            minHeight: pxToRem(47),
            padding: `${pxToRem(16)} ${pxToRem(36)}`,
            fontSize: R.typography.size.sm,
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(20)} !important`,
            },
         },

         iconSize: {
            "&.MuiButtonBase-root .MuiIcon-root": {
               fontSize: `${pxToRem(iconSize)} !important`,
            },
         },
      };

      const isStringSize = ['small', 'medium', 'large', 'xLarge', 'sm', 'md', 'lg', 'xl'].includes(size)

      const style = buildStyle(classes, {
         root: true,
         color: !!color && variant === 'contained',
         colorText: !!color && variant === 'text',
         sizeDefault: true,
         sizeSM: size === 'small' || size === 'sm',
         sizeMD: size === 'medium' || size === 'md',
         sizeLG: size === 'large' || size === 'lg',
         sizeXL: size === 'xLarge' || size === 'xl',
         fontSize: size && !isStringSize && Number.isInteger(size) ? size : false,
         noHover: noHover,
         oppositeColor: oppositeColor,
         gradient: gradient && !disabled,
         coloredShadow: gradient || variant === 'contained',
         outlined: variant === 'outlined',
         disabledDark: disabled && dark,
         onlyIcon: !children,
         border: borders,
         iconSize: iconSize,
         noUppercase: !uppercase,
         height: height && Number.isInteger(height),
      });

      let duration = moment.duration(timeLeft * 1000)
      return (
         <MuiButton
            //color={color}
            variant={variant}
            disabled={loading || disabled || isWaiting}
            //size={size}
            fullWidth={fullWidth}
            startIcon={startIcon ? <Icon src={iconType === 'image' ? startIcon : undefined}>{startIcon}</Icon> : undefined}
            sx={[style, sx]}
            {...rest}
         >
            {loading ?
               <View /*className={classes.loadingWrapper}*/ direction={'row'}>
                  {children}
                  {loading &&
                     <>
                        <CircularProgress
                           size={12}
                           className={classes.buttonProgress}
                           thickness={4}
                           color={'inherit'}
                        />
                     </>
                  }
               </View>
               :
               isWaiting ?
                  <View fullWidth>
                     <span>{
                        'attendi '
                     }</span>
                     <span>{
                        (duration.minutes() > 0 ? duration.minutes()  + ' min ' : '') +  duration.seconds() + ' sec'
                     }</span>
                  </View>

                  :
                  <>{children}</>
            }
         </MuiButton>
      );
   }
;

Button.propTypes = {
   variant: PropTypes.oneOf(["text", "contained", "outlined"]),
   children: PropTypes.node,
   className: PropTypes.string,
   color: PropTypes.string,
   fullWidth: PropTypes.bool,
   noHover: PropTypes.bool,
   oppositeColor: PropTypes.bool,
   loading: PropTypes.bool,
   size: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf(['small', 'medium', 'large', 'xLarge'])])
};

export default Button;
