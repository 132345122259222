import R from "../../../theme/styles";
import linearGradient from "../../../theme/functions/linearGradient";

function navbar(theme, ownerState) {
   const {palette, boxShadows, functions, transitions, breakpoints, borders} = theme;
   const {transparentNavbar, absolute, light, darkMode, fullWidth, isMini, viewport, gradient} = ownerState;

   const {dark, white, text, transparent, background} = palette;
   const {navbarBoxShadow} = boxShadows;
   const {rgba, pxToRem} = functions;
   const {borderRadius} = borders;

   return {
      width: '100%',
      maxWidth: viewport.totalWidth,
      [breakpoints.down("xl")]: {
         transition: transitions.create(["width"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
         }),
      },

      transition: R.globals.transition,
      boxShadow: transparentNavbar || absolute ? "none" : R.boxShadows.navbarBoxShadow,
      backdropFilter: transparentNavbar || absolute ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
      background:
         transparentNavbar || absolute
            ? `${transparent.main} !important`
                  : rgba(R.palette.elements.navbar.background, 1),

      top: 0,
      minHeight: R.dimensions.navBarHeight,
      display: "grid",
      alignItems: "center",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingTop: pxToRem(0),
      paddingBottom: pxToRem(0),
      paddingRight: absolute ? pxToRem(8) : 0,
      paddingLeft: absolute ? pxToRem(16) : 0,

      borderBottom: `0.8px solid ${R.palette.elements.divider.primary}`,

      "& > *": {
         transition: transitions.create("all", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
         }),
      },

      "& .MuiToolbar-root": {
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
         //borderTop:`solid 1px ${R.palette.elements.navbar.background}`,
         [breakpoints.up("sm")]: {
            minHeight: "auto",
            padding: `${pxToRem(0)} ${pxToRem(16)} ${pxToRem(0)}  ${pxToRem(16)} `,
         },
      },
   };
}

const navbarContainer = ({breakpoints}) => ({
   flexDirection: "column",
   alignItems: "center",
   justifyContent: "space-between",
   pt: 0,
   pb: 0,

   [breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "0",
      paddingBottom: "0",
   },
});

const navbarRow = ({breakpoints}, {isMini}) => ({
   /*display: "flex",
   alignItems: "center",
   justifyContent: "space-between",
   width: "100%",

   [breakpoints.up("md")]: {
     justifyContent: isMini ? "space-between" : "stretch",
     width: isMini ? "100%" : "max-content",
   },

   [breakpoints.up("xl")]: {
     justifyContent: "stretch !important",
     width: "max-content !important",
   },*/
});

const navbarIconButton = ({typography: {size}, breakpoints}) => ({
   px: 1,
   paddingTop: 0,

   "& .material-icons, .material-icons-round": {
      fontSize: `${size.xl} !important`,
   },

   "& .MuiTypography-root": {
      display: "none",

      [breakpoints.up("sm")]: {
         display: "inline-block",
         lineHeight: 1.2,
         ml: 0.5,
      },
   },
});

const navbarMobileMenu = ({breakpoints}) => ({
   display: "inline-block",
   lineHeight: 0,
   paddingTop: 0,

   /*[breakpoints.up("xl")]: {
     display: "none",
   },*/
});

export {navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu};
