import {useEffect, useState} from "react";
import MainLayout from "../layouts/MainLayout/MainLayout";
import Button from "../components/MD/Button";
import axios from "axios";
import * as rdd from 'react-device-detect';
import {useParams} from "react-router-dom";
import {v4 as uuidv4, validate} from "uuid";


const RedirectPage = () => {
   const params = useParams();
   const [openAppLink, setOpenAppLink] = useState(null)

   useEffect(() => {
      (async () => {
         if(rdd.isMobile){
            let prov_id = localStorage.getItem('prov_id_2024')
            if(!prov_id){
               prov_id = uuidv4()
               localStorage.setItem('prov_id_2024', prov_id)
            }
            let id = params?.id
            if(validate(id)){
               await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/analytics/link-click', {
                  data: {id},
                  manufacturer: rdd.mobileVendor,
                  model: rdd.mobileModel,
                  os_version: rdd.osVersion,
                  device_type: rdd.deviceType,
                  screen_width: window.innerWidth,
                  link_id: id,
                  id: prov_id,
                  platform: rdd.isIOS ? 'ios' : rdd.isAndroid ? 'android' : 'web',
                  browser: rdd.browserName,
               })
               window.location = 'fantalab://smart-link=' + prov_id
               setOpenAppLink('smart-link=' + prov_id)
            } else {
               let ref = localStorage.getItem('ref_2024') || params?.id
               await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/analytics/link-click', {
                  data: {ref},
                  manufacturer: rdd.mobileVendor,
                  model: rdd.mobileModel,
                  os_version: rdd.osVersion,
                  device_type: rdd.deviceType,
                  screen_width: window.innerWidth,
                  link_id: prov_id,
                  id: prov_id,
                  platform: rdd.isIOS ? 'ios' : rdd.isAndroid ? 'android' : 'web',
                  browser: rdd.browserName,
               })
               window.location = 'fantalab://ref=' + prov_id
               setOpenAppLink('ref=' + prov_id)
            }
         } else {
            let id = params?.id
            let res = await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/smart-link/get-data', {
               id: id
            })
            if(res.data?.data?.url){
               window.location.replace(res.data?.data?.url)
            } else {
               window.location.replace('https://fantalab.it')
            }
         }
      })()

   }, [])

   return (
      <MainLayout
         //bgColor={R.palette.white.main}
         //bgColor2={R.palette.dark.focus}
         horizontalPadding={0}
         emptyLayout
         verticalPadding={0}
         backgroundColorOverlay={0.75}
      >
         <Button onClick={() => {
            if(openAppLink){
               window.location = 'fantalab://' + openAppLink
               setTimeout(() => {
                  if(rdd.isAndroid){
                     window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app&&referrer=' + openAppLink
                  } else {
                     window.location = 'https://apps.apple.com/it/app/id6450352620'
                  }
               }, 2000)
            } else {
               if(rdd.isAndroid){
                  window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app'
               } else {
                  window.location = 'https://apps.apple.com/it/app/id6450352620'
               }
            }
         }}>Apri App</Button>
      </MainLayout>
   )
}
export default RedirectPage
