import {forwardRef} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material MD
import {MenuItem as MUIMenuItem} from "@mui/material";

import menuItem from "./styles";
import Typography from "../../../../../components/MD/Typography";
import View from "../../../../../components/MD/View";
import Link from "../../../../../components/MD/Link";

const MenuElement = forwardRef(({icon, title, ...rest}, ref) => (
    <MUIMenuItem ref={ref} sx={(theme) => menuItem(theme)} {...rest} >
        <View component={Link}>
            <View direction={'row'} py={0.5} sx={{display: 'flex'}} fullWidth alignItems="center" lineHeight={1}>
                {icon &&
                    <Typography clickable variant="body2" color="secondary">
                        {icon}
                    </Typography>
                }
                <Typography clickable variant="button" sx={{ml: 1}}>
                    {title}
                </Typography>
            </View>
        </View>
    </MUIMenuItem>
));

// Typechecking props for the NotificationItem
MenuElement.propTypes = {
    title: PropTypes.string.isRequired,
};

export default MenuElement;
