import typography from "../../typography";
import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";
import R from "../../index";
import globals from "../../globals";

const {size, fontWeightRegular} = typography;
const {borderRadius} = borders;
const {dark} = palette;
const {cardOpacity} = globals;

const tab = {
    styleOverrides: {
        root: {
            display: "flex",
            alignItems: "center",
            //flexDirection: "row",
            flex: "1 1 auto",
            textAlign: "center",
            maxWidth: "120px",
            //maxWidth: "unset !important",
            minHeight: 50,
            //minHeight: "unset !important",
            fontSize: size.sm,
            fontWeight: fontWeightRegular,
            textTransform: "none",
            lineHeight: "inherit",
            padding: pxToRem(4),
            //borderRadius: borderRadius.lg,
            //color: `${dark.main} !important`,
            opacity: `1 !important`,

            "& .material-icons, .material-icons-round": {
                marginBottom: "0 !important",
                marginRight: pxToRem(6),
            },

            "& svg": {
                marginBottom: "0 !important",
                marginRight: pxToRem(6),
            },
        },

        labelIcon: {
            paddingTop: pxToRem(4),
        },

        fullWidth:{
            maxWidth: "unset !important",
        }
    },
};

export default tab;
