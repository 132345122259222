import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'purchases',
   initialState: {
      fantaleagues: null,
   },
   reducers: {
      setFantaleagues: (
         state,
         action
      ) => {
         state.fantaleagues = action.payload
      }
   },
})

export const {setFantaleagues} = slice.actions

export default slice.reducer

