import palette from "./palette";
import dimensions from "./dimensions";
import {darken, lighten} from "../functions/colorManipulation";

const elements = {
    scrollbar:{
        //overflowY: 'scroll',
        height: "100%",
        '&::-webkit-scrollbar': {
            width: dimensions.sidebarDimension,
            height: dimensions.sidebarDimension,
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.themeDark ? lighten(palette.background.secondary, 0.4) : darken(palette.background.secondary, 0.4),
            outline: '0px solid slategrey',
            borderRadius: 200
        }
    },
    categoriesListItem:{

    },
    backgroundGradient:{
        background: "linear-gradient(to bottom right, " + palette.primary.gradient + ", " + palette.primary.main + ")",
    },
    backgroundFlat:{
        backgroundColor: palette.primary.gradient
    }

}

export default elements
