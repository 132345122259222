import palette from "./palette";

// Material Dashboard 2 React Base Styles

const {info, dark} = palette;

const globals = {
    html: {
        scrollBehavior: "smooth",
    },
    "*, *::before, *::after": {
        margin: 0,
        padding: 0,
    },
    "a, a:link, a:visited": {
        textDecoration: "none !important",
    },
    "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
        color: `${dark.main} !important`,
        transition: "color 150ms ease-in !important",
    },
    "a.link:hover, .link:hover, a.link:focus, .link:focus": {
        color: `${info.main} !important`,
    },

    /*//disable arrows in textfield if number
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },*/

    cardColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: {
        default: 20,
        round:1000,
        xl:40,
        lg:30,
        md:20,
        sm:10,
        xs:5,
    },

    transition: '300ms ease-in-out',
};

export default globals;
