import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'live',
   initialState: {},
   reducers: {
      setLivePurchases: (
         state,
         action
      ) => {
         state.purchases = action.payload
      },
      setLiveAuction: (
         state,
         action
      ) => {
         state.auction = action.payload
      },
      setLiveAsta: (
         state,
         action
      ) => {
         state.asta = action.payload
      },
      setLiveFantateams: (
         state,
         action
      ) => {
         state.fantateams = action.payload
      },
      setLiveOverrides: (
         state,
         action
      ) => {
         state.overrides = action.payload
      },
      setFavTeam : (
         state,
         action
      ) => {
         state.favTeam = action.payload
      },
   },
})

export const {setLivePurchases, setLiveAuction, setLiveAsta, setLiveFantateams, setLiveOverrides, setFavTeam} = slice.actions

export default slice.reducer

