import React from 'react';
import PropTypes from 'prop-types';
import {TextField as MuiTextField, useTheme} from "@mui/material";
import {buildStyle} from "../../utils/style/buildStyle";
import R from "../../theme/styles";
import rgba from "../../theme/functions/rgba";

const TextField = ({
                      value,
                      className,
                      color = false,
                      alphaColor = 0.05,
                      fullWidth = false,
                      minWidth,
                      width,
                      margin = 'dense',
                      paddingVertical = 0,
                      variant = 'outlined',
                      fontSize,
                      shadow,
                      hideNumberArrows,
                      hiddenLabel,
                      icon,
                      borderColor,
                      sx,
                      ...rest
                   }) => {
   const theme = useTheme();

   const classes = {
      root: {
         "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
         "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
         "& .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: `${R.palette.info.main} !important`,
         },
      },
      standard:{
         "& .MuiInputBase-root:before": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
         "&:hover .MuiInputBase-root:before": {
            borderColor: borderColor || R.palette.inputBorderColor,
         },
      },
      paddingVertical: {
         '& input': {
            paddingTop: theme.spacing(paddingVertical),
            paddingBottom: theme.spacing(paddingVertical),
         },
      },
      fontSize: {
         '& input': {
            fontSize: fontSize
         },
      },
      color: {
         backgroundColor: color && theme.palette[color].main && `${rgba(theme.palette[color].main, alphaColor)} !important`,
         input: {
            backgroundColor: color && theme.palette[color].main && `${rgba(theme.palette[color].main, alphaColor)} !important`,
         },
      },
      colorWhite: {
         backgroundColor: `white !important`,
         input: {
            backgroundColor: `white !important`,
         },
      },
      shadow: {
         boxShadow: R.boxShadows[shadow] || R.boxShadows.xs
      },
      hideNumberArrows: {
         '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
         },
         '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
         },
      },
      minWidth: {
         minWidth: minWidth
      },
      width: {
         width: width
      },
      hiddenLabel: {
         "& legend": {
            visibility: "hidden"
         },
      },
      icon: {
         "& .MuiInputBase-inputAdornedStart": {
            marginLeft: 1,
         },
      },
   };
   /*const buildStyle = () => {
       let rootClassName = {...classes.root}
       rootClassName = paddingVertical > 0 ? {...rootClassName, ...classes.paddingVertical} : rootClassName
       rootClassName = fontSize ? {...rootClassName, ...classes.fontSize} : rootClassName
       rootClassName = sx ? {...rootClassName, sx} : rootClassName
       return rootClassName
   }*/
   const style = buildStyle(classes, {
      root: true,
      paddingVertical: paddingVertical > 0,
      fontSize: fontSize,
      color: color && color !== 'white',
      colorWhite: color === 'white',
      shadow: shadow,
      minWidth: minWidth,
      width: width,
      icon: icon,
      hideNumberArrows: hideNumberArrows,
      hiddenLabel: hiddenLabel,
      standard: variant === 'standard',
   }, sx)

   return (
      <MuiTextField
         value={value}
         color={color || null}
         fullWidth={fullWidth}
         variant={variant}
         margin={margin}
         className={className}
         sx={style}
         InputProps={{
            startAdornment: icon,
         }}
         {...rest}
      />
   );
};

TextField.propTypes = {
   autoComplete: PropTypes.string,
   autoFocus: PropTypes.bool,
   classes: PropTypes.object,
   color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool,]),
   defaultValue: PropTypes.any,
   disabled: PropTypes.bool,
   error: PropTypes.bool,
   FormHelperTextProps: PropTypes.object,
   fullWidth: PropTypes.bool,
   helperText: PropTypes.node,
   id: PropTypes.string,
   InputLabelProps: PropTypes.object,
   inputProps: PropTypes.object,
   InputProps: PropTypes.object,
   label: PropTypes.node,
   margin: PropTypes.oneOf(['dense', 'none', 'normal']),
   multiline: PropTypes.bool,
   name: PropTypes.string,
   onChange: PropTypes.func,
   placeholder: PropTypes.string,
   required: PropTypes.bool,
   rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
   rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
   select: PropTypes.bool,
   SelectProps: PropTypes.object,
   size: PropTypes.oneOf(['medium', 'small', 'large']),
   type: PropTypes.string,
   value: PropTypes.any,
   variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default TextField;
