import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";

import {navbar, navbarContainer,} from "./styles";
import MenuElement from "../Menu/old/MenuElement";
import R from "../../../theme/styles";

import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import View from "../../../components/MD/View";

import {Auth} from 'aws-amplify';
import Menu from "../../../components/MD/Menu";
import AppBar from "../../../components/MD/AppBar";
import Toolbar from "../../../components/MD/Toolbar";
import Icon from "../../../components/MD/Icon";
import {setMiniSidenav} from "../../../config/slices/styleSlice";
import useViewport from "../../../hooks/useViewport";
import {useTheme} from "@mui/styles";
import MenuList from "../Menu/MenuList";
import {compare} from "../../../utils/component/componentUtils";
import Logo from "../../../components/Logo/Logo";
import {resetPersistedData} from "../../../config/slices/persistedSlice";
import {resetAuthData} from "../../../config/slices/authSlice";

function Navbar({
                   routes,
                   absolute,
                   light,
                   isMini,
                   fixedNavbar,
                   fullWidth,
                   transparentNavbar,
                   gradient,
                   title = 'asta',
                   justLogo
                }) {
   const [navbarType, setNavbarType] = useState('static');
   const [openMenu, setOpenMenu] = useState(false);
   const [showProfile, setShowProfile] = useState(false);
   const route = useLocation().pathname.split("/").slice(1);
   const darkMode = R.palette.themeDark;
   const dispatch = useDispatch();
   const viewport = useViewport()
   const theme = useTheme()
   const navigate = useNavigate()
   const miniSidenav = useSelector((state) => state.style.miniSidenav)
   const user = useSelector((state) => state.auth.user);

   /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
   /*let prevScrollpos = window.pageYOffset;
   window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos >= currentScrollPos) {
         if (document.getElementById("navbar")) document.getElementById("navbar").style.opacity = "1";
      } else {
         if (document.getElementById("navbar")) document.getElementById("navbar").style.opacity = "0";
      }
      prevScrollpos = currentScrollPos;
   }*/

   useEffect(() => {
      // Setting the navbar type
      if (fixedNavbar) {
         setNavbarType("fixed");
      } else {
         setNavbarType("static");
      }
   })

   const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
   const handleCloseMenu = () => setOpenMenu(false);
   const handleMiniSidenav = () => dispatch(setMiniSidenav({miniSidenav: !miniSidenav}));
   const handleOpenProfile = () => setShowProfile(true)
   const handleCloseProfile = () => setShowProfile(false)

   const handleOnClickLogout = () => {
      handleCloseMenu();
      dispatch(resetAuthData())
      dispatch(resetPersistedData())
      console.log('sign out fatto')
      Auth.signOut();
   };
   const handleOnClickProfile = () => {
      handleCloseMenu();
      handleOpenProfile();
   };

   // Render the notifications menu
   const renderMenu = () => (
      <Menu
         anchorEl={openMenu}
         anchorReference={null}
         anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
         }}
         open={Boolean(openMenu)}
         onClose={handleCloseMenu}
         sx={{mt: 2}}
      >
         <MenuElement icon={<Icon fontSize={'small'}>account_circle</Icon>} title="Profilo"
                      onClick={handleOnClickProfile}/>
         <MenuElement icon={<Icon fontSize={'small'}>logout</Icon>} title="Logout" onClick={handleOnClickLogout}/>
      </Menu>
   );

   // Styles for the navbar icons
   const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
      color: () => {
         let colorValue = light || R.palette.themeDark ? white.main : dark.main;

         if (transparentNavbar && !light) {
            colorValue = R.palette.themeDark ? rgba(text.main, 0.6) : text.main;
         }

         return colorValue;
      },
   });

   return (
      <View
         id={'navbar'}
         sx={{
            transition: 'opacity 0.3s',
            minHeight: R.dimensions.navBarHeight,
            position: 'relative',
            width: '100%',
            maxWidth: viewport.width,
            flexShrink: 0,
         }}
      >
         <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {
               transparentNavbar,
               absolute,
               light,
               darkMode,
               fullWidth,
               isMini,
               viewport,
               gradient
            })}
         >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
               <View flex1 fullWidth>
                  <View flex1 fullWidth direction={'row'} alignItems={'center'} spacing={3}>
                     <Logo size={80}/>
                     {!viewport.isXS &&
                        <MenuList routes={routes} darkMode={darkMode} miniSidenav={miniSidenav} horizontal/>}
                     <View fullWidth alignItems={'flex-end'}>
                        <View color={light ? "white" : "inherit"} direction={'row'} centerCenter>
                           {/*{viewport.isXS &&
                              <IconButton
                                 size="small"
                                 disableRipple
                                 color="inherit"
                                 sx={navbarMobileMenu}
                                 onClick={handleMiniSidenav}
                              >
                                 <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                 </Icon>
                              </IconButton>
                           }*/}

                           {/*<IconButton
                              size="large"
                              disableRipple
                              sx={navbarIconButton}
                              aria-controls="notification-menu"
                              aria-haspopup="true"
                              variant="contained"
                              onClick={handleOpenMenu}
                           >
                              <Icon sx={iconsStyle}>account_circle</Icon>
                           </IconButton>*/}
                           {renderMenu()}
                        </View>
                     </View>
                  </View>
                  {viewport.isXS &&
                     <MenuList routes={routes} darkMode={darkMode} miniSidenav={miniSidenav} horizontal/>}
                  {/*{viewport.isXS && !justLogo &&
                     <MenuList routes={routes} darkMode={darkMode} miniSidenav={miniSidenav} horizontal/>
                  }*/}


               </View>
            </Toolbar>
         </AppBar>
      </View>
   );
}

// Setting default values for the props of DashboardNavbar
Navbar.defaultProps = {
   absolute: false,
   light: false,
   isMini: false,
};

// Typechecking props for the DashboardNavbar
Navbar.propTypes = {
   absolute: PropTypes.bool,
   light: PropTypes.bool,
   isMini: PropTypes.bool,
};

export default memo(Navbar, compare);
