

const grid = {
    styleOverrides: {
        root:{
            overflow: "unset",
        }
    },
};

export default grid;
