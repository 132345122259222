import typography from "../../typography";
import palette from "../../palette";
import borders from "../../borders";

const {inputBorderColor, info, colors, transparent, white} = palette;
const {borderRadius} = borders;
const {size} = typography;

const inputOutlined = {
   styleOverrides: {
      root: {
         //backgroundColor: transparent.main,
         //fontSize: size.sm,
      },

      input: {
         color: colors.grey[700],
      },

      multiline: {
         color: colors.grey[700],
         //padding: 0,
      },
   },
};

export default inputOutlined;
