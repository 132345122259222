import React from 'react';
import View from "../../components/MD/View";
import {CssBaseline} from "@mui/material";
import PropTypes from "prop-types";
import Navbar from "../components/Navbar/Navbar";
import Sidenav from "../components/Sidenav/Sidenav";
import routes from "../../routes";
import {useDispatch, useSelector} from "react-redux";
import useViewport from "../../hooks/useViewport";
import {setOnMouseEnter} from "../../config/slices/styleSlice";
import Snackbar from "../../components/MD/Snackbar";
import {setShowSnackbar} from "../../config/slices/appGeneralSlice";
import R from "../../theme/styles";
import {alpha, darken} from "../../theme/functions/colorManipulation";
import linearGradient from "../../theme/functions/linearGradient";
import {assets} from "../../utils/fantacalcio/assets/assets";


const MainLayout = ({
                       children,
                       oppositeColor,
                       emptyLayout,
                       justLogo = false,
                       onlyNavbar = false,
                       noNavbar = false,
                       bgColor = R.palette.background.primary,
                       bgColor2,
                       backgroundImage,
                       defaultBackground,
                       defaultBackgroundImage = true,
                       horizontalPadding = 3,
                       verticalPadding = 2,
                       sideBar,
                       freeWidth = false,
                       backgroundColorOverlay = 0.7
                    }) => {
   const miniSidenav = useSelector((state) => state.style.miniSidenav)
   const onMouseEnter = useSelector((state) => state.style.onMouseEnter)
   const showSnackbar = useSelector((state) => state.appGeneral.showSnackbar)
   const viewport = useViewport()
   const dispatch = useDispatch()

   // Open sidenav when mouse enter on mini sidenav
   const handleOnMouseEnter = () => {
      if (miniSidenav && !onMouseEnter) {
         dispatch(setOnMouseEnter({onMouseEnter: true}));
      }
   };

   // Close sidenav when mouse leave mini sidenav
   const handleOnMouseLeave = () => {
      if (onMouseEnter) {
         dispatch(setOnMouseEnter({onMouseEnter: false}));
      }
   };

   return (
      <View
      >
         <View
            backgroundImage={backgroundImage || (defaultBackgroundImage || (emptyLayout && !bgColor) ? assets.general.football_pitch : false)}
            backgroundColor={bgColor}
            backgroundColor2={bgColor2}
            gradient={(bgColor && bgColor2) || defaultBackground}
            flex1
            sx={{
               backgroundSize: 'cover',
               backgroundAttachment: 'fixed',
               backgroundPosition: 'center, center',
               //height: '100vh',
               height: viewport.height,
               minWidth: viewport.totalWidth,
               //width: freeWidth && !emptyLayout ? 'fit-content' : viewport.totalWidth,
               overflowX: 'auto',
               overflowY: 'auto',
            }}
         >
            <View
               sx={{
                  background: !backgroundColorOverlay ? undefined : linearGradient(alpha(darken(R.palette.wisdom.main, 1), 0.9), alpha(darken(R.palette.wisdom.main, 1), backgroundColorOverlay), 180),
                  //minHeight: '100vh',
                  minHeight: viewport.height,
                  backgroundSize: 'cover',
                  backgroundAttachment: 'fixed',
               }}
            >
               <CssBaseline/>
               {(!emptyLayout && !onlyNavbar) &&
                  <Sidenav
                     routes={routes}
                     onMouseEnter={handleOnMouseEnter}
                     onMouseLeave={handleOnMouseLeave}
                     miniSidenav={miniSidenav && !onMouseEnter}
                  />
               }
               <View
                  flex={emptyLayout || onlyNavbar}
                  justifyContent={'center'}
                  sx={({breakpoints, transitions, functions: {pxToRem}}) => ({
                     flex: 1,
                     position: "relative",
                     //minHeight: '100vh',
                     minHeight: viewport.height,
                     minWidth: '100%',
                     transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                     }),

                     [breakpoints.up("sm")]: {
                        marginLeft: emptyLayout || onlyNavbar ? 0 : `${viewport.sidenav}px`,
                     },
                     [breakpoints.down("sm")]: {
                        width: viewport.width
                     },
                  })}
               >
                  <View
                     flex1
                     fullWidth
                     sx={{
                        pt: 0,
                        maxWidth: viewport.isXS ? '100vw' : '100vw',
                        overflowY: viewport.isXS ? 'overlay' : 'overlay',
                        overflowX: viewport.isXS ? 'hidden' : 'auto',
                        //minHeight: '100vh',
                        minHeight: viewport.height,
                     }}
                  >
                     <View
                        flex1
                        minWidth={'100vw'}
                        sx={{
                           width: freeWidth ? 'fit-content' : `calc(${viewport.totalWidth}px - ${!sideBar ? 0 : viewport.sidenav}px)`,
                           maxWidth: freeWidth ? undefined : '100%'
                        }}
                     >
                        {!emptyLayout && !noNavbar &&
                           <Navbar
                              routes={routes} fullWidth={true}
                              transparentNavbar fixedNavbar={false}
                              isMini={miniSidenav} justLogo={justLogo}
                           />
                        }
                        <View
                           flex
                           flex1
                           py={verticalPadding}
                           sx={{
                              pl: viewport.isXS ? 0 : horizontalPadding,
                              pr: viewport.isXS || freeWidth ? 0 : horizontalPadding,
                           }}
                           centerCenter={emptyLayout}
                        >
                           {children}
                        </View>
                     </View>
                  </View>
               </View>
               <Snackbar
                  open={showSnackbar}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                  severity="success"
                  onClose={() => {
                     dispatch(setShowSnackbar(false))
                  }}
                  autoHideDuration={3000}
                  message={showSnackbar.message}
               />
            </View>
         </View>
      </View>
   );
};

MainLayout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default MainLayout;
