import React, {forwardRef} from 'react';
import {Alert as MuiAlert}  from '@mui/material';

const Alert = forwardRef(({children, severity='success', onClose, ...rest }, ref) => {
    return (
        <MuiAlert
            onClose={onClose}
            ref={ref}
            severity={severity}
            sx={{alignItems:'center'}}
            {...rest}
        >
            {children}
        </MuiAlert>
    );
});

Alert.propTypes = {};

export default Alert;
