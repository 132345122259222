
const specific = {
   gradientRoles:{
      P: "warning",
      D: "success",
      C: "info",
      T: "wisdom",
      A: "error",

      Por: "warning",
      Dc: "success",
      Ds: "success",
      Dd: "success",
      E: "info",
      M: "info",
      W: "wisdom",
      Pc: "error",
   },
   roles: {
      P: "#ff9900",
      D: "#4caf4f",
      C: "#49a3f1",
      T: "#7F07C4",
      A: "#f44336",

      Por: "#ff9900",
      Dc: "#4caf4f",
      Ds: "#4caf4f",
      Dd: "#4caf4f",
      E: "#49a3f1",
      M: "#49a3f1",
      W: "#7F07C4",
      Pc: "#f44336",
   },

   teams: {
      atalanta:{
         mainColor: '#1A73E8',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      bologna:{
         mainColor: '#0c2884',
         secondaryColor: '#790404',
         textColor: '#FFFFFF',
      },
      cremonese:{
         mainColor: '#c05d5d',
         secondaryColor: '#e33939',
         textColor: '#FFFFFF',
      },
      empoli:{
         mainColor: '#1A73E8',
         secondaryColor: '#1A73E8',
         textColor: '#FFFFFF',
      },
      fiorentina:{
         mainColor: '#5227d0',
         secondaryColor: '#5227d0',
         textColor: '#FFFFFF',
      },
      inter:{
         mainColor: '#0f42ab',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      juventus:{
         mainColor: '#555',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      lazio:{
         mainColor: '#7caff1',
         secondaryColor: '#7caff1',
         textColor: '#FFFFFF',
      },
      lecce:{
         mainColor: '#d1a000',
         secondaryColor: '#a00000',
         textColor: '#FFFFFF',
      },
      milan:{
         mainColor: '#761212',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      monza:{
         mainColor: '#b32b2b',
         secondaryColor: '#b02e2e',
         textColor: '#FFFFFF',
      },
      napoli:{
         mainColor: '#58a0ff',
         secondaryColor: '#58a0ff',
         textColor: '#FFFFFF',
      },
      roma:{
         mainColor: '#be5e23',
         secondaryColor: '#be3b23',
         textColor: '#FFFFFF',
      },
      salernitana:{
         mainColor: '#824133',
         secondaryColor: '#824133',
         textColor: '#FFFFFF',
      },
      sampdoria:{
         mainColor: '#10296f',
         secondaryColor: '#10296f',
         textColor: '#FFFFFF',
      },
      sassuolo:{
         mainColor: '#1f641f',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      spezia:{
         mainColor: '#555',
         secondaryColor: '#000',
         textColor: '#FFFFFF',
      },
      torino:{
         mainColor: '#824133',
         secondaryColor: '#824133',
         textColor: '#FFFFFF',
      },
      udinese:{
         mainColor: '#000',
         secondaryColor: '#333',
         textColor: '#FFFFFF',
      },
      verona:{
         mainColor: '#09286b',
         secondaryColor: '#ae963d',
         textColor: '#FFFFFF',
      },
   },
}

export default specific
