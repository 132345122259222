import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
   apiKey: "AIzaSyAUil-4mmWcJc-eaTBhZdxd43EHLmVtcds",
   authDomain: "fantalab-79eaa.firebaseapp.com",
   databaseURL: "https://fantalab-79eaa-default-rtdb.europe-west1.firebasedatabase.app",
   projectId: "fantalab-79eaa",
   storageBucket: "fantalab-79eaa.appspot.com",
   messagingSenderId: "374161263115",
   appId: "1:374161263115:web:0a1617aab4da83531ed50e",
   measurementId: "G-LENKS2C4QG"
};
const firebaseConfigLive = {
   apiKey: "AIzaSyAUil-4mmWcJc-eaTBhZdxd43EHLmVtcds",
   authDomain: "fantalab-79eaa.firebaseapp.com",
   databaseURL: "https://fantalab-live.europe-west1.firebasedatabase.app/",
   projectId: "fantalab-79eaa",
   storageBucket: "fantalab-79eaa.appspot.com",
   messagingSenderId: "374161263115",
   appId: "1:374161263115:web:0a1617aab4da83531ed50e",
   measurementId: "G-LENKS2C4QG"
};


// Initialize Realtime Database and get a reference to the service

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const appLive = initializeApp(firebaseConfigLive,"secondary");
const analytics = getAnalytics(app);
const perf = getPerformance(app);
export const databaseFB = getDatabase(app);
//export const databaseFBLive = getDatabase(appLive);

