import palette from "../palette";
import pxToRem from "../../functions/pxToRem";
import borders from "../borders";

const {borderRadius} = borders;
const {light} = palette;

const linearProgress = {
    styleOverrides: {
        root: {
            borderRadius: borderRadius.md,
            overflow: "hidden",
            position: "relative",
        },

        colorPrimary: {
            backgroundColor: light.main,
        },

        colorSecondary: {
            backgroundColor: light.main,
        },

        bar: {
            borderRadius: borderRadius.sm,
            transition: "width 0.6s ease !important",
        },
    },
};

export default linearProgress;
