import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'strategy',
   initialState: {
      strategyType: 'classic'
   },
   reducers: {
      setStrategy: (
         state,
         action
      ) => {
         state['strategy'] = action.payload
      },
      setStrategyType: (
         state,
         action
      ) => {
         state['strategyType'] = action.payload
      },
   },
})

export const {setStrategy, setStrategyType} = slice.actions

export default slice.reducer

