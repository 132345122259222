import React from "react";
import {useSelector} from "react-redux";
import R from "../theme/styles";
import {isMobileOnly} from "react-device-detect";

const viewportContext = React.createContext({});
const breakpointsMap = ['xs', 'sm', 'md', 'lg', 'xl']

export const ViewportProvider = ({children}) => {

   const isDimension = (breakpoint) => {
      if (breakpoint === 0) {
         return isMobileOnly || window.innerWidth <= R.breakpoints.values[breakpointsMap[breakpoint + 1]]
      } else if (breakpoint === 4) {
         return window.innerWidth > R.breakpoints.values[breakpointsMap[breakpoint]]
      } else {
         return window.innerWidth <= R.breakpoints.values[breakpointsMap[breakpoint + 1]]
      }
   }

   const miniSidenav = useSelector((state) => state.style.miniSidenav)
   const openSidenav = useSelector((state) => state.style.openSidenav)
   const scrollbar = isDimension(0) ? 0 : 15

   const [width, setWidth] = React.useState(window.innerWidth - scrollbar);
   const [height, setHeight] = React.useState(window.innerHeight);
   const [isXS, setIsXS] = React.useState(isDimension(0));
   const [isSM, setIsSM] = React.useState(isDimension(1));
   const [isMD, setIsMD] = React.useState(isDimension(2));
   const [isLG, setIsLG] = React.useState(isDimension(3));
   const [isXL, setIsXL] = React.useState(isDimension(4));

   const bodyEmptyWidth = window.innerWidth - scrollbar
   const bodyMiniSidenavWidth = window.innerWidth - R.dimensions.miniDrawerWidth - scrollbar
   const bodySidenavWidth = window.innerWidth - R.dimensions.drawerWidth - scrollbar
   const bodyWidth = isDimension(0) ? bodyEmptyWidth : miniSidenav ? bodyMiniSidenavWidth : bodySidenavWidth

   const mainLayout = bodyEmptyWidth - (R.dimensions.paddingBody * 2)
   const totalWidth = window.innerWidth
   const sidenav = miniSidenav ? R.dimensions.miniDrawerWidth : R.dimensions.drawerWidth

   const handleWindowResize = () => {
      setWidth(window.innerWidth - scrollbar);
      setHeight(window.innerHeight);
      setIsXS(isDimension(0));
      setIsSM(isDimension(1));
      setIsMD(isDimension(2));
      setIsLG(isDimension(3));
      setIsXL(isDimension(4));
   };

   const mainLayoutFreeWidth = bodyEmptyWidth - (R.dimensions.paddingBody * 2) -1

   React.useEffect(() => {
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
   }, []);

   return (
      <viewportContext.Provider
         value={{width, height, isXS, isSM, isMD, isLG, isXL, mainLayout, mainLayoutFreeWidth, totalWidth, sidenav, scrollbar}}>
         {children}
      </viewportContext.Provider>
   );
};

const useViewport = () => {
   const {
      width,
      height,
      isXS,
      isSM,
      isMD,
      isLG,
      isXL,
      mainLayout,
      mainLayoutFreeWidth,
      totalWidth,
      sidenav,
      scrollbar
   } = React.useContext(viewportContext);
   return {width, height, isXS, isSM, isMD, isLG, isXL, mainLayout, mainLayoutFreeWidth, totalWidth, sidenav, scrollbar};
};
export default useViewport
