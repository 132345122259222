import React from 'react';
import {Link as MuiLink} from "@mui/material";
import {Link as RouterLink, NavLink} from "react-router-dom";
import {buildStyle} from "../../utils/style/buildStyle";

const Link = ({
                  className,
                  children,
                  disabled,
                  inline,
                  color = 'inherit',
                  underline = 'none',
                  to,
                  href,
                  ...rest
              }) => {

    const classes = {
        root: {
            cursor: 'pointer',
            display: 'block'
        },
        disabled: {
            cursor: 'default',
        },
        inline: {
            display: 'contents'
        },
        flex: {}
    };
    const style = buildStyle(classes, {
        root: true,
        disabled: disabled,
        inline: inline,
    });

    return (
        disabled ?
            <div style={style}>{children}</div>
            : to ?
                <NavLink to={to} {...rest} style={style}>
                    {children}
                </NavLink>
                :
                <MuiLink {...rest}
                         underline={underline}
                         className={`${classes.root} ${className}`}
                         color={color}
                         style={style}
                >
                    {children}
                </MuiLink>
    )
}

export default Link

Link.propTypes = {}
