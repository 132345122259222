import {assets} from "../utils/fantacalcio/assets/assets";

const config = {
    siteUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.fantalab.it',
    isDebug: 0,

    siteName: "FantaLab",
    appSiteUrl: "https://app.fantalab.it/",

    logo:{
        dark:{
            logo: assets.logo.dark.fantalab_logo,
            logoLong: assets.logo.dark.fantalab_logo_with_text
        },
        light:{
            logo: assets.logo.light.fantalab_logo,
            logoLong: assets.logo.light.fantalab_logo_with_text
        }
    }
}

export default config
