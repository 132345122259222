import SidenavCollapse from "../Sidenav/SidenavCollapse";
import Typography from "../../../components/MD/Typography";
import Divider from "../../../components/MD/Divider";
import React from "react";
import List from "../../../components/MD/List/List";
import {useLocation} from "react-router-dom";
import {Stack} from "@mui/material";
import useViewport from "../../../hooks/useViewport";
import View from "../../../components/MD/View";


const MenuList = ({routes, darkMode, miniSidenav, horizontal}) => {
   const location = useLocation();
   const collapseName = location.pathname.replace("/", "");

   let textColor = "white";

   if (!darkMode) {
      textColor = "dark";
   } else if (darkMode) {
      textColor = "inherit";
   }

   const renderRoutes = routes.map(({
                                       type,
                                       name,
                                       icon,
                                       title,
                                       key,
                                       href,
                                       route,
                                       subList,
                                       async_children
                                    }) => {
      let returnValue;

      if (type === "collapse") {
         returnValue = (
            <View key={key} flex1 centerCenter>
               <SidenavCollapse
                  to={route}
                  href={href}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  subList={subList || false}
                  mini={miniSidenav}
                  horizontal={horizontal}
               />
            </View>
         )
      } else if (type === "title") {
         returnValue = (
            <Typography
               key={key}
               color={textColor}
               display="block"
               variant="caption"
               fontWeight="bold"
               textTransform="uppercase"
               pl={3}
               mt={2}
               mb={1}
               ml={1}
            >
               {name}
            </Typography>
         );
      } else if (type === "divider") {
         returnValue = (
            <Divider
               key={key}
               light={
                  (!darkMode) ||
                  (darkMode)
               }
            />
         );
      }

      return returnValue;
   });

   return(
      horizontal ?
            <List component={View} direction={"row"}>{renderRoutes}</List>
            :
            <List>{renderRoutes}</List>
   )
}

export default MenuList
