import {Menu as MuiMenu} from "@mui/material";

const Menu = ({children, ...rest}) => {
    return(
        <MuiMenu {...rest}>
            {children}
        </MuiMenu>
    )
}

export default Menu
