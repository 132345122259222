/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React base styles
import typography from "../../typography";
import palette from "../../palette";

// Material Dashboard 2 React helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const {size} = typography;
const {text} = palette;

const dialogContentText = {
    styleOverrides: {
        root: {
            fontSize: size.md,
            color: text.main,
        },
    },
};

export default dialogContentText;
