import {IconButton as MuiIconButton} from "@mui/material";
import React from 'react'
import {darken, emphasize, getContrastText, getLuminance, lighten} from "../../theme/functions/colorManipulation";
import R from "../../theme/styles";

const IconButton = React.forwardRef(({
                                        children,
                                        backgroundColor,
                                        noBackground,
                                        sx,
                                        color,
                                        ...rest
                                     }, ref) => {

   const colorIcon = (color === 'primary' || !color) ? R.palette.elements.iconButton.primary : color === 'secondary' ? R.palette.elements.iconButton.secondary : color
   const darkColor = getLuminance(colorIcon) < 0.2
   const hoverColor = darkColor ? darken(colorIcon, 1) : lighten(colorIcon, 1)
   return (
      <MuiIconButton
         ref={ref}
         sx={{
            backgroundColor: backgroundColor === true ? R.palette.elements.iconButton.background : backgroundColor ? backgroundColor : undefined,
            '&.MuiButtonBase-root:hover': {
               backgroundColor: backgroundColor === true ? emphasize(R.palette.elements.iconButton.background, 0.1) : backgroundColor ? emphasize(backgroundColor, 0.1) : undefined,
            },
            '&.MuiButtonBase-root .MuiIcon-root': {
               //color: `${colorIcon}`,
               color: color ? colorIcon : backgroundColor === true ? '#fff' : backgroundColor ? getContrastText(backgroundColor, 'secondary') : colorIcon,
            },
            '&.MuiButtonBase-root:hover .MuiIcon-root': {
               //color: hoverColor
               color: noBackground ? hoverColor : undefined
            },
            ...sx,
         }}
         {...rest}
      >
         {children}
      </MuiIconButton>
   )
})

export default IconButton
