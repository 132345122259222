import View from "../components/MD/View";
import Button from "../components/MD/Button";
import MainLayout from "../layouts/MainLayout/MainLayout";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useState} from "react";
import Typography from "../components/MD/Typography";
import rgba from "../theme/functions/rgba";
import R from "../theme/styles";
import Input from "../components/MD/Input/Input";


const SignOutMarketingPage = () => {
   const params = useParams();
   const [completeSignout, setCompleteSignout] = useState(false)
   const [email, setEmail] = useState('')
   return (
      <MainLayout
         horizontalPadding={0}
         emptyLayout
         verticalPadding={0}
         backgroundColorOverlay={0.75}
      >
      <View >
         {
            completeSignout ?
               <View spacing={2} card backgroundColor={rgba('white', 1)} p={6} borderRadius={5}>
                  <Typography variant={'h6'} color={R.palette.text.dark.secondary}>Disiscritto con successo dalla lista</Typography>
               </View>
               :
               <View spacing={2} card backgroundColor={rgba('white', 1)} p={6} borderRadius={5}>
                  <Typography variant={'h6'} color={R.palette.text.dark.secondary}>Disiscriviti dalla lista mail</Typography>
                  {
                     !params?.email &&
                     <Input placeholder={'La tua email'} value={email} onChange={(e) => setEmail(e.target.value)}/>
                  }
                  <Button color={'error'} onClick={async () => {
                     try {
                        await axios.post('https://api.fantalab.it/user/sign-out-marketing', {
                           email: params?.email || email
                        })
                        setCompleteSignout(true)
                     } catch (e) {
                        console.log(e)
                     }
                  }}>Disiscriviti Ora</Button>
               </View>

         }

      </View>
      </MainLayout>
   );
}

export default SignOutMarketingPage;
