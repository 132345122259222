import {AppBar as MuiAppBar} from "@mui/material";

const AppBar = ({children, ...rest}) => {
    return(
        <MuiAppBar {...rest}>
            {children}
        </MuiAppBar>
    )
}

export default AppBar
