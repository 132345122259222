import typography from "../../typography";
import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";
import boxShadows from "../../boxShadows";

const {lg} = boxShadows;
const {size} = typography;
const {text, white} = palette;
const {borderRadius} = borders;

const menu = {
    defaultProps: {
        disableAutoFocusItem: true,
    },

    styleOverrides: {
        paper: {
            //minWidth: pxToRem(160),
            boxShadow: lg,
            padding: `${pxToRem(16)} ${pxToRem(8)}`,
            fontSize: size.sm,
            color: text.main,
            textAlign: "left",
            backgroundColor: `${white.main} !important`,
            borderRadius: borderRadius.md,
        },
    },
};

export default menu;
