import View from "../MD/View";
import MobileMockup from "./MobileMockup";
import IpadMockup from "./IpadMockup";
import {useEffect, useRef, useState} from 'react';


const proportion = 894 / 548
const IpadAndMobile = ({
                          defaultSpeed,
                          position = 'left',
                          list,
                          scale = 1,
                          mobile= true
}) => {
   const ref = useRef(null);
   const [width, setWidth] = useState(0);
   const [currentMockupIndex, setCurrentMockupIndex] = useState(0);

   useEffect(() => {
      const timer = setInterval(() => {
         setCurrentMockupIndex((prevIndex) => (prevIndex + 1) % list.length);
      }, defaultSpeed); // Change index every XXX seconds

      // Cleanup function
      return () => clearInterval(timer);
   }, []);

   const currentMockup = list[currentMockupIndex];

   useEffect(() => {
      setWidth(ref.current ? ref.current.offsetWidth : 0);
   }, [ref.current]);

   const totalHeight = width / proportion
   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
         }}
         ref={ref}
      >
         <View
            direction={'row'}
            sx={{
               width: width,
               //height: totalHeight,
               position: 'relative',
            }}
         >
            {mobile &&
               <View
                  minWidth={120}
               >
                  <View
                     flex1
                     justify={'flex-start'}
                     position={'absolute'}
                     sx={{
                        left: position === 'left' ? width / 75 : undefined,
                        right: position === 'right' ? width / 75 : undefined,
                        bottom: -40
                     }}
                  >
                     <MobileMockup height={totalHeight * 0.9 * scale} src={currentMockup?.mobileImage}/>
                  </View>
               </View>
            }

            <View
               flex1
               alignItems={position === 'left' ? 'flex-end' : 'flex-start'}
               sx={{display: scale > 1 ? 'block' : undefined}}
            >
               <IpadMockup height={totalHeight * scale} src={currentMockup?.ipadImage}/>
            </View>
         </View>
      </div>
   )
}

export default IpadAndMobile
