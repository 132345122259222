import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'simulazione',
   initialState: {},
   reducers: {
      setSimulazione: (
         state,
         action
      ) => {
         state[action.payload.strategyId] = action.payload.simulazione
      },
   },
})

export const {setSimulazione} = slice.actions

export default slice.reducer

