import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";

const {borderWidth} = borders;
const {light} = palette;

const tableCell = {
    styleOverrides: {
        root: {
            //padding: `${pxToRem(12)} ${pxToRem(16)}`,
            borderBottom: `${borderWidth[1]} solid ${light.main}`,
        },
    },
};

export default tableCell;
