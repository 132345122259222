import {combineReducers, configureStore, isRejectedWithValue} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import authReducer, {setCredentials} from "./slices/authSlice"
import styleReducer from "./slices/styleSlice"
import simulazioneReducer from "./slices/simulazioneSlice"
import astaReducer from "./slices/astaSlice"
import auctionReducer from "./slices/auctionSlice"
import purchasesReducer from "./slices/purchasesSlice"
import fantaleaguesReducer from "./slices/fantaleaguesSlice"
import persistedSlice from "./slices/persistedSlice"
import liveSlice from "./slices/liveSlice"
import appGeneral from "./slices/appGeneralSlice"
import seasonSlice from "./slices/seasonSlice"
import strategySlice from "./slices/strategySlice"
import AsyncStorage from '@react-native-async-storage/async-storage';

import { persistReducer } from 'redux-persist'
import {api} from "../services/api";

const persistConfig = {
   key: 'root',
   storage: AsyncStorage,
   whitelist: ['simulazione', 'persisted', 'strategy']
};



/*const rtkQueryErrorLogger = (api) => (next) => async (action) => {
   // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
   if (isRejectedWithValue(action)) {
      if(action?.payload?.status === 401){
         console.log('token expired')
         try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
               console.log('session', err, session);
               const { accessToken } = session;
               console.log('new token',accessToken)
               store.dispatch(setCredentials({token: accessToken.jwtToken}))
            });
         } catch (e) {
            console.log('Unable to refresh Token', e);
         }
      }
   }

   return next(action)
}*/

let reducer = combineReducers({
   [api.reducerPath]: api.reducer,
   auth: authReducer,
   style: styleReducer,
   simulazione: simulazioneReducer,
   asta: astaReducer,
   auction: auctionReducer,
   purchases: purchasesReducer,
   fantaleagues: fantaleaguesReducer,
   persisted: persistedSlice,
   live: liveSlice,
   appGeneral: appGeneral,
   season: seasonSlice,
   strategy: strategySlice
})
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
         immutableCheck: false,
      }).concat(api.middleware)/*.concat(rtkQueryErrorLogger)*/,
})

setupListeners(store.dispatch)
