import React, {useState} from 'react';
import config from "../../config/config";
import ComingSoonModal from "../../containers/ComingSoonModal";
import {useCookies} from "react-cookie";
import {trackEvent} from "../../config/eventsTracking/tracking";
import {mixpanel_events} from "../../config/eventsTracking/mixpanel_events";
import Button from "../MD/Button";


const SignupButton = ({position}) => {
   const BUTTON_TEXT = "ACCEDI GRATIS A FANTALAB"

   //const [cookies, setCookies, removeCookies] = useCookies(['ref']);

   const [comingSoonModal, setComingSoonModal] = useState(false);

   const handleOnClick = () => {
      trackEvent(mixpanel_events.button_call_to_action_landing_page, {
         button_text: BUTTON_TEXT,
         position: position
      })

      //setComingSoonModal(true);

      let url = config.appSiteUrl + '?id=' + localStorage.getItem('prov_id_2024')
      if(localStorage.getItem('ref_2024')){
         url = url + "&ref=" + localStorage.getItem('ref_2024')
      }

      window.open(url, '_blank');
   }

   return (
      <>
         <ComingSoonModal
            open={comingSoonModal}
            onClose={() => setComingSoonModal(false)}
         />
         <Button fullWidth height={70} sx={{borderRadius:1000}} color={'primary'} size={'xLarge'} onClick={handleOnClick}>{BUTTON_TEXT}</Button>
      </>
   )
}

export default SignupButton
