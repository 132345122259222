import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'appGeneral',
   initialState: {
      showSnackbar: false
   },
   reducers: {
      setPlayerModal: (
         state,
         action
      ) => {
         state.playerModal = action.payload
      },
      setShowSnackbar: (
         state,
         action
      ) => {
         state.showSnackbar = action.payload
      },
      setAllPlayers: (
         state,
         action
      ) => {
         state.allPlayers = action.payload
      },
      setOverrides: (
         state,
         action
      ) => {
         state.overrides = action.payload
      },
      setUsedPlayers: (
         state,
         action
      ) => {
         state.usedPlayers = action.payload
      },
      showCreateAdviceDialog: (
         state,
         action
      ) => {
         state.createAdviceDialog = action.payload
      },
   },
})

export const { setPlayerModal, setShowSnackbar, setAllPlayers, setOverrides, setUsedPlayers, showCreateAdviceDialog } = slice.actions

export default slice.reducer

