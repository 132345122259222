import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Typography from "../../MD/Typography";
import linearGradient from "../../../theme/functions/linearGradient";
import R from "../../../theme/styles";

const Cursor = styled.span`
  font-weight: 400;
  color: #fff;
  animation: blink 1s infinite;
  position: absolute;
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Highlight = styled.span`
  color: red; // Fallback color
  text-transform: uppercase;
  font-weight: 900;
  background: ${({ color }) => color};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TypingText = ({
                       featuresList,
                       highlightColor = linearGradient(R.palette.warning.main, R.palette.warning.gradient),
                       delayBetweenStrings = 6000,
                       typingSpeed = 50,
                       deletionSpeed = 25,
                       skipAnimation = false,
                       ...rest
                    }) => {
   const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
   const [charIndex, setCharIndex] = useState(skipAnimation ? featuresList[0].text.length : 0);
   const [isDeleting, setIsDeleting] = useState(false);

   useEffect(() => {
      let timer;

      if (isDeleting) {
         timer = setTimeout(() => {
            setCharIndex((prevVal) => prevVal > 0 ? prevVal - 1 : prevVal);
         }, deletionSpeed);
      } else if (charIndex < featuresList[currentFeatureIndex]?.text?.length) {
         timer = setTimeout(() => {
            setCharIndex((prevVal) => prevVal + 1);
         }, typingSpeed);
      } else {
         timer = setTimeout(() => {
            setIsDeleting(true);
         }, featuresList[currentFeatureIndex]?.delayBetweenStrings || delayBetweenStrings);
      }

      return () => clearTimeout(timer);
   }, [featuresList, currentFeatureIndex, typingSpeed, deletionSpeed, isDeleting, charIndex, delayBetweenStrings]);

   useEffect(() => {
      if (charIndex === 0 && isDeleting) {
         setIsDeleting(false);
         setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % featuresList.length);
         setCharIndex(skipAnimation ? featuresList[(currentFeatureIndex + 1) % featuresList.length].text.length : 0);
      }
   }, [charIndex, isDeleting, featuresList, currentFeatureIndex, skipAnimation]);


   /*useEffect(() => {
      //NEW TEXT
      setCharIndex(skipAnimation ? text.length : 0);
      setIsCompleted(false)
      setIsDeleting(false)
   }, [text, skipAnimation]);*/

   const getHighlightedText = (text, highlights, charIndex) => {
      const result = [];
      let lastEnd = 0;

      highlights.sort((a, b) => text.toLowerCase().indexOf(a.highlightedText.toLowerCase()) - text.toLowerCase().indexOf(b.highlightedText.toLowerCase()))
         .forEach((highlight) => {
            const start = text.toLowerCase().indexOf(highlight.highlightedText.toLowerCase());
            const end = start + highlight.highlightedText.length;

            // Add the non-highlighted part
            if (start > lastEnd) {
               result.push({
                  text: text.slice(lastEnd, start),
                  color: null
               });
            }

            // Add the highlighted part
            result.push({
               text: highlight.highlightedText,
               color: highlight.color
            });

            lastEnd = end;
         });

      // Add the remaining non-highlighted part
      if (text.length > lastEnd) {
         result.push({
            text: text.slice(lastEnd),
            color: null
         });
      }

      let cumulativeLength = 0;

      return result.map(({ text, color }, index) => {
         const visibleLength = Math.max(0, Math.min(text.length, charIndex - cumulativeLength));
         cumulativeLength += text.length;

         if (color && visibleLength > 0) {
            return (
               <Highlight color={color} key={index}>
                  {text.slice(0, visibleLength)}
               </Highlight>
            );
         } else {
            return text.slice(0, visibleLength);
         }
      });
   };



   return (
      <Typography align={'left'} variant={'h1'} {...rest}>
         {getHighlightedText(featuresList[currentFeatureIndex].text, featuresList[currentFeatureIndex].highlights, charIndex)}
         <Cursor>|</Cursor>
      </Typography>
   );

};

export default TypingText;
