import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";
import boxShadows from "../../boxShadows";
import linearGradient from "../../../functions/linearGradient";

const {white, colors, transparent} = palette;
const {borderWidth} = borders;
const {md} = boxShadows;

const switchButton = {
    defaultProps: {
        disableRipple: false,
    },

    styleOverrides: {
        switchBase: {
            //color: palette.dark.gradient,

            "&:hover": {
                //backgroundColor: transparent.main,
            },

            "&.Mui-checked": {
                //color: palette.dark.gradient,

                "&:hover": {
                    backgroundColor: transparent.main,
                },

                "& .MuiSwitch-thumb": {
                    //borderColor: `${palette.dark.gradient} !important`,
                },

                "& + .MuiSwitch-track": {
                    //backgroundColor: `${palette.dark.gradient} !important`,
                    borderColor: `${palette.dark.gradient} !important`,
                    opacity: 1,
                },
            },

            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: "0.3 !important",
            },

            "&.Mui-focusVisible .MuiSwitch-thumb": {
                backgroundImage: linearGradient(palette.info.gradient, palette.info.main),
            },
        },

        thumb: {
            backgroundColor: white.main,
            boxShadow: md,
            border: `${borderWidth[1]} solid ${colors.grey[400]}`,
        },

        track: {
            width: pxToRem(32),
            height: pxToRem(15),
            backgroundColor: colors.grey[400],
            //border: `${borderWidth[1]} solid ${grey[400]}`,
            opacity: 1,
        },

        checked: {},
    },
};

export default switchButton;
