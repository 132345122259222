import {alpha, darken, lighten} from "../functions/colorManipulation";

const themeDark = true;

const materialColors = {
    "red": {
        "50": "#ffebee",
        "100": "#ffcdd2",
        "200": "#ef9a9a",
        "300": "#e57373",
        "400": "#ef5350",
        "500": "#f44336",
        "600": "#e53935",
        "700": "#d32f2f",
        "800": "#c62828",
        "900": "#b71c1c",
        "a100": "#ff8a80",
        "a200": "#ff5252",
        "a400": "#ff1744",
        "a700": "#d50000"
    },
    "pink": {
        "50": "#fce4ec",
        "100": "#f8bbd0",
        "200": "#f48fb1",
        "300": "#f06292",
        "400": "#ec407a",
        "500": "#e91e63",
        "600": "#d81b60",
        "700": "#c2185b",
        "800": "#ad1457",
        "900": "#880e4f",
        "a100": "#ff80ab",
        "a200": "#ff4081",
        "a400": "#f50057",
        "a700": "#c51162"
    },
    "purple": {
        "50": "#f3e5f5",
        "100": "#e1bee7",
        "200": "#ce93d8",
        "300": "#ba68c8",
        "400": "#ab47bc",
        "500": "#9c27b0",
        "600": "#8e24aa",
        "700": "#7b1fa2",
        "800": "#6a1b9a",
        "900": "#4a148c",
        "a100": "#ea80fc",
        "a200": "#e040fb",
        "a400": "#d500f9",
        "a700": "#aa00ff"
    },
    "deeppurple": {
        "50": "#ede7f6",
        "100": "#d1c4e9",
        "200": "#b39ddb",
        "300": "#9575cd",
        "400": "#7e57c2",
        "500": "#673ab7",
        "600": "#5e35b1",
        "700": "#512da8",
        "800": "#4527a0",
        "900": "#311b92",
        "a100": "#b388ff",
        "a200": "#7c4dff",
        "a400": "#651fff",
        "a700": "#6200ea"
    },
    "indigo": {
        "50": "#e8eaf6",
        "100": "#c5cae9",
        "200": "#9fa8da",
        "300": "#7986cb",
        "400": "#5c6bc0",
        "500": "#3f51b5",
        "600": "#3949ab",
        "700": "#303f9f",
        "800": "#283593",
        "900": "#1a237e",
        "a100": "#8c9eff",
        "a200": "#536dfe",
        "a400": "#3d5afe",
        "a700": "#304ffe"
    },
    "blue": {
        "50": "#e3f2fd",
        "100": "#bbdefb",
        "200": "#90caf9",
        "300": "#64b5f6",
        "400": "#42a5f5",
        "500": "#2196f3",
        "600": "#1e88e5",
        "700": "#1976d2",
        "800": "#1565c0",
        "900": "#0d47a1",
        "a100": "#82b1ff",
        "a200": "#448aff",
        "a400": "#2979ff",
        "a700": "#2962ff"
    },
    "lightblue": {
        "50": "#e1f5fe",
        "100": "#b3e5fc",
        "200": "#81d4fa",
        "300": "#4fc3f7",
        "400": "#29b6f6",
        "500": "#03a9f4",
        "600": "#039be5",
        "700": "#0288d1",
        "800": "#0277bd",
        "900": "#01579b",
        "a100": "#80d8ff",
        "a200": "#40c4ff",
        "a400": "#00b0ff",
        "a700": "#0091ea"
    },
    "cyan": {
        "50": "#e0f7fa",
        "100": "#b2ebf2",
        "200": "#80deea",
        "300": "#4dd0e1",
        "400": "#26c6da",
        "500": "#00bcd4",
        "600": "#00acc1",
        "700": "#0097a7",
        "800": "#00838f",
        "900": "#006064",
        "a100": "#84ffff",
        "a200": "#18ffff",
        "a400": "#00e5ff",
        "a700": "#00b8d4"
    },
    "teal": {
        "50": "#e0f2f1",
        "100": "#b2dfdb",
        "200": "#80cbc4",
        "300": "#4db6ac",
        "400": "#26a69a",
        "500": "#009688",
        "600": "#00897b",
        "700": "#00796b",
        "800": "#00695c",
        "900": "#004d40",
        "a100": "#a7ffeb",
        "a200": "#64ffda",
        "a400": "#1de9b6",
        "a700": "#00bfa5"
    },
    "green": {
        "50": "#e8f5e9",
        "100": "#c8e6c9",
        "200": "#a5d6a7",
        "300": "#81c784",
        "400": "#66bb6a",
        "500": "#4caf50",
        "600": "#43a047",
        "700": "#388e3c",
        "800": "#2e7d32",
        "900": "#1b5e20",
        "a100": "#b9f6ca",
        "a200": "#69f0ae",
        "a400": "#00e676",
        "a700": "#00c853"
    },
    "lightgreen": {
        "50": "#f1f8e9",
        "100": "#dcedc8",
        "200": "#c5e1a5",
        "300": "#aed581",
        "400": "#9ccc65",
        "500": "#8bc34a",
        "600": "#7cb342",
        "700": "#689f38",
        "800": "#558b2f",
        "900": "#33691e",
        "a100": "#ccff90",
        "a200": "#b2ff59",
        "a400": "#76ff03",
        "a700": "#64dd17"
    },
    "lime": {
        "50": "#f9fbe7",
        "100": "#f0f4c3",
        "200": "#e6ee9c",
        "300": "#dce775",
        "400": "#d4e157",
        "500": "#cddc39",
        "600": "#c0ca33",
        "700": "#afb42b",
        "800": "#9e9d24",
        "900": "#827717",
        "a100": "#f4ff81",
        "a200": "#eeff41",
        "a400": "#c6ff00",
        "a700": "#aeea00"
    },
    "yellow": {
        "50": "#fffde7",
        "100": "#fff9c4",
        "200": "#fff59d",
        "300": "#fff176",
        "400": "#ffee58",
        "500": "#ffeb3b",
        "600": "#fdd835",
        "700": "#fbc02d",
        "800": "#f9a825",
        "900": "#f57f17",
        "a100": "#ffff8d",
        "a200": "#ffff00",
        "a400": "#ffea00",
        "a700": "#ffd600"
    },
    "amber": {
        "50": "#fff8e1",
        "100": "#ffecb3",
        "200": "#ffe082",
        "300": "#ffd54f",
        "400": "#ffca28",
        "500": "#ffc107",
        "600": "#ffb300",
        "700": "#ffa000",
        "800": "#ff8f00",
        "900": "#ff6f00",
        "a100": "#ffe57f",
        "a200": "#ffd740",
        "a400": "#ffc400",
        "a700": "#ffab00"
    },
    "orange": {
        "50": "#fff3e0",
        "100": "#ffe0b2",
        "200": "#ffcc80",
        "300": "#ffb74d",
        "400": "#ffa726",
        "500": "#ff9800",
        "600": "#fb8c00",
        "700": "#f57c00",
        "800": "#ef6c00",
        "900": "#e65100",
        "a100": "#ffd180",
        "a200": "#ffab40",
        "a400": "#ff9100",
        "a700": "#ff6d00"
    },
    "deeporange": {
        "50": "#fbe9e7",
        "100": "#ffccbc",
        "200": "#ffab91",
        "300": "#ff8a65",
        "400": "#ff7043",
        "500": "#ff5722",
        "600": "#f4511e",
        "700": "#e64a19",
        "800": "#d84315",
        "900": "#bf360c",
        "a100": "#ff9e80",
        "a200": "#ff6e40",
        "a400": "#ff3d00",
        "a700": "#dd2c00"
    },
    "brown": {
        "50": "#efebe9",
        "100": "#d7ccc8",
        "200": "#bcaaa4",
        "300": "#a1887f",
        "400": "#8d6e63",
        "500": "#795548",
        "600": "#6d4c41",
        "700": "#5d4037",
        "800": "#4e342e",
        "900": "#3e2723"
    },
    "grey": {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121"
    },
    "bluegrey": {
        "50": "#eceff1",
        "100": "#cfd8dc",
        "200": "#b0bec5",
        "300": "#90a4ae",
        "400": "#78909c",
        "500": "#607d8b",
        "600": "#546e7a",
        "700": "#455a64",
        "800": "#37474f",
        "900": "#263238"
    }
}

export const colors = {
    typography:{
        dark:{
            primary: '#7b809a',
            secondary: 'rgba(0,0,0,0.7)',
            alpha: 'rgba(0,0,0,0.3)',
            strong: '#333',
        },
        light:{
            primary: '#ffffff',
            secondary: 'rgba(255,255,255,0.7)',
            alpha: 'rgba(255,255,255,0.3)',
            strong: '#FFFFFF',
        },
    },

    background: {
        dark:{
            primary: '#111',
            secondary: '#262334'/*"252525"*/,
            tertiary: '#434053'/*"#373737"*/,
            strong: "rgba(0,0,0,1)",
            input: "rgba(0,0,0,0.5)",
        },
        light:{
            primary: "rgba(255,255,255,1)",
            secondary: "rgba(255,255,255,1)",
            tertiary: "rgba(255,255,255,0.3)",
            strong: "rgba(255,255,255,1)",
            input: "rgba(255,255,255,0.9)",
        },
    },

    primary: {
        main: "#C20057",
        focus: "#ac114a",
        gradient: "#2D2FAA",
        light: "#f8b3ca",
    },
    secondary: {
        main: "#811362",
        focus: "#5b0d45",
        gradient: "#CE3867",
        light: "#fcd3d0",
    },
    contrast: {
        main: "#c1ff72",
        focus: "#7aab3c",
        gradient: "#66952b",
        light: "#e3fdba",
    },
    info: {
        main: "#264CD2",
        focus: "#2346c3",
        gradient: "#3CAEEE",
        light: "#aecef7",
    },
    success: {
        main: "#18A75C",
        focus: "#159451",
        gradient: "#9FBE47",
        light: "#bce2be",
    },
    warning: {
        main: "#F07821",
        focus: "#ca760d",
        gradient: "#E7A94E",
        light: "#ffd59f",
    },
    error: {
        main: "#d72d2d",
        focus: "#b03228",
        gradient: "#e65946",
        light: "#fcd3d0",
    },
    wisdom: {
        main: "#210F69",
        focus: "#120838",
        gradient: "#4D38CE",
        light: "#fcd3d0",
    },
    light: {
        main: "#ffffff",
        focus: "#f0f2f5",
        gradient: "#f0f2f5",
        light: "#ffffff",
    },
    dark: {
        main: "#333333",
        focus: "#191919",
        gradient: "#42424a",
        light: "#d5d5d5",
    },

    white: {
        main: "#ffffff",
        focus: "#ffffff",
        gradient: "#ffffff",
        light: "#ffffff",
    },
    black: {
        main: "#000000",
        focus: "#000000",
        gradient: "#07053a",
        light: "#000000",
    },

    colors:{
        ...materialColors
    },
    socialMediaColors: {
        facebook: {
            main: "#3b5998",
            dark: "#344e86",
        },

        twitter: {
            main: "#55acee",
            dark: "#3ea1ec",
        },

        instagram: {
            main: "#125688",
            dark: "#0e456d",
        },

        linkedin: {
            main: "#0077b5",
            dark: "#00669c",
        },

        pinterest: {
            main: "#cc2127",
            dark: "#b21d22",
        },

        youtube: {
            main: "#e52d27",
            dark: "#d41f1a",
        },

        vimeo: {
            main: "#1ab7ea",
            dark: "#13a3d2",
        },

        slack: {
            main: "#3aaf85",
            dark: "#329874",
        },

        dribbble: {
            main: "#ea4c89",
            dark: "#e73177",
        },

        github: {
            main: "#24292e",
            dark: "#171a1d",
        },

        reddit: {
            main: "#ff4500",
            dark: "#e03d00",
        },

        tumblr: {
            main: "#35465c",
            dark: "#2a3749",
        },
    },
    barsColor:{
        general:{
            blue:'#00D1FF',
            green:'#26D260',
            greenDarker:'#158e4f',
            lightgreen:'#83E058',
            lightgreenDarker:'#8dba63',
            yellow:'#d7a72d',
            red:'#FB6767',
            grey:'#9e9e9e',
        },
    }
}

export const getPalette = (theme) => {
    let themeDark = theme === 'dark'
    return {
        themeDark: themeDark,
        mode: themeDark ? 'dark' : 'light',
        background: {
            primary: themeDark ? colors.background.dark.primary : colors.background.light.primary,
            secondary: themeDark ? colors.background.dark.secondary : colors.background.light.secondary,
            tertiary: themeDark ? colors.background.dark.tertiary : colors.background.light.tertiary,
            strong: themeDark ? colors.background.dark.strong : colors.background.light.strong,
            input: themeDark ? colors.background.dark.input : colors.background.light.input,
            opposite:{
                primary: !themeDark ? colors.background.dark.primary : colors.background.light.primary,
                secondary: !themeDark ? colors.background.dark.secondary : colors.background.light.secondary,
                tertiary: !themeDark ? colors.background.dark.tertiary : colors.background.light.tertiary,
                strong: !themeDark ? colors.background.dark.strong : colors.background.light.strong,
                input: !themeDark ? colors.background.dark.input : colors.background.light.input,
            },
            dark:{
                primary: colors.background.dark.primary,
                secondary: colors.background.dark.secondary,
                tertiary: colors.background.dark.tertiary,
                strong: colors.background.dark.strong,
                input: colors.background.dark.input,
            },
            light:{
                primary: colors.background.light.primary,
                secondary: colors.background.light.secondary,
                tertiary: colors.background.light.tertiary,
                strong: colors.background.light.strong,
                input: colors.background.light.input,
            },
        },
        text: {
            primary: themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
            secondary: themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
            strong: themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
            alpha: themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
            opposite:{
                primary: !themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
                secondary: !themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
                strong: !themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
                alpha: !themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
            },
            oppositePrimary: !themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
            oppositeSecondary: !themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
            oppositeStrong: !themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
            oppositeAlpha: !themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
            dark:{
                primary: colors.typography.dark.primary,
                secondary: colors.typography.dark.secondary,
                strong: colors.typography.dark.strong,
                alpha: colors.typography.dark.alpha,
            },
            light:{
                primary: colors.typography.light.primary,
                secondary: colors.typography.light.secondary,
                strong: colors.typography.light.strong,
                alpha: colors.typography.light.alpha,
            },
            white: "#fff",
            black: "#000",
        },

        transparent: {
            main: "transparent",
        },

        primary: {
            ...colors.primary
        },
        secondary: {
            ...colors.secondary
        },
        contrast: {
            ...colors.contrast
        },
        info: {
            ...colors.info
        },
        success: {
            ...colors.success
        },
        warning: {
            ...colors.warning
        },
        error: {
            ...colors.error
        },
        wisdom: {
            ...colors.wisdom
        },
        light: {
            ...colors.light
        },
        dark: {
            ...colors.dark
        },
        white: {
            ...colors.white
        },
        black: {
            ...colors.black
        },


        colors:{
            ...colors.colors
        },
        socialMediaColors: {
            ...colors.socialMediaColors
        },
        status:{
            selected:{
                main: colors.primary.main,
                gradient: colors.primary.gradient,
            },
            highlighted:{
                main: alpha(colors.warning.main, 0.3)
            }
        },

        inputBorderColor: 'rgba(255,255,255,0.2)',
        elements:{
            iconButton:{
                primary: themeDark ? darken(colors.light.main, 0.2) : lighten(colors.dark.main, 0.2),
                secondary: themeDark ? darken(colors.light.main, 0.4) : lighten(colors.dark.main, 0.4),
                background: themeDark ? alpha(colors.light.main, 0.4) : alpha(colors.dark.main, 0.4),
                opposite:{
                    primary: !themeDark ? darken(colors.light.main, 0.2) : lighten(colors.dark.main, 0.2),
                    secondary: !themeDark ? darken(colors.light.main, 0.4) : lighten(colors.dark.main, 0.4),
                    background: !themeDark ? alpha(colors.light.main, 0.4) : alpha(colors.dark.main, 0.4),
                },
                dark:{
                    primary: darken(colors.light.main, 0.2),
                    secondary: darken(colors.light.main, 0.4),
                    background: alpha(colors.light.main, 0.4),
                },
                light:{
                    primary: lighten(colors.dark.main, 0.2),
                    secondary: lighten(colors.dark.main, 0.4),
                    background: alpha(colors.dark.main, 0.4),
                },
            },
            divider:{
                primary: themeDark ? alpha(colors.background.light.strong, 0.3) : alpha(colors.background.dark.strong, 0.3),
                secondary: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
                opposite:{
                    primary: !themeDark ? alpha(colors.background.light.strong, 0.3) : alpha(colors.background.dark.strong, 0.3),
                    secondary: !themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
                }
            },
            accordion:{
                main: themeDark ? darken(colors.background.light.secondary, 0.2) : lighten(colors.background.dark.secondary, 0.2),
                opposite: themeDark ? lighten(colors.background.dark.secondary, 0.2) : darken(colors.background.light.secondary, 0.2),
                dark: darken(colors.background.light.secondary, 0.2),
                light: lighten(colors.background.dark.secondary, 0.2),
            },
            dialog:{
                main: themeDark ? colors.background.dark.tertiary : colors.background.light.secondary
            },
            tabs:{
                background: themeDark ? colors.background.dark.secondary : colors.background.light.tertiary,
                dark: colors.background.dark.secondary,
                light: colors.background.light.tertiary
            },
            navbar:{
                background: themeDark ? colors.background.dark.tertiary : colors.background.light.secondary,
            },
            oddRow: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
            scrollbar:{
                main: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
                dark: alpha(colors.background.dark.strong, 0.1),
                light: alpha(colors.background.light.strong, 0.1),
            },
            barChart:{
                general:{
                    ...colors.barsColor.general
                },
                mv:{
                    0:colors.barsColor.general.green,
                    1:colors.barsColor.general.lightgreen,
                    2:colors.barsColor.general.yellow,
                    3:colors.barsColor.general.red,
                },
                fmv:{
                    0:colors.barsColor.general.blue,
                    1:colors.barsColor.general.yellow,
                    2:colors.barsColor.general.red,
                },
                darkMv:{
                    0:colors.barsColor.general.greenDarker,
                    1:colors.barsColor.general.lightgreenDarker,
                    2:colors.barsColor.general.yellow,
                    3:colors.barsColor.general.red,
                },
            }
        }
    };

}

const palette = {
    themeDark: themeDark,
    mode: themeDark ? 'dark' : 'light',
    background: {
        primary: themeDark ? colors.background.dark.primary : colors.background.light.primary,
        secondary: themeDark ? colors.background.dark.secondary : colors.background.light.secondary,
        tertiary: themeDark ? colors.background.dark.tertiary : colors.background.light.tertiary,
        strong: themeDark ? colors.background.dark.strong : colors.background.light.strong,
        input: themeDark ? colors.background.dark.input : colors.background.light.input,
        opposite:{
            primary: !themeDark ? colors.background.dark.primary : colors.background.light.primary,
            secondary: !themeDark ? colors.background.dark.secondary : colors.background.light.secondary,
            tertiary: !themeDark ? colors.background.dark.tertiary : colors.background.light.tertiary,
            strong: !themeDark ? colors.background.dark.strong : colors.background.light.strong,
            input: !themeDark ? colors.background.dark.input : colors.background.light.input,
        },
        dark:{
            primary: colors.background.dark.primary,
            secondary: colors.background.dark.secondary,
            tertiary: colors.background.dark.tertiary,
            strong: colors.background.dark.strong,
            input: colors.background.dark.input,
        },
        light:{
            primary: colors.background.light.primary,
            secondary: colors.background.light.secondary,
            tertiary: colors.background.light.tertiary,
            strong: colors.background.light.strong,
            input: colors.background.light.input,
        },
    },
    text: {
        primary: themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
        secondary: themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
        strong: themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
        alpha: themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
        opposite:{
            primary: !themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
            secondary: !themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
            strong: !themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
            alpha: !themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
        },
        oppositePrimary: !themeDark ? colors.typography.light.primary : colors.typography.dark.primary,
        oppositeSecondary: !themeDark ? colors.typography.light.secondary : colors.typography.dark.secondary,
        oppositeStrong: !themeDark ? colors.typography.light.strong : colors.typography.dark.strong,
        oppositeAlpha: !themeDark ? colors.typography.light.alpha : colors.typography.dark.alpha,
        dark:{
            primary: colors.typography.dark.primary,
            secondary: colors.typography.dark.secondary,
            strong: colors.typography.dark.strong,
            alpha: colors.typography.dark.alpha,
        },
        light:{
            primary: colors.typography.light.primary,
            secondary: colors.typography.light.secondary,
            strong: colors.typography.light.strong,
            alpha: colors.typography.light.alpha,
        },
        white: "#fff",
        black: "#000",
    },

    transparent: {
        main: "transparent",
    },

    primary: {
        ...colors.primary
    },
    secondary: {
        ...colors.secondary
    },
    contrast: {
        ...colors.contrast
    },
    info: {
        ...colors.info
    },
    success: {
        ...colors.success
    },
    warning: {
        ...colors.warning
    },
    error: {
        ...colors.error
    },
    wisdom: {
        ...colors.wisdom
    },
    light: {
        ...colors.light
    },
    dark: {
        ...colors.dark
    },
    white: {
        ...colors.white
    },
    black: {
        ...colors.black
    },


    colors:{
        ...colors.colors
    },
    socialMediaColors: {
        ...colors.socialMediaColors
    },
    status:{
        selected:{
            main: colors.primary.main,
            gradient: colors.primary.gradient,
        },
        highlighted:{
            main: alpha(colors.warning.main, 0.3)
        }
    },

    inputBorderColor: 'rgba(255,255,255,0.2)',
    elements:{
        iconButton:{
            primary: themeDark ? darken(colors.light.main, 0.2) : lighten(colors.dark.main, 0.2),
            secondary: themeDark ? darken(colors.light.main, 0.4) : lighten(colors.dark.main, 0.4),
            background: themeDark ? alpha(colors.light.main, 0.4) : alpha(colors.dark.main, 0.4),
            opposite:{
                primary: !themeDark ? darken(colors.light.main, 0.2) : lighten(colors.dark.main, 0.2),
                secondary: !themeDark ? darken(colors.light.main, 0.4) : lighten(colors.dark.main, 0.4),
                background: !themeDark ? alpha(colors.light.main, 0.4) : alpha(colors.dark.main, 0.4),
            },
            dark:{
                primary: darken(colors.light.main, 0.2),
                secondary: darken(colors.light.main, 0.4),
                background: alpha(colors.light.main, 0.4),
            },
            light:{
                primary: lighten(colors.dark.main, 0.2),
                secondary: lighten(colors.dark.main, 0.4),
                background: alpha(colors.dark.main, 0.4),
            },
        },
        divider:{
            primary: themeDark ? alpha(colors.background.light.strong, 0.3) : alpha(colors.background.dark.strong, 0.3),
            secondary: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
            opposite:{
                primary: !themeDark ? alpha(colors.background.light.strong, 0.3) : alpha(colors.background.dark.strong, 0.3),
                secondary: !themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
            }
        },
        accordion:{
            main: themeDark ? darken(colors.background.light.secondary, 0.2) : lighten(colors.background.dark.secondary, 0.2),
            opposite: themeDark ? lighten(colors.background.dark.secondary, 0.2) : darken(colors.background.light.secondary, 0.2),
            dark: darken(colors.background.light.secondary, 0.2),
            light: lighten(colors.background.dark.secondary, 0.2),
        },
        dialog:{
            main: themeDark ? colors.background.dark.tertiary : colors.background.light.secondary
        },
        tabs:{
            background: themeDark ? colors.background.dark.secondary : colors.background.light.tertiary,
            dark: colors.background.dark.secondary,
            light: colors.background.light.tertiary
        },
        navbar:{
            background: themeDark ? colors.background.dark.tertiary : colors.background.light.secondary,
        },
        oddRow: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
        scrollbar:{
            main: themeDark ? alpha(colors.background.light.strong, 0.1) : alpha(colors.background.dark.strong, 0.1),
            dark: alpha(colors.background.dark.strong, 0.1),
            light: alpha(colors.background.light.strong, 0.1),
        },
        barChart:{
            general:{
                ...colors.barsColor.general
            },
            mv:{
                0:colors.barsColor.general.green,
                1:colors.barsColor.general.lightgreen,
                2:colors.barsColor.general.yellow,
                3:colors.barsColor.general.red,
            },
            fmv:{
                0:colors.barsColor.general.blue,
                1:colors.barsColor.general.yellow,
                2:colors.barsColor.general.red,
            },
            darkMv:{
                0:colors.barsColor.general.greenDarker,
                1:colors.barsColor.general.lightgreenDarker,
                2:colors.barsColor.general.yellow,
                3:colors.barsColor.general.red,
            },
        }
    }
};

export default palette;
