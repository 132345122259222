/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React Base Styles
import palette from "../../palette";
import borders from "../../borders";
import boxShadows from "../../boxShadows";
import rgba from "../../../functions/rgba";


const {black, white} = palette;
const {borderWidth, borderRadius} = borders;
const {xs, sm, md,lg,xl,xxl} = boxShadows;

const card = {
    styleOverrides: {
        root: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            minWidth: 0,
            wordWrap: "break-word",
            backgroundColor: white.main,
            backgroundClip: "border-box",
            border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
            borderRadius: borderRadius.lg,
            //boxShadow: lg,
            overflow: "visible",
        },
    },
};

export default card;
