import PropTypes from "prop-types";
import View from "../View";
import Icon from "../Icon";
import TextField from "../TextField";
import {Autocomplete} from "@mui/material";
import React from "react";
import R from "../../../theme/styles";
import {alpha, getContrastText} from "../../../theme/functions/colorManipulation";
import MenuItem from "../MenuItem";
import Select from "../Select";
import {buildStyle} from "../../../utils/style/buildStyle";
import {capitalize} from "lodash/string";
import Popper from "../Popper";
import {sleep} from "../../../utils/time/timeUtils";


const Input = ({
                  type = 'input',
                  options,
                  optionLabel,
                  valueLabel,
                  typeInput,
                  value,
                  defaultValue,
                  onChange,
                  blurOnSelect = true,
                  clearOnBlur = false,
                  fontSize = R.typography.size.sm,
                  size = 'medium',
                  margin = 'none',
                  variant = 'outlined',
                  placeholder = '',
                  backgroundColor = R.palette.background.input,
                  borderColor,
                  minWidth = 100,
                  multiline = false,
                  multiple,
                  textAlign,
                  label,
                  InputProps,
                  inputProps,
                  disabled,
                  icon,
                  fullWidth,
                  sx,
                  ...rest
               }) => {

   const inputTextColor = getContrastText(backgroundColor, 'strong')

   const classesTextField = {
      root: {
         borderRadius: R.borders.borderRadius.lg,
         color: 'rgba(255,255,255,1)',
         cursor: 'pointer !important',
         textAlign: "left",
         minWidth: minWidth,
         height: '100%',


      },
      small: {},
      medium: {
         //paddingTop: 11.5,
         //paddingBottom: 11.5,
      },
      large: {
         '& .MuiSelect-select': {
            //padding: '10px 14px'
         }
      },

   }

   const classesInput = {
      root: {
         minWidth: minWidth,
         borderRadius: R.borders.borderRadius.input,
         paddingBottom: 0,
         paddingTop: 0,
         height: multiline ? undefined : size === 'xLarge' ? 60 : size === 'large' ? 50 : size === 'medium' ? 40 : 30,
         display: 'flex',
         justifyContent: 'center',
         backgroundColor: variant === 'standard' ? undefined : backgroundColor,
         "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: R.borders.borderRadius.input
         },
         "& .MuiInputBase-root": {
            height: '100%',
            //minHeight:150,
         },
         '& .MuiInputBase-root .MuiAutocomplete-input': {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            top: 'auto',
         },

         input: {color: `${inputTextColor} !important`},
         textarea: {color: `${inputTextColor} !important`},
         color: {
            "& .MuiSvgIcon-root": {
               color: `${inputTextColor} !important`,
            },
            "& .MuiAutocomplete-popupIndicator": {
               color: `${inputTextColor} !important`,
            },
         },
      },
      disabled: {
         '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `rgba(255,255,255,0.3) !important`
         },
      }
   }

   const textFieldStyle = buildStyle(classesTextField, {
      root: true,
      xSmall: size === 'xSmall',
      small: size === 'small',
      medium: size === 'medium',
      large: size === 'large',
   });

   const inputStyle = buildStyle(classesInput, {
      root: true,
      disabled: disabled,
   });

   const getTextField = (params) => {
      return (
         <TextField
            variant={variant}
            size={size}
            value={value}
            type={typeInput}
            onChange={type !== 'autocomplete' && onChange}
            margin={margin}
            //hiddenLabel={!label}
            fontSize={fontSize}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            sx={{...inputStyle, ...sx}}
            InputLabelProps={{shrink: label ? undefined : false}}
            icon={icon}
            fullWidth={fullWidth}
            InputProps={{
               disableUnderline: variant !== 'standard',
               inputProps: {
                  style: {
                     ...textFieldStyle,
                     textAlign: textAlign || 'left',
                  },
                  ...inputProps,
               },
               ...InputProps,
            }}
            multiline={multiline}
            borderColor={borderColor}
            {...rest}
            {...params}
         />
      )
   }

   return (
      <>
         {type === 'select' ?
            <Select
               fullWidth={fullWidth}
               color={backgroundColor}
               value={value}
               size={size}
               fontSize={fontSize}
               onChange={onChange}
               minWidth={minWidth}
               borderColor={borderColor}
               sx={{...sx}}
               {...rest}
            >
               {options.map((element, index) => {
                  return (
                     <MenuItem
                        sx={{
                           '&.MuiMenuItem-root': {
                              color: R.palette.text.dark.primary
                           },
                           '&:hover': {
                              color: R.palette.text.dark.strong,
                              backgroundColor: alpha(R.palette.background.dark.secondary, 0.1)
                           },
                           '&.MuiMenuItem-root.Mui-selected': {
                              color: R.palette.text.dark.strong,
                              backgroundColor: alpha(R.palette.background.dark.secondary, 0.1)
                           }
                        }}
                        key={'index_' + index}
                        value={valueLabel ? element?.[valueLabel] : element}>{optionLabel ? (element?.[optionLabel] || '') : (capitalize(element) || '')}</MenuItem>
                  )
               })}
            </Select>
            : type === 'autocomplete' ?
               <div style={{position: 'relative'}}>
                  {icon &&
                        <Icon
                           fontSize={'medium'}
                           sx={{
                              position: 'absolute', top: '50%', left: 16,
                              transform: 'translate(0%, -50%)'
                           }}
                        >search</Icon>
                  }
                  <Autocomplete
                     size={size}
                     fullWidth
                     disablePortal={false}
                     defaultValue
                     multiple={multiple}
                     clearOnBlur={clearOnBlur}
                     options={options}
                     getOptionLabel={option => option[optionLabel] || ''}
                     selectOnFocus
                     value={value}
                     blurOnSelect={blurOnSelect}
                     onChange={onChange}
                     handleHomeEndKeys
                     popupIcon={<Icon>keyboard_arrow_down_icon</Icon>}
                     PopperComponent={Popper}
                     sx={{
                        "& .MuiInputBase-root": {
                           paddingLeft: icon ? 5 : undefined,
                        },
                        //per il bottone
                        "& .MuiButtonBase-root span:not(.MuiChip-label)": {
                           fontSize: '20px !important'
                        },
                        "& input::placeholder": {
                           fontSize: size === 'small' ? "13px": undefined,
                        }
                     }}
                     renderInput={(params) => (getTextField(params))}
                  />
               </div> :
               getTextField()
         }
      </>
   )
}

View.propTypes = {
   type: PropTypes.oneOf(['input', 'autocomplete', 'select']),
   size: PropTypes.oneOf(['small', 'medium', 'large', 'xLarge']),
}

export default Input
