import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
   name: 'season',
   initialState: {
      imported: null,
   },
   reducers: {
      setImported: (
         state,
         action
      ) => {
         state.imported = action.payload
      }
   },
})

export const {setImported} = slice.actions

export default slice.reducer

