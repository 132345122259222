import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";
import borders from "../../borders";
import boxShadows from "../../boxShadows";
import R from "../../index";

const {white} = palette;
const {borderRadius} = borders;
const {tabsBoxShadow} = boxShadows;

const tabs = {
    styleOverrides: {
        root: {
            position: "relative",
            //backgroundColor: R.globals.cardColor,
            borderRadius: borderRadius.lg,
            minHeight: "unset",
            padding: '0px 16px',
            opacity: `1 !important`,
        },

        flexContainer: {
            height: "100%",
            position: "relative",
            zIndex: 10,
        },

        fixed: {
            overflow: "unset !important",
            overflowX: "unset !important",
        },

        vertical: {
            "& .MuiTabs-indicator": {
                width: "100%",
            },
        },

        indicator: {
            height: 3,
            /*height: "100%",
            borderRadius: borderRadius.lg,
            backgroundColor: white.main,
            boxShadow: tabsBoxShadow.indicator,*/
            transition: "all 200ms ease",
        },
    },
};

export default tabs;
