import React from 'react';
import PropTypes from 'prop-types';
import S from "../../theme/styles";
import {Grid as MuiGrid} from "@mui/material";
import {buildStyle} from "../../utils/style/buildStyle";
import R from "../../theme/styles";


const classes = {
    root: {
        //...S.layout.overflowHidden,
    },
    overflowVisible: {
        ...S.layout.overflowVisible,
    },
    fiveElements: {
        flexGrow: 0,
        maxWidth: '20%',
        flexBasis: '20%',
    },
    eightElements: {
        flexGrow: 0,
        maxWidth: '12.5%',
        flexBasis: '12.5%',
    },
    hidden: {
        display: 'none'
    },
    flex: {...R.layout.flex},
    flex1: {flex: '1 !important',},
    flexGrow: {...R.layout.flexGrow},
    flexShrink: {...R.layout.flexShrink},
};

const Grid = ({
                  className,
                  children,
                  overflowVisible = true,
                  spacing,
                  wrap,
                  item,
                  direction,
                  container,
                  alignItems,
                  alignContent,
                  justify,
                  flex,
                  flex1,
                  flexGrow,
                  flexShrink,
                  xs,
                  sm,
                  md,
                  lg,
                  xl,
                  show = true,
                  sx,
                  ...rest
              }) => {

    //const isuseCustomElements5 = uCustomElements(2.4, xs, sm, md, lg, xl);
    //const isuseCustomElements7 = uCustomElements(1.5, xs, sm, md, lg, xl);
    /*const buildStyle2 = () => {
        let rootClassName = {...classes.root}
        rootClassName = overflowVisible ? {...rootClassName, ...classes.overflowVisible} : rootClassName
        rootClassName = !show ? {...rootClassName, ...classes.hidden} : rootClassName
        rootClassName = flex ? {...rootClassName, ...classes.flex} : rootClassName
        //rootClassName = isuseCustomElements5 ? {...rootClassName, ...classes.fiveElements} : rootClassName
        //rootClassName = isuseCustomElements7 ? {...rootClassName, ...classes.eightElements} : rootClassName
        rootClassName = sx ? {...rootClassName, ...sx} : rootClassName
        return rootClassName
    }*/

    const style = buildStyle(classes, {
        root: true,
        overflowVisible: overflowVisible,
        hidden: !show,
        flex: flex,
        flex1: flex1,
        flexGrow: flexGrow,
        flexShrink: flexShrink,
    }, sx);

    return (
        <MuiGrid
            spacing={spacing}
            wrap={wrap}
            item={item}
            direction={direction}
            container={container}
            alignItems={alignItems}
            alignContent={alignContent}
            justifyContent={justify}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg !== 2.4 && lg !== 1.5 ? lg : false}
            xl={xl}
            sx={style}
            {...rest}
            className={className}
        >
            {children}
        </MuiGrid>
    );
};

Grid.propTypes = {
    children: PropTypes.node,
    overflowVisible: PropTypes.bool,
    spacing: PropTypes.number,
    item: PropTypes.bool,
    container: PropTypes.bool,
    direction: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
    alignContent: PropTypes.oneOf([false, 'stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    justify: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
    wrap: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
    xs: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    sm: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    md: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    xl: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

export default Grid;
