import View from "../MD/View";
import Image from "../ImageCustom/Image";

const phoneImageProportion = 753/498

const IpadMockup = ({src, height = 500}) => {
   return(
      <View
         sx={{
            width:height*phoneImageProportion,
            height:height,
         }}
         backgroundColor={'#aaa'}
         p={0.3}
         borderRadius={5}
         overflow={'hidden'}
      >
         <View fullWidth fullHeight backgroundColor={'#000'} borderRadius={5} overflow={'hidden'} p={1}>
            <Image
               src={src}
               width={'100%'}
               height={'100%'}
               sx={{borderRadius:15}}
               objectPosition={'top'}
               objectFit={'cover'}
            />
         </View>
      </View>
   )
}

export default IpadMockup
