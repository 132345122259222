// Material Dashboard 2 React Helper Functions
import palette from "./palette";
import boxShadow from "../functions/boxShadow";

const {black, white} = palette;
const shadowColor = palette.themeDark ? black.main : black.main

const shadowSize = {
   xs: boxShadow([0, 2], [9, -5], shadowColor, 0.15),
   sm: boxShadow([0, 2], [6, 0], shadowColor, 0.12),
   md: `${boxShadow([0, 2], [6, -1], shadowColor, 0.1)}, ${boxShadow(
      [0, 2],
      [4, -1],
      shadowColor,
      0.06
   )}`,
   lg: `${boxShadow([0, 0], [15, -3], shadowColor, 0.15)}, ${boxShadow(
      [0, 4],
      [6, -2],
      shadowColor,
      0.05
   )}`,
   xl: `${boxShadow([0, 2], [25, -5], shadowColor, 0.1)}, ${boxShadow(
      [0, 10],
      [10, -5],
      shadowColor,
      0.04
   )}`,
   xxl: boxShadow([0, 2], [27, 0], shadowColor, 0.1),
}

const colorShadowOffset = {
   0: [0, 4],
   1: [0, 7],
}
const colorShadowRadius = {
   0: [20, 0],
   1: [10, -5],
}
const colorShadowOpacity = 0.14

const boxShadows = {
   ...shadowSize,
   inset: boxShadow([0,0], [10,0], shadowColor, 0.4, "inset"),
   //COLORED
   coloredShadow: {
      primary: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.primary.main, colorShadowOpacity)}`,
      secondary: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.secondary.main, colorShadowOpacity)}`,
      contrast: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.contrast.main, colorShadowOpacity)}`,
      info: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.info.main, colorShadowOpacity)}`,
      success: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.success.main, colorShadowOpacity)}`,
      warning: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.warning.main, colorShadowOpacity)}`,
      error: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.error.main, colorShadowOpacity)}`,
      wisdom: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.wisdom.main, colorShadowOpacity)}`,
      light: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.light.main, colorShadowOpacity)}`,
      dark: `${boxShadow(colorShadowOffset[0], colorShadowRadius[0], shadowColor, colorShadowOpacity)},
                  ${boxShadow(colorShadowOffset[1], colorShadowRadius[1], palette.dark.main, colorShadowOpacity)}`,
   },

   navbarBoxShadow: shadowSize.md,
   sliderBoxShadow: {
      thumb: boxShadow([0, 1], [13, 0], shadowColor, 0.2),
   },
   tabsBoxShadow: {
      indicator: boxShadow([0, 1], [5, 1], palette.colors.grey[300], 1),
   },
   strong: boxShadow([0, 3], [3, 0], '#000', 0.15),
   paper:'0px 1px 5px 1px rgba(0,0,0,0.5)'
};

export default boxShadows;
