import React from "react";
import Typography from "../components/MD/Typography";
import Grid from "../components/MD/Grid";
import View from "../components/MD/View";
import Dialog from "../components/MD/Dialog/Dialog";


const ComingSoonModal = ({open, onClose}) => {

    return (
        <Dialog onClose={onClose}
                open={open}
                title={"L'app di FantaLab sarà attiva a breve"}
                fullWidth
                maxWidth={'xs'}
                noActions
        >
            <View px={5} pb={3} flex1>
                <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={1}>
                    <Grid item>
                        <Typography variant={'h6'}>
                            {"scrivici a"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <a href={"mailto:allegro.federico@gmail.com"}>
                            allegro.federico@gmail.com
                        </a>
                        {/*<Link key={"mail-to"} href={"mailto:allegro.federico@gmail.com"}>*/}
                        {/*    allegro.federico@gmail.com*/}
                        {/*</Link>*/}
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'}>
                            {"per saperne di più"}
                        </Typography>
                    </Grid>
                </Grid>
            </View>
        </Dialog>
    )
}

export default ComingSoonModal
