import {Toolbar as MuiToolbar} from "@mui/material";

const Toolbar = ({children, ...rest}) => {
    return(
        <MuiToolbar {...rest}>
            {children}
        </MuiToolbar>
    )
}

export default Toolbar
