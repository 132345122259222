import {CircularProgress, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "../Button";
import Typography from "../Typography";
import Icon from "../Icon";
import View from "../View";
import R from "../../../theme/styles";
import {buildStyle} from "../../../utils/style/buildStyle";
import IconButton from "../IconButton";
import {darken} from "../../../theme/functions/colorManipulation";
import pxToRem from "../../../theme/functions/pxToRem";

const Dialog = ({
                   children,
                   title,
                   subTitle,
                   open,
                   onClose,
                   actionClick,
                   alignBody = 'flex-start',
                   actionButtonColor = 'info',
                   actionButtonText = 'conferma',
                   closeButtonVariant,
                   actionButtonIcon,
                   onSecondButtonClick,
                   actionButtonDisabled = false,
                   hideCloseButton,
                   closeButtonText = 'annulla',
                   closeButtonColor = R.palette.colors.grey["400"],
                   fullScreen,
                   fullWidth,
                   fullHeight,
                   maxWidth = 'xl',
                   minWidth,
                   noActions,
                   loading,
                   pxContent = 2,
                   backgroundImage,
                   paperStyle,
                   ...rest
                }) => {

   const classes = {
      root: {
         background: R.palette.background.primary,
         border: !fullScreen && R.borders.borderPaper["0"],
         borderRadius: !fullScreen && R.borders.borderRadius.xxxl,
         //backgroundColor: !backgroundImage ? R.palette.elements.dialog.main : undefined,
      },
      backgroundImage: {
         backgroundPosition: 'center',
         backgroundSize: 'cover !important',
         backgroundImage: `${backgroundImage}`,
      },
      fullHeight: {
         height: '100%',
      },
      minWidth:{
         minWidth: R.breakpoints.values?.[minWidth] || minWidth
      }
   }

   const style = buildStyle(classes, {
      root: true,
      backgroundImage: backgroundImage,
      fullHeight: fullHeight,
      minWidth: minWidth,
   }, paperStyle)

   return (
      <MuiDialog
         onClose={onClose}
         open={open}
         fullScreen={fullScreen}
         fullWidth={fullWidth}
         maxWidth={maxWidth}
         PaperComponent={View}
         PaperProps={{style}}
         //style={{background: 'rgba(0,0,0,0.3)'}}
         {...rest}
      >
         {title &&
            <DialogTitle sx={{
               padding: 2,
               //paddingBottom: 0,
               fontSize: R.typography.size.xl,
               textAlign: 'center'
            }}>
               <View spacing={1} px={4}>
                  <Typography variant="h5" fontWeight="medium" textTransform="capitalize">
                     {title}
                  </Typography>
                  {subTitle &&
                     <Typography variant="subtitle2" textTransform="capitalize">
                        {subTitle}
                     </Typography>
                  }
               </View>
            </DialogTitle>
         }
         <DialogContent
            sx={{display: 'flex', justifyContent: alignBody, pl: pxContent, pr: pxContent, pt: '6px !important'}}>
            {children}
         </DialogContent>
         {!loading && !noActions &&
            <DialogActions>
               {
                  !hideCloseButton &&
                  <Button
                     variant={closeButtonVariant ? closeButtonVariant : !actionClick ? "contained" : "text"}
                     color={closeButtonColor}
                     fullWidth
                     onClick={(e) => {
                        e.stopPropagation();
                        if (onSecondButtonClick) {
                           onSecondButtonClick()
                        } else {
                           onClose()
                        }
                     }}
                  >
                     {closeButtonText}
                  </Button>
               }
               {actionClick &&
                  <Button
                     disabled={actionButtonDisabled}
                     variant="contained"
                     color={actionButtonColor}
                     fullWidth
                     onClick={(e) => {
                        e.stopPropagation();
                        actionClick()
                     }}
                     startIcon={'check'}
                  >
                     {actionButtonText}
                  </Button>
               }
            </DialogActions>
         }
         {
            loading &&
            <View centerCenter pb={2}>
               <CircularProgress
                  size={30}/>
            </View>

         }

         <IconButton
            sx={{
               position: 'absolute', right: 10, top: 10,
               //backgroundColor: R.palette.colors.grey[200],
               backgroundColor: R.palette.background.opposite.primary,
               '&:hover': {
                  backgroundColor: darken(R.palette.background.opposite.secondary, 0.1)
               }
            }}
            color={R.palette.text.opposite.primary}
            onClick={onClose}
         >
            <Icon fontSize="small">close</Icon>
         </IconButton>

      </MuiDialog>
   )
}

export default Dialog
