import typography from "../../typography";
import palette from "../../palette";
import borders from "../../borders";
import R from "../../index";

const {info, inputBorderColor, dark} = palette;
const {size} = typography;
const {borderWidth} = borders;

const input = {
    styleOverrides: {
        root: {
            //fontSize: R.typography.size.sm,
            //color: R.palette.dark.main,

            "&:hover:not(.Mui-disabled):before": {
                //borderBottom: `${R.borders.borderWidth[1]} solid ${R.palette.inputBorderColor}`,
            },

            "&:after": {
                //borderColor: R.palette.info.main,
            },
        },
    },
};

export default input;
