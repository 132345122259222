import palette from "../../palette";

const {text} = palette;

const formLabel = {
    styleOverrides: {
        root: {
            color: text.main,
        },
    },
};

export default formLabel;
