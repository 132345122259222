import typography from "../../typography";
import palette from "../../palette";
import pxToRem from "../../../functions/pxToRem";

// Material Dashboard 2 React Base Styles

// Material Dashboard 2 React Helper Functions

const {white, text, primary, secondary} = palette;
const {size} = typography;

const contained = {
   base: {
      backgroundColor: white.main,

      "&:hover": {
         //backgroundColor: white.main,
      },

      "&:active, &:active:focus, &:active:hover": {
         //opacity: 0.85,
      },
   },
};

export default contained;
