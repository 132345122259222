import pxToRem from "../functions/pxToRem";

const drawerWidth = 221;
const miniDrawerWidth = 60;
const paddingBody = 16;

const dimensions = {
    spacing:  8,
    drawerWidth: drawerWidth,
    miniDrawerWidth: miniDrawerWidth,
    navBarMiniWidth: `calc(100vw - ${miniDrawerWidth + (paddingBody * 3)}px)`,
    navBarWidth: `calc(100vw - ${drawerWidth + (paddingBody * 3)}px)`,
    bodyMiniWidth: `calc(100vw - ${miniDrawerWidth + (paddingBody * 3)}px)`,
    bodyWidth: `calc(100vw - ${drawerWidth + (paddingBody * 3)}px)`,
    paddingBody: paddingBody,

    navBarHeight: pxToRem(30),

    sidebarDimension: 2,

    //ListIcon
    listIconMinWidth: 35,
    listIconSize: '1.25rem',

    //Toolbar
    toolbarHeight: 50,

    bodyMaxWidthMedium: 800,
    bodyMaxWidthLarge: 1100,
}
export default dimensions
