import { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
   '@keyframes slideAnim': {
      from: {
         transform: 'translateX(0%)',
      },
      to: {
         transform: 'translateX(-100%)',
      },
   },
   looper: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flex:1,
   },
   looper__innerList: {
      display: 'flex',
      flex:1,
      justifyContent: 'center',
      width: 'fit-content',
      '&[data-animate="true"] $looper__listInstance': {
         animation: '$slideAnim linear infinite',
      },
   },

   looper__listInstance: {
      display: 'flex',
      width: 'max-content',
      animation: 'none',
      flex:1,
      alignItems:'flex-start'
   },
});

const InfiniteLooper = ({ speed = 1, direction = 'left', children }) => {
   // Your function body goes here
   const classes = useStyles();

   const [looperInstances, setLooperInstances] = useState(1);
   const outerRef = useRef(null);
   const innerRef = useRef(null);

   function resetAnimation() {
      if (innerRef?.current) {
         innerRef.current.setAttribute("data-animate", "false");

         setTimeout(() => {
            if (innerRef?.current) {
               innerRef.current.setAttribute("data-animate", "true");
            }
         }, 10);
      }
   }

   const setupInstances = useCallback(() => {
      if (!innerRef?.current || !outerRef?.current) return;

      const { width } = innerRef.current.getBoundingClientRect();

      const { width: parentWidth } = outerRef.current.getBoundingClientRect();

      const widthDeficit = parentWidth - width;

      const instanceWidth = width / innerRef.current.children.length;

      if (widthDeficit) {
         setLooperInstances(
            looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1
         );
      }

      resetAnimation();
   }, [looperInstances]);


   /*
     6 instances, 200 each = 1200
     parent = 1700
   */

   useEffect(() => setupInstances(), [setupInstances]);

   useEffect(() => {
      window.addEventListener("resize", setupInstances);

      return () => {
         window.removeEventListener("resize", setupInstances);
      };
   }, [looperInstances, setupInstances]);

   return (
      <div className={classes.looper} ref={outerRef}>
         <div className={classes.looper__innerList} ref={innerRef} data-animate="true">
            {[...Array(looperInstances)].map((_, ind) => (
               <div
                  key={ind}
                  className={classes.looper__listInstance}
                  style={{
                     animationDuration: `${speed}s`,
                     animationDirection: direction === "right" ? "reverse" : "normal",
                  }}
               >
                  {children}
               </div>
            ))}
         </div>
      </div>
   );
}

export default InfiniteLooper
